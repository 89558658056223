import { useQuery } from 'react-query';
import { useAuthenticationStore } from '@therapie-ecommerce-ui/auth';
import { graphqlGatewayClient } from '@core/graphql/gateway/gateway';
import { generateAuthHeaders } from '@features/authentication/headers';
import { WEB_VIEW_STALE_TIME_MS } from '@features/web-view/constants/queries';
import { useIsWebView } from '@features/web-view/hooks/useIsWebView/useIsWebView';
import { GetUserTreatmentsQuery, GetUserTreatmentsQueryVariables } from '@/data/graphql/types';

export const GET_USER_TREATMENTS_KEY = 'GET_USER_TREATMENTS';

export const useGetUserTreatmentsQuery = () => {
  const isUserAuthenticated = useAuthenticationStore((s) => s.isUserAuthenticated);
  const { isWebView } = useIsWebView();

  return useQuery(
    GET_USER_TREATMENTS_KEY,
    async () => {
      const { getUserTreatments: response } = await graphqlGatewayClient.post<
        GetUserTreatmentsQueryVariables,
        GetUserTreatmentsQuery
      >('/GetUserTreatments', { page: { page: 0, size: 100 } }, { headers: generateAuthHeaders() });

      return response?.treatments;
    },
    {
      enabled: isUserAuthenticated,
      refetchOnWindowFocus: isWebView,
      staleTime: isWebView ? WEB_VIEW_STALE_TIME_MS : Infinity,
    }
  );
};
