export class ErrorBase<T extends string> extends Error {
  name: T;
  message: string;
  cause?: any;
  messageContext?: Record<string, any>;
  userMessage?: string;

  constructor({
    name,
    message,
    cause,
    messageContext,
    userMessage,
  }: {
    name: T;
    message: string;
    cause?: any;
    messageContext?: Record<string, any>;
    userMessage?: string;
  }) {
    super(message);
    this.name = name;
    this.message = message;
    this.cause = cause;
    this.messageContext = messageContext;
    this.userMessage = userMessage;
  }
}
