import { useTranslation } from 'next-i18next';

import { useRouter } from 'next/router';
import { cx, Icon } from '@therapie-ecommerce-ui/ui';

import { LinkText } from '@components/link/Link';
import { SITE_URLS } from '@utils/constants/site-urls.constants';
import { log } from '@core/logging/logging';
import { ORIGIN_KEY } from '@hooks/useRedirectOrigin/useRedirectOrigin';

import { LoginRegisterProps } from './LoginRegister.types';

import styles from './login-register.module.scss';

export function LoginRegister({
  className,
  textProps = {},
  iconSize = 'md',
  color = 'inherit',
}: LoginRegisterProps) {
  const { t } = useTranslation('common');
  const router = useRouter();

  const handleOnClick = (link: 'login' | 'register') => {
    log({
      level: 'info',
      category: 'login',
      message: 'login_register_link_clicked_in_service_bar',
      messageContext: { link, path: router?.asPath },
    });
  };

  return (
    <div className={cx(styles['login-register'], className)}>
      <Icon family="therapie" name="person" color={color} size={iconSize} />
      <LinkText
        onClick={() => handleOnClick('login')}
        href={{ pathname: SITE_URLS.LOGIN, query: { [ORIGIN_KEY]: router?.asPath } }}
        as={SITE_URLS.LOGIN}
        className={styles['login-register__link']}
        textProps={{
          color,
          ...textProps,
        }}
      >
        {t('buttons.logIn')}
      </LinkText>
      |
      <LinkText
        onClick={() => handleOnClick('register')}
        href={{ pathname: SITE_URLS.REGISTRATION, query: { [ORIGIN_KEY]: router?.asPath } }}
        as={SITE_URLS.REGISTRATION}
        className={styles['login-register__link']}
        textProps={{
          color,
          ...textProps,
        }}
      >
        {t('buttons.register')}
      </LinkText>
    </div>
  );
}
