import { useQuery } from 'react-query';
import { useAuthenticationStore } from '@therapie-ecommerce-ui/auth';
import { graphqlGatewayClient } from '@core/graphql/gateway/gateway';
import { generateAuthHeaders } from '@features/authentication/headers';
import { validateAndUseAddressIfExists } from '@features/cart-and-checkout/components/checkout-workflow/CheckoutWorkflow.utils';
import { checkoutActionsState } from '@features/cart-and-checkout/store/checkout/checkout.store';
import { getUsersDefaultAddress } from '@hooks/useUserAddresses/useUserAddresses.utils';
import { checkKeysForInvalidValues } from '@utils/functions/address';
import { GetUserQuery, GetUserQueryVariables } from '@/data/graphql/types';

export const USER_QUERY_KEY = (id?: string) => [{ name: 'GET_USER', id }];

export const useGetUserQuery = () => {
  const id = useAuthenticationStore((s) => s.user?.username!);

  return useQuery(
    USER_QUERY_KEY(id),
    async () => {
      const { getUser: user } = await graphqlGatewayClient.post<
        GetUserQueryVariables,
        GetUserQuery
      >(
        '/GetUser',
        {
          id,
        },
        { headers: generateAuthHeaders() }
      );
      const { defaultBillingAddress, defaultShippingAddress } = getUsersDefaultAddress(user!);
      const { addresses = [] } = user ?? {};
      // Dedupe this from firing multiple times in onSuccess callbacks
      checkoutActionsState.setCheckoutData((prev) => ({
        ...prev,
        billingAddress:
          validateAndUseAddressIfExists(addresses!, prev.billingAddress) ?? defaultBillingAddress,
        shippingAddress:
          validateAndUseAddressIfExists(addresses!, prev.shippingAddress) ?? defaultShippingAddress,
      }));
      checkoutActionsState.setCheckoutSteps((prev) => ({
        ...prev,
        PREFERRED_CLINIC: user?.preferredClinic ? false : true,
      }));
      return user;
    },
    {
      enabled: !!id,
      select: (data) => ({
        ...data!,
        addresses: data?.addresses?.filter(checkKeysForInvalidValues) ?? [],
      }),
    }
  );
};
