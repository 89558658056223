import { useRouter } from 'next/router';

import { cx } from '@therapie-ecommerce-ui/ui';
import { LinkText } from '@components/link/Link';
import { SITE_URLS } from '@utils/constants/site-urls.constants';

import { CartItemLinkProps } from './CartItemLink.types';
import styles from './cart-item-link.module.scss';

export const CartItemLink = ({
  href,
  className: classNameProp,
  isDisabled,
  children,
  onClick,
}: CartItemLinkProps) => {
  const router = useRouter();
  const isCheckoutPage = router && router.pathname.includes(SITE_URLS.CHECKOUT);

  const isLink = !isDisabled && !isCheckoutPage;

  const className = cx(styles['link'], { [styles['link--active']]: isLink }, classNameProp);

  return (
    <>
      {isLink ? (
        <LinkText
          textProps={{ hoverColor: 'trp-purple', color: 'night-500' }}
          href={href}
          className={className}
          onClick={onClick}
        >
          {children}
        </LinkText>
      ) : (
        <div className={className}>{children}</div>
      )}
    </>
  );
};
