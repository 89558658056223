import { SiteRegionURLsType } from '@utils/types/url.types';

export function getRegionForLocale(locale?: string): SiteRegionURLsType | undefined {
  switch (locale) {
    case 'en-IE':
      return 'roi';
    case 'en-GB':
      return 'uk';
    case 'en-US':
      return 'us';
    default:
      return undefined;
  }
}
