import { useTranslation } from 'next-i18next';

import { Text, TextProps } from '@therapie-ecommerce-ui/ui';

import { UseCartItemReturnType } from '../hooks/useCartItem.types';

export const CartItemRemoved = ({
  isRemoved,
  textProps,
}: UseCartItemReturnType & { textProps?: TextProps }) => {
  const { t } = useTranslation('cart');

  if (!isRemoved) return null;

  return (
    <Text color="mist-500" weight="semi-bold" {...textProps}>
      {t('removed')}
    </Text>
  );
};
