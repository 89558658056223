import React from 'react';
import { getFormattedPrice } from '@utils/functions/price';
import { PriceProps } from './Price.types';

export const Price = ({ children = 0, format = 'full' }: PriceProps) => {
  const { fullPrice, integerPrice, noCurrencyPrice } = getFormattedPrice(children);

  let content: React.ReactNode;

  switch (format) {
    case 'full':
      content = fullPrice;
      break;
    case 'integer':
      content = integerPrice;
      break;
    case 'no-currency':
      content = noCurrencyPrice;
      break;
  }

  return <>{content}</>;
};
