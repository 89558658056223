import { ENV_REGION } from '@utils/constants/region.constants';
import { SITE_REGION_URLS } from '@utils/constants/site-urls.constants';
import { SiteRegionURLsType } from '@utils/types/url.types';

export const getRegionUrl = (region?: SiteRegionURLsType) => SITE_REGION_URLS[region ?? ENV_REGION];

export function getWPTenantForRegion(region: SiteRegionURLsType) {
  switch (region) {
    case 'roi':
      return 'ie'; // https://www.therapieclinic.com/ie
    case 'uk':
      return ''; // https://www.therapieclinic.com
    case 'us':
      return 'us'; // https://us.therapieclinic.com
    default:
      return 'ie';
  }
}

export function getWPUrlForRegion(region: SiteRegionURLsType) {
  /** This is the Wordpress URL that matches the region specified with `NEXT_PUBLIC_DEFAULT_REGION` */
  const WORDPRESS_BASE_URL = 'https://www.therapieclinic.com';
  const WORDPRESS_SUBDOMAIN_URL = 'https://[subdomain].therapieclinic.com';

  const WPTenant = getWPTenantForRegion(region);

  if (region === 'us') {
    return WORDPRESS_SUBDOMAIN_URL.replace('[subdomain]', WPTenant);
  }

  return `${WORDPRESS_BASE_URL}${!!WPTenant ? `/${WPTenant}` : ''}`;
}

export const isUSRegion = () => ENV_REGION === 'us';
