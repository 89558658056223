import { SITE_URLS } from '@utils/constants/site-urls.constants';
import { CartItemType } from '@/data/graphql/types';

export const getItemHref = (type: CartItemType, slug: string) => {
  if (type === CartItemType.Product) {
    return `${SITE_URLS.PRODUCTS}/${slug}`;
  }
  if (type === CartItemType.Treatment) {
    // If the slug already contains the treatments url, return it as is
    if (slug.slice(0, SITE_URLS.TREATMENTS.length) === SITE_URLS.TREATMENTS) {
      return slug;
    }
    return `${SITE_URLS.TREATMENTS}/${slug}`;
  }
  return slug;
};
