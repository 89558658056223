import { useRef } from 'react';
import { useTranslation } from 'next-i18next';

import { Drawer, Title } from '@therapie-ecommerce-ui/ui';
import { clearRemovedItemsCartState } from '@features/cart-and-checkout/store/cart.store';
import { useSegmentAnalytics } from '@hooks/useSegmentAnalytics/useSegmentAnalytics';
import { useCartId } from '@features/cart-and-checkout/hooks/useCartId';
import { useCartItems } from '@features/cart-and-checkout/hooks/useCartItems';
import { buildLinkUrlForCartItem } from '@features/booking/utils/booking.utils';

import { CartCta } from '../cart-cta/CartCta';
import { CartSummary } from '../cart-summary/CartSummary';
import { CartSidebarProps, FooterContentProps } from './CartSidebar.types';

import styles from './cart-sidebar.module.scss';

export const CartSidebar = ({ isOpen, onClose, children }: CartSidebarProps) => {
  const { t } = useTranslation('cart');
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const { sendTrackEvent } = useSegmentAnalytics();
  const { items } = useCartItems();
  const cartId = useCartId();

  const sendCartViewAnalytics = () => {
    sendTrackEvent({
      event: 'Cart Viewed',
      properties: {
        cartId: cartId!,
        products: items.map((item) => ({
          productId: item.itemId,
          crmProductId: item.crmId,
          type: item.type,
          treatmentType: item.treatmentType,
          name: item.name,
          price: item.price,
          quantity: item.quantity,
          url: buildLinkUrlForCartItem(item.type, item.slug),
        })),
      },
    });
  };

  return (
    <Drawer
      isOpen={isOpen}
      keepMounted={true}
      onClose={onClose}
      closeButtonProps={{
        'aria-label': t('continueShopping'),
      }}
      onEntered={() => sendCartViewAnalytics()}
      onExited={() => clearRemovedItemsCartState()}
      initialFocusRef={buttonRef}
      dialogClassName={styles['cart__dialog']}
      headerClassName={styles['cart__header']}
      bodyClassName={styles['cart__body']}
      footerClassName={styles['cart__footer']}
      headerContent={<HeaderContent />}
      bodyContent={children}
      footerContent={<FooterContent buttonRef={buttonRef} onClose={onClose} />}
    />
  );
};

const HeaderContent = () => {
  const { t } = useTranslation('cart');

  return (
    <Title variant="h3" weight="semi-bold">
      {t('yourShoppingCart')}
    </Title>
  );
};

const FooterContent = ({ onClose, buttonRef }: FooterContentProps) => {
  return (
    <>
      <CartSummary />
      <CartCta
        className={styles['cart__cta']}
        checkoutButtonProps={{ ref: buttonRef }}
        continueShoppingButtonProps={{ onClick: onClose }}
      />
    </>
  );
};
