import React from 'react';

import { cx } from '@therapie-ecommerce-ui/ui';

import { Footer } from '../footer/Footer';
import { ContentProps } from './Content.types';

import styles from './content.module.scss';

export const Content = ({ className, children, hasSecondaryBg, hasFooter }: ContentProps) => {
  return (
    <div className={styles['content']}>
      <main
        className={cx(
          styles['content__main'],
          { [styles['content__main--grey-bg']]: hasSecondaryBg },
          className
        )}
        id="main"
      >
        {children}
      </main>
      {hasFooter && <Footer />}
    </div>
  );
};
