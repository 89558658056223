import React from 'react';

import { useTranslation } from 'next-i18next';
import { Text, IconButton, cx } from '@therapie-ecommerce-ui/ui';

import { QuantitySpinButtonsProps } from './QuantitySpinButtons.types';

import styles from './quantity-spin-buttons.module.scss';

export const QuantitySpinButtons = ({
  className = '',
  buttonClassName = '',
  quantityClassName = '',
  decrementDisabled = false,
  handleDecrement,
  handleIncrement,
  incrementDisabled = false,
  quantity,
}: QuantitySpinButtonsProps) => {
  const { t } = useTranslation('common');

  return (
    <div className={cx(styles['quantity-spin-buttons'], className)}>
      <IconButton
        aria-label={t('buttons.decreaseQuantity')}
        variant="outline"
        colorScheme="night"
        isDisabled={decrementDisabled}
        iconProps={{
          name: 'remove',
          size: 'sm',
        }}
        size="sm"
        onClick={handleDecrement}
        className={cx(styles['quantity-spin-buttons__button'], buttonClassName)}
      />
      <Text
        aria-labelledby="quantity"
        weight="semi-bold"
        className={cx(styles['quantity-spin-buttons__quantity'], quantityClassName)}
      >
        {quantity}
      </Text>
      <IconButton
        aria-label={t('buttons.increaseQuantity')}
        variant="outline"
        colorScheme="night"
        iconProps={{
          name: 'add',
          size: 'sm',
        }}
        size="sm"
        isDisabled={incrementDisabled}
        onClick={handleIncrement}
        className={cx(styles['quantity-spin-buttons__button'], buttonClassName)}
      />
    </div>
  );
};
