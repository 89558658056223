import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { sortObjectsListByAttributes } from '@therapie-ecommerce-ui/ui';
import {
  CartItemInState,
  EnrichedCartItemType,
} from '@features/cart-and-checkout/store/cart.types';
import { getEnrichedItems } from './useGetEnrichedCartItems.utils';

export const useGetEnrichedCartItems = (cartItems: CartItemInState[]) => {
  const queryClient = useQueryClient();

  /**
   * UI-TODO: Sorting by name for now, to prevent items jumping on update.
   * Maybe a better way to sort in the future?
   */
  return useMemo(
    () => sortObjectsListByAttributes(getEnrichedItems(cartItems, queryClient), ['name']),
    [cartItems, queryClient]
  ) as EnrichedCartItemType[];
};
