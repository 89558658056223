import React from 'react';

import { CartSidebar } from '@features/cart-and-checkout/components/cart-sidebar/CartSidebar';
import { CartIcon } from '@features/cart-and-checkout/components/cart-icon/CartIcon';
import { useDevice } from '@hooks/useDevice/useDevice';
import { CartItems } from '@features/cart-and-checkout/components/cart-items/CartItems';
import { useCartState } from '@features/cart-and-checkout/components/cart/hooks/useCartState';

export const Cart = () => {
  const { isMobile } = useDevice();
  const { isOpen, handleOpenCartSidebar, handleCloseCartSidebar } = useCartState();

  return (
    <>
      <CartIcon onClick={handleOpenCartSidebar} />
      <CartSidebar isOpen={isOpen} onClose={handleCloseCartSidebar}>
        <CartItems />
      </CartSidebar>
    </>
  );
};
