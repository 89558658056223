export const formatOptionsLabel = (label?: string | null) => {
  if (!label || !label.trim()) return '';

  const words = label.toLowerCase().split(' ');

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].substring(1);
  }

  return words.join(' ');
};
