import { cx, Text } from '@therapie-ecommerce-ui/ui';
import { CartItemLink } from '../../cart-item-link/CartItemLink';
import { CartProductNameProps } from './CartProductName.types';
import { useProductName } from '@features/product-catalog/hooks/useProductName/useProductName';

import styles from '../cart-item-name.module.scss';

export const CartProductName = ({
  textProps,
  name: nameProp,
  itemHref,
  isLoading,
  isRemoved,
  handleSendAnalytics,
  type,
}: CartProductNameProps) => {
  const name = useProductName({ type, name: nameProp });

  return (
    <CartItemLink
      href={itemHref}
      className={styles['item-name__link']}
      isDisabled={isLoading || isRemoved}
      onClick={handleSendAnalytics}
    >
      <Text
        color="inherit"
        className={cx(styles['item-name'], {
          [styles['item-name--removed']]: isRemoved,
        })}
        weight="semi-bold"
        {...textProps}
      >
        {name}
      </Text>
    </CartItemLink>
  );
};
