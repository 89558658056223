import { TFunction } from 'react-i18next';
import { InitialFooterGroup } from './Footer.types';
import { getFooterLinks } from './Footer.utils';

export const FOOTER_GROUPS = (t: TFunction<['footer', 'navbar']>): InitialFooterGroup[] => [
  {
    title: 'locations',
    links: getFooterLinks(t, t('footer:navigation.locations.groupLinks', { returnObjects: true })),
  },
  {
    title: 'treatments',
    links: getFooterLinks(t, t('footer:navigation.treatments.groupLinks', { returnObjects: true })),
  },
  {
    title: 'company',
    links: getFooterLinks(t, t('footer:navigation.company.groupLinks', { returnObjects: true })),
  },
  {
    title: 'legalStuff',
    links: getFooterLinks(t, t('footer:navigation.legalStuff.groupLinks', { returnObjects: true })),
  },
];
