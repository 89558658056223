import produce from 'immer';
import create from 'zustand';
import { persist } from 'zustand/middleware';
import { zsDevtools } from '@core/zustand/zustand.utils';
import { STORE_ACTIONS, STRIPE_LS_KEY } from './stripe.constants';
import { IntentGroupType, StripeActions, StripeState } from './stripe.types';

const getInitialState = () =>
  ({
    payments: {
      paymentIntent: null,
      confirmedPaymentMode: null,
    },
    setups: {
      setupIntent: null,
      confirmedPaymentMode: null,
    },
  } as const);

// TODO: Remove this to begin persisting state
const _persist = ((fn: any) => fn) as unknown as typeof persist;

export const useStripeStore = create<StripeState & StripeActions>(
  _persist(
    zsDevtools((set) => ({
      ...getInitialState(),
      actions: {
        setPaymentIntent: (payload) =>
          set(
            produce<StripeState>((state) => {
              state.payments = {
                ...state.payments,
                ...payload,
              };
            }),
            false,
            STORE_ACTIONS.SET_PAYMENT_INTENT
          ),
        setSetupIntent: (payload) =>
          set(
            produce<StripeState>((state) => {
              state.setups = {
                ...state.setups,
                ...payload,
              };
            }),
            false,
            STORE_ACTIONS.SET_SETUP_INTENT
          ),
        resetIntent: (intent) => {
          const intentGroup: IntentGroupType = intent === 'paymentIntent' ? 'payments' : 'setups';
          return set(
            (state) => ({
              ...state,
              [intentGroup]: {
                [intent]: null,
                confirmedPaymentMode: null,
              },
            }),
            false,
            intent === 'paymentIntent'
              ? STORE_ACTIONS.RESET_PAYMENT_INTENT
              : STORE_ACTIONS.RESET_SETUP_INTENT
          );
        },
        resetAllIntents: () => set(getInitialState(), false, STORE_ACTIONS.RESET_INTENTS),
      },
    })),
    {
      name: STRIPE_LS_KEY,
      // TODO: Persist setups
      partialize: ({ payments }) => ({
        payments: {
          paymentIntent: payments.paymentIntent?.id
            ? {
                id: payments.paymentIntent?.id,
              }
            : null,
        },
      }),
    }
  )
);

export const stripeActionsState = useStripeStore.getState().actions;

export const useStripeActions = () => useStripeStore((s) => s.actions);
export const useStripeSetupIntent = () => useStripeStore((s) => s.setups);
export const useStripePaymentIntent = () => useStripeStore((s) => s.payments);
