import { useCallback, useMemo, useState } from 'react';
import { segmentAnalytics } from '@core/segment-analytics/segment-analytics';
import {
  SendIdentifyEventData,
  SendPageEventData,
  SendTrackEventData,
  TrackEvents,
} from '@core/segment-analytics/segment-analytics.types';
import { useIsWebView } from '@features/web-view/hooks/useIsWebView/useIsWebView';
import { useCookieConsent } from '@hooks/useCookieConsent/useCookieConsent';
import { generateUUIDv4 } from '@utils/functions/uuid';

export const useSegmentAnalytics = () => {
  const [id] = useState(generateUUIDv4());
  const { cookieConsent } = useCookieConsent();

  const { isWebView } = useIsWebView();

  const commonContext = useMemo(
    () => ({
      source: isWebView ? 'mobile_app' : 'web',
    }),
    [isWebView]
  );

  const mergeOptions = useCallback(
    (options?: SegmentAnalytics.SegmentOpts) => ({
      ...options,
      context: { ...options?.context, ...commonContext },
    }),
    [commonContext]
  );

  const sendPageEvent = useCallback(
    (args: SendPageEventData = {}) => {
      const { statistics, marketing } = cookieConsent ?? {};

      if (!statistics && !marketing) return;

      const { category, name, properties, options, callback } = args;
      const mergedOptions = mergeOptions(options);

      segmentAnalytics.sendPageEvent({
        category,
        name,
        properties,
        options: mergedOptions,
        callback,
      });
    },
    [cookieConsent, mergeOptions]
  );

  const sendIdentifyEvent = useCallback(
    (args: SendIdentifyEventData) => {
      const { marketing } = cookieConsent ?? {};

      if (!marketing) return;

      const { userId, traits, options, callback } = args;
      const mergedOptions = mergeOptions(options);

      segmentAnalytics.sendIdentifyEvent({
        userId,
        traits,
        options: mergedOptions,
        callback,
      });
    },
    [cookieConsent, mergeOptions]
  );

  const sendTrackEvent = useCallback(
    <TEvent extends TrackEvents = TrackEvents>(args: SendTrackEventData<TEvent>) => {
      const { statistics, marketing } = cookieConsent ?? {};

      if (!statistics && !marketing) return;

      const { event, properties, options, callback } = args;
      const mergedOptions = mergeOptions(options);

      segmentAnalytics.sendTrackEvent<TEvent>({
        event,
        properties,
        options: mergedOptions,
        callback,
      });
    },
    [cookieConsent, mergeOptions]
  );

  return {
    /** can be used to correlate analytics events with this `id` */
    id,
    sendPageEvent,
    sendIdentifyEvent,
    sendTrackEvent,
  };
};
