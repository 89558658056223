import { QueryClient } from 'react-query';
import { getItemDataFromQueryCache } from '@utils/enrichment/item-enrichment';
import { FormattedItemForCache } from '@utils/enrichment/item-enrichment.types';
import { CartItemType } from '@/data/graphql/types';

type BasicItem = { itemId: string };

export const getEnrichedItems = <TData extends BasicItem>(
  cartItems: TData[],
  queryClient: QueryClient
) => {
  return cartItems?.reduce<(FormattedItemForCache & TData)[]>((items, item) => {
    const cachedItem = getItemDataFromQueryCache(item.itemId, queryClient);

    if (!cachedItem) {
      return items;
    }

    const { treatmentGroup, treatmentCollection, treatmentType, outOfStock, type, ...restItem } =
      cachedItem;

    const newItem = {
      ...restItem,
      ...item,
      type,
      ...(type === CartItemType.Treatment && {
        treatmentGroup,
        treatmentCollection,
        treatmentType,
      }),
      ...(type === CartItemType.Product && {
        outOfStock,
      }),
    };

    return [...items, newItem];
  }, []);
};
