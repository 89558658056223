/**
 * FetchClient provides a wrapper around `fetch` with options to
 * set a base URL and common headers for all the requests.
 *
 * To debug a call, add the following code after `await fetch` line:
 * ```
 * console.log(`${this.baseUrl}${url}`, params);
 * const t = await res.text();
 * console.log(res.ok, t);
 * ```
 * And then change the return from `await res.json()` to `JSON.parse(t)` (without await).
 */

import { RequestOptions } from './client.types';

export class FetchClient {
  baseUrl: string;
  commonHeaders: HeadersInit;

  constructor(baseUrl: string = '', commonHeaders: HeadersInit = {}) {
    this.baseUrl = baseUrl;
    this.commonHeaders = commonHeaders;
  }

  async request<TResponse>(
    url: string,
    params: RequestInit,
    options: RequestOptions = {}
  ): Promise<TResponse> {
    const reqParams: RequestInit = {
      ...params,
      headers: {
        ...params.headers,
        ...this.commonHeaders,
        ...options.headers,
      },
    };
    const res = await fetch(`${this.baseUrl}${url}`, reqParams);
    if (!res.ok) {
      // Forward the text of the failing call
      const text = await res.text();
      throw text;
    }
    return await res.json();
  }

  async get<TResponse = unknown>(
    url: string,
    options: RequestOptions | undefined = {}
  ): Promise<TResponse> {
    return await this.request<TResponse>(
      url,
      {
        method: METHODS.GET,
      },
      options
    );
  }

  async post<TRequest, TResponse = unknown>(
    url: string,
    body?: TRequest,
    options: RequestOptions | undefined = {},
    params: RequestInit = {}
  ): Promise<TResponse> {
    return await this.request<TResponse>(
      url,
      {
        method: METHODS.POST,
        body: JSON.stringify(body),
        ...params,
      },
      options
    );
  }

  async put<TRequest, TResponse = unknown>(
    url: string,
    body: TRequest,
    options: RequestOptions | undefined = {}
  ): Promise<TResponse> {
    return await this.request<TResponse>(
      url,
      {
        method: METHODS.PUT,
        body: JSON.stringify(body),
      },
      options
    );
  }

  async delete<TResponse = unknown>(
    url: string,
    params: RequestInit = {},
    options: RequestOptions | undefined = {}
  ): Promise<TResponse> {
    return await this.request<TResponse>(
      url,
      {
        method: METHODS.DELETE,
        ...params,
      },
      options
    );
  }
}

const METHODS = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  PUT: 'PUT',
  DELETE: 'DELETE',
} as const;
