import { useTranslation } from 'next-i18next';
import { cx, Text, TextProps } from '@therapie-ecommerce-ui/ui';
import { UseCartItemReturnType } from '../hooks/useCartItem.types';
import styles from './cart-item-out-of-stock.module.scss';

export const CartItemOutOfStock = ({
  isRemoved,
  outOfStock,
  textProps = {},
}: UseCartItemReturnType & { textProps?: TextProps }) => {
  const { t } = useTranslation('cart');

  if (isRemoved || !outOfStock) return null;

  const { className: textClassName, ...restTextProps } = textProps;

  const className = cx(styles['out-of-stock'], textClassName);

  return (
    <Text className={className} weight="semi-bold" color="scarlett-500" {...restTextProps}>
      {t('outOfStock')}
    </Text>
  );
};
