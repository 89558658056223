export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  defaultBilling?: Maybe<Scalars['Boolean']>;
  defaultShipping?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  defaultBilling?: InputMaybe<Scalars['Boolean']>;
  defaultShipping?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type Appointment = {
  __typename?: 'Appointment';
  appointmentDate?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  machineId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['String']>;
  staffRequest?: Maybe<Scalars['Boolean']>;
  startTime?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
};

export type AppointmentFailToCancel = {
  __typename?: 'AppointmentFailToCancel';
  appointmentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export type Appointments = {
  serviceSchedules: Array<TreatmentScheduleInput>;
};

export type AppointmentsInput = {
  branchId: Scalars['String'];
  fetchCancelled: Scalars['Boolean'];
  fromDate: Scalars['String'];
  toDate: Scalars['String'];
};

export type AvailableStaff = {
  __typename?: 'AvailableStaff';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type AvailableTimeslots = {
  __typename?: 'AvailableTimeslots';
  startTime?: Maybe<Scalars['String']>;
  treatmentSchedules?: Maybe<Array<TreatmentSchedule>>;
};

export type BookingHistory = {
  __typename?: 'BookingHistory';
  bookingId?: Maybe<Scalars['String']>;
  bookings?: Maybe<Array<BookingHistoryItem>>;
  branchId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
};

export type BookingHistoryItem = {
  __typename?: 'BookingHistoryItem';
  appointmentId?: Maybe<Scalars['String']>;
  cancelled?: Maybe<Scalars['Boolean']>;
  crmUserTreatmentItemId?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  treatmentCategory?: Maybe<Scalars['String']>;
  treatmentId?: Maybe<Scalars['String']>;
  treatmentTitle?: Maybe<Scalars['String']>;
};

export type BookingUpdateInput = {
  appointments: Array<Appointments>;
  clinicId: Scalars['String'];
};

export type BuyNowPayLater = {
  __typename?: 'BuyNowPayLater';
  enabled?: Maybe<Scalars['Boolean']>;
  installments?: Maybe<Scalars['Int']>;
  surchargePercentage?: Maybe<Scalars['Float']>;
};

export enum CancelAppointmentStatusGql {
  Failure = 'FAILURE',
  Success = 'SUCCESS',
}

export type CancelUserAppointmentsResponseGql = {
  __typename?: 'CancelUserAppointmentsResponseGql';
  appointmentId: Scalars['String'];
  clinicId: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  status: CancelAppointmentStatusGql;
};

export type Card = {
  __typename?: 'Card';
  brand: Scalars['String'];
  exp_month: Scalars['Int'];
  exp_year: Scalars['Int'];
  last4: Scalars['String'];
};

export type Cart = {
  __typename?: 'Cart';
  createdAt: Scalars['String'];
  deliveryCost?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  items?: Maybe<Array<CartItem>>;
  subtotalCost?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type CartItem = {
  __typename?: 'CartItem';
  addedAt: Scalars['String'];
  itemCost: Scalars['Float'];
  itemId: Scalars['String'];
  priceChanged?: Maybe<Scalars['Boolean']>;
  quantity: Scalars['Int'];
  totalCost: Scalars['Float'];
  type: Scalars['String'];
  updatedAt: Scalars['String'];
};

export enum CartItemType {
  Product = 'PRODUCT',
  Treatment = 'TREATMENT',
  Voucher = 'VOUCHER',
}

export enum ChannelSourceInput {
  MobileApp = 'MOBILE_APP',
  Web = 'WEB',
}

export type City = {
  __typename?: 'City';
  name?: Maybe<Scalars['String']>;
  stores?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ClientAppointmentSchedule = {
  __typename?: 'ClientAppointmentSchedule';
  clientId?: Maybe<Scalars['String']>;
  serviceSchedules?: Maybe<Array<Maybe<ServiceSchedule>>>;
};

export type Clinic = {
  __typename?: 'Clinic';
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  crmId: Scalars['String'];
  id: Scalars['String'];
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type ClinicIdName = {
  __typename?: 'ClinicIdName';
  crmId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ClinicsData = {
  __typename?: 'ClinicsData';
  clinics?: Maybe<Array<Maybe<Clinic>>>;
};

export type CreateOrderInput = {
  billingAddress?: InputMaybe<AddressInput>;
  channelSource: ChannelSourceInput;
  payments: Array<PurchasePayment>;
  purchaseNumber?: InputMaybe<Scalars['String']>;
  shippingAddress?: InputMaybe<AddressInput>;
};

export type CreateOrderResponse = {
  __typename?: 'CreateOrderResponse';
  orderId: Scalars['String'];
};

export type CreatePaymentIntentResponse = {
  __typename?: 'CreatePaymentIntentResponse';
  intent: Scalars['String'];
  status: CreatePaymentIntentStatus;
};

export enum CreatePaymentIntentStatus {
  IntentCreatedSuccessfully = 'INTENT_CREATED_SUCCESSFULLY',
}

export type CreatePaymentIntentValuesInput = {
  amount: Scalars['Float'];
  captureMethod: Scalars['String'];
  confirm?: InputMaybe<Scalars['Boolean']>;
  currency: Scalars['String'];
  metadataJson?: InputMaybe<Scalars['String']>;
  paymentMethodTypes?: InputMaybe<Array<Scalars['String']>>;
};

export type CreatePurchaseTransactionResponse = {
  __typename?: 'CreatePurchaseTransactionResponse';
  clientId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createDateISO?: Maybe<Scalars['String']>;
  currentStep?: Maybe<Scalars['String']>;
  currentStepStatus?: Maybe<PaymentTransactionStatus>;
  errors?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  status?: Maybe<PaymentTransactionStatus>;
  updateDateISO?: Maybe<Scalars['String']>;
};

export type CreateUserBookingResponse = {
  __typename?: 'CreateUserBookingResponse';
  bookingId?: Maybe<Scalars['String']>;
  clientAppointmentSchedules?: Maybe<Array<ClientAppointmentSchedule>>;
  clientId: Scalars['String'];
};

export type CreateUserInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type CreatedVoucher = {
  __typename?: 'CreatedVoucher';
  expiryDate: Scalars['String'];
  remainingBalance: Scalars['Float'];
  serialNumber: Scalars['String'];
};

export type DeletePaymentIntentResponse = {
  __typename?: 'DeletePaymentIntentResponse';
  intent: Scalars['String'];
  status: DeletePaymentIntentStatus;
};

export enum DeletePaymentIntentStatus {
  IntentDeletedSuccessfully = 'INTENT_DELETED_SUCCESSFULLY',
}

export type DeletePaymentMethodResponse = {
  __typename?: 'DeletePaymentMethodResponse';
  error?: Maybe<Scalars['String']>;
  status: DeletePaymentMethodStatus;
};

export enum DeletePaymentMethodStatus {
  PaymentMethodDeletedSuccessfully = 'PAYMENT_METHOD_DELETED_SUCCESSFULLY',
  PaymentMethodLockedForBookings = 'PAYMENT_METHOD_LOCKED_FOR_BOOKINGS',
}

export type EnrichedCartGql = {
  __typename?: 'EnrichedCartGql';
  createdAt: Scalars['String'];
  deliveryCost: Scalars['Float'];
  grossSubtotalCost: Scalars['Float'];
  grossTotalCost: Scalars['Float'];
  id: Scalars['String'];
  items?: Maybe<Array<EnrichedCartItemGql>>;
  netSubtotalCost: Scalars['Float'];
  netTotalCost: Scalars['Float'];
  subtotalCost: Scalars['Float'];
  totalCost: Scalars['Float'];
  totalDiscount: Scalars['Float'];
  updatedAt?: Maybe<Scalars['String']>;
};

export type EnrichedCartItemGql = {
  __typename?: 'EnrichedCartItemGql';
  addedAt: Scalars['String'];
  grossItemCost: Scalars['Float'];
  id: Scalars['String'];
  itemCost: Scalars['Float'];
  itemDiscount: Scalars['Float'];
  itemId: Scalars['String'];
  netItemCost: Scalars['Float'];
  priceChanged?: Maybe<Scalars['Boolean']>;
  quantity: Scalars['Int'];
  totalCost: Scalars['Float'];
  type: Scalars['String'];
  updatedAt: Scalars['String'];
};

export enum FulfillmentStatus {
  Fulfilled = 'FULFILLED',
  Unfulfilled = 'UNFULFILLED',
}

export type GetAppointmentTimeslotsInput = {
  businessId: Scalars['String'];
  clinicId: Scalars['String'];
  crmTreatmentIds?: InputMaybe<Array<Scalars['String']>>;
  crmUserId?: InputMaybe<Scalars['String']>;
  fromDateTime: Scalars['String'];
  returnAlternativeStaff?: InputMaybe<Scalars['Boolean']>;
  staffId?: InputMaybe<Scalars['String']>;
  toDateTime: Scalars['String'];
};

export type GetAppointmentTimeslotsResponse = {
  __typename?: 'GetAppointmentTimeslotsResponse';
  availableStaff?: Maybe<Array<AvailableStaff>>;
  availableTimeslots?: Maybe<Array<AvailableTimeslots>>;
  multiCategory?: Maybe<Scalars['Boolean']>;
};

export type GetAppointmentsResponse = {
  __typename?: 'GetAppointmentsResponse';
  appointments?: Maybe<Array<Appointment>>;
  page?: Maybe<Page>;
};

export type GetTreatmentInput = {
  nuaSlug?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type GetUserBookingsHistoryInput = {
  page?: InputMaybe<PaginationInput>;
};

export type GetUserTreatmentsInput = {
  includeArchived?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<PaginationInput>;
};

export type GqlCognitoUser = {
  __typename?: 'GqlCognitoUser';
  email: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  phoneNumber: Scalars['String'];
  phoneNumberVerified?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};

export type Image = {
  __typename?: 'Image';
  original?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
};

export type ImageInput = {
  original?: InputMaybe<Scalars['String']>;
  thumbnail?: InputMaybe<Scalars['String']>;
};

export type LookupValue = {
  __typename?: 'LookupValue';
  name: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type MergeInfo = {
  __typename?: 'MergeInfo';
  date?: Maybe<Scalars['String']>;
  executedBy?: Maybe<Scalars['String']>;
  mergedWithUserId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type MergeUsersInput = {
  destinationUserId: Scalars['String'];
  sourceUserId: Scalars['String'];
};

export type MergeUsersResponse = {
  __typename?: 'MergeUsersResponse';
  activeUser?: Maybe<UserOrders>;
  inactiveUser?: Maybe<UserOrders>;
};

export type MobileAction = {
  __typename?: 'MobileAction';
  action: Scalars['String'];
  platform: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  version: Scalars['String'];
};

export type MobileActionInput = {
  platform: Scalars['String'];
  version: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addCartItem?: Maybe<Cart>;
  cancelUserAppointments?: Maybe<Array<Maybe<CancelUserAppointmentsResponseGql>>>;
  createOrder?: Maybe<CreateOrderResponse>;
  createPaymentIntent?: Maybe<CreatePaymentIntentResponse>;
  createPurchaseTransactionSync?: Maybe<CreatePurchaseTransactionResponse>;
  createUser?: Maybe<Scalars['String']>;
  createUserBooking?: Maybe<CreateUserBookingResponse>;
  deletePaymentIntent?: Maybe<DeletePaymentIntentResponse>;
  deletePaymentMethod?: Maybe<DeletePaymentMethodResponse>;
  deleteUser?: Maybe<Scalars['Boolean']>;
  emptyCart?: Maybe<Cart>;
  forceResetUserPassword?: Maybe<Scalars['Boolean']>;
  mergeUsers?: Maybe<MergeUsersResponse>;
  rateLimitUser?: Maybe<Scalars['Boolean']>;
  refreshCartAfterLogin?: Maybe<Cart>;
  sendVouchers?: Maybe<Scalars['Boolean']>;
  setUserPassword?: Maybe<Scalars['Boolean']>;
  triggerTransaction?: Maybe<TriggerTransactionResponse>;
  unblockUsers?: Maybe<Scalars['Int']>;
  updateEmail?: Maybe<Scalars['Boolean']>;
  updatePaymentIntent?: Maybe<UpdatePaymentIntentResponse>;
  updatePhone?: Maybe<Scalars['Boolean']>;
  updateUser?: Maybe<User>;
  updateUserBooking?: Maybe<Array<TreatmentUpdateSchedule>>;
  verifyEmail?: Maybe<Scalars['Boolean']>;
  verifyPhone?: Maybe<Scalars['Boolean']>;
};

export type MutationAddCartItemArgs = {
  cartId: Scalars['String'];
  itemId: Scalars['String'];
  quantity: Scalars['Int'];
  type: CartItemType;
};

export type MutationCancelUserAppointmentsArgs = {
  input: Array<UserAppointmentCancellationInput>;
};

export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};

export type MutationCreatePaymentIntentArgs = {
  intentType: PaymentIntentType;
  values: CreatePaymentIntentValuesInput;
};

export type MutationCreatePurchaseTransactionSyncArgs = {
  input: CreateOrderInput;
};

export type MutationCreateUserArgs = {
  user: CreateUserInput;
};

export type MutationCreateUserBookingArgs = {
  input: UserBookingInput;
};

export type MutationDeletePaymentIntentArgs = {
  intentId: Scalars['String'];
  intentType: PaymentIntentType;
};

export type MutationDeletePaymentMethodArgs = {
  paymentMethodId: Scalars['String'];
};

export type MutationDeleteUserArgs = {
  username: Scalars['String'];
};

export type MutationEmptyCartArgs = {
  cartId: Scalars['String'];
};

export type MutationForceResetUserPasswordArgs = {
  sendCode: Scalars['Boolean'];
};

export type MutationMergeUsersArgs = {
  input: MergeUsersInput;
};

export type MutationRateLimitUserArgs = {
  input: RateLimitUser;
};

export type MutationRefreshCartAfterLoginArgs = {
  cartId: Scalars['String'];
};

export type MutationSendVouchersArgs = {
  orderId: Scalars['String'];
};

export type MutationSetUserPasswordArgs = {
  password: Scalars['String'];
};

export type MutationTriggerTransactionArgs = {
  orderId: Scalars['String'];
};

export type MutationUnblockUsersArgs = {
  users: Array<RateLimitUser>;
};

export type MutationUpdateEmailArgs = {
  AccessToken: Scalars['String'];
  Value: Scalars['String'];
};

export type MutationUpdatePaymentIntentArgs = {
  intentId: Scalars['String'];
  intentType: PaymentIntentType;
  values: UpdatePaymentIntentValuesInput;
};

export type MutationUpdatePhoneArgs = {
  AccessToken: Scalars['String'];
  Value: Scalars['String'];
};

export type MutationUpdateUserArgs = {
  user: UpdateUserInput;
};

export type MutationUpdateUserBookingArgs = {
  input: UserBookingUpdateInput;
};

export type MutationVerifyEmailArgs = {
  AccessToken: Scalars['String'];
  Code: Scalars['String'];
};

export type MutationVerifyPhoneArgs = {
  AccessToken: Scalars['String'];
  Code: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  billingDetails?: Maybe<Address>;
  cardPayment?: Maybe<Scalars['Float']>;
  date: Scalars['String'];
  deliveryCost: Scalars['Float'];
  fulfillmentStatus: FulfillmentStatus;
  isDeleted?: Maybe<Scalars['Boolean']>;
  items: Array<OrderItem>;
  loyaltyPointsPayment: Scalars['Float'];
  mergedFromUserId?: Maybe<Scalars['String']>;
  orderId: Scalars['String'];
  paymentStatus: PaymentStatus;
  paymentStatusLastChange?: Maybe<Scalars['String']>;
  publicId?: Maybe<Scalars['String']>;
  shippingDetails?: Maybe<Address>;
  taxes: Scalars['Float'];
  total: Scalars['Float'];
  userId: Scalars['String'];
  voucherPayment?: Maybe<OrderVoucher>;
  vouchers?: Maybe<Array<Maybe<CreatedVoucher>>>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  grossItemCost?: Maybe<Scalars['Float']>;
  itemCost: Scalars['Float'];
  itemDiscount?: Maybe<Scalars['Float']>;
  itemId: Scalars['String'];
  name: Scalars['String'];
  netItemCost?: Maybe<Scalars['Float']>;
  quantity: Scalars['Int'];
  taxRate?: Maybe<Scalars['Float']>;
  totalCost: Scalars['Float'];
  totalDiscount?: Maybe<Scalars['Float']>;
  type: Scalars['String'];
};

export type OrderVoucher = {
  __typename?: 'OrderVoucher';
  amount: Scalars['Float'];
  serialNumber: Scalars['String'];
};

export type Page = {
  __typename?: 'Page';
  number?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  totalElements?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type PaginationInput = {
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};

export enum PaymentIntentType {
  PaymentIntent = 'PAYMENT_INTENT',
  SetupIntent = 'SETUP_INTENT',
}

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  card?: Maybe<Card>;
  id: Scalars['String'];
};

export type PaymentServiceUser = {
  __typename?: 'PaymentServiceUser';
  id?: Maybe<Scalars['String']>;
};

export enum PaymentStatus {
  Open = 'OPEN',
  Paid = 'PAID',
  Pending = 'PENDING',
  Unpaid = 'UNPAID',
}

export enum PaymentTransactionStatus {
  Failed = 'failed',
  Pending = 'pending',
  Succeeded = 'succeeded',
}

export type PhorestUser = {
  __typename?: 'PhorestUser';
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientSince?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  creatingBranchId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyPoints?: Maybe<Scalars['Float']>;
  mergedToClientId?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
};

export type Product = {
  __typename?: 'Product';
  barcodes?: Maybe<Array<Scalars['String']>>;
  brandName?: Maybe<Scalars['String']>;
  comparePrice?: Maybe<Scalars['Float']>;
  crmId?: Maybe<Scalars['String']>;
  dateAdded?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Image>>;
  isOnSale?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  nuaId?: Maybe<Scalars['String']>;
  outOfStock?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Float']>;
  priceWithTax?: Maybe<Scalars['Float']>;
  priceWithoutTax?: Maybe<Scalars['Float']>;
  sizeUnit?: Maybe<Scalars['String']>;
  sizeValue?: Maybe<Scalars['Float']>;
  sku?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<ProductStatus>;
  tags?: Maybe<Array<Tags>>;
};

export type ProductGroup = {
  __typename?: 'ProductGroup';
  component: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Int'];
  searchFilter: ProductSearch;
};

export type ProductSearch = {
  __typename?: 'ProductSearch';
  brandName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productRange?: Maybe<Scalars['String']>;
  productType?: Maybe<Array<Scalars['String']>>;
  skinConcern?: Maybe<Array<Scalars['String']>>;
  skinType?: Maybe<Array<Scalars['String']>>;
};

export type ProductSearchFilter = {
  __typename?: 'ProductSearchFilter';
  name: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type ProductSearchInput = {
  brandName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  productRange?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Array<Scalars['String']>>;
  skinConcern?: InputMaybe<Array<Scalars['String']>>;
  skinType?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Scalars['String']>;
};

export enum ProductStatus {
  Archived = 'ARCHIVED',
  Deleted = 'DELETED',
  Live = 'LIVE',
  NotLive = 'NOT_LIVE',
}

export type PurchasePayment = {
  amount: Scalars['Float'];
  code?: InputMaybe<Scalars['String']>;
  intentId?: InputMaybe<Scalars['String']>;
  intentType?: InputMaybe<PaymentIntentType>;
  type: PurchasePaymentType;
};

export enum PurchasePaymentType {
  Card = 'CARD',
  Loyalty = 'LOYALTY',
  Voucher = 'VOUCHER',
}

export type Query = {
  __typename?: 'Query';
  getAppointmentTimeslots: GetAppointmentTimeslotsResponse;
  getAppointments?: Maybe<GetAppointmentsResponse>;
  getCart?: Maybe<Cart>;
  getCartEnriched?: Maybe<EnrichedCartGql>;
  getClinics?: Maybe<ClinicsData>;
  getClinicsByTreatments?: Maybe<GetClinicsByTreatmentResponse>;
  getLookupValues?: Maybe<Array<LookupValue>>;
  getMobileAction?: Maybe<MobileAction>;
  getOrderById?: Maybe<Order>;
  getOrders?: Maybe<UserOrders>;
  getPaymentIntent?: Maybe<Scalars['String']>;
  getPaymentServiceUser: PaymentServiceUser;
  getProductBySlug?: Maybe<Product>;
  getProductGroups?: Maybe<Array<ProductGroup>>;
  getProductSearchFilters?: Maybe<Array<ProductSearchFilter>>;
  getProducts?: Maybe<Array<Product>>;
  getStaffByIds?: Maybe<Array<Staff>>;
  getTreatment?: Maybe<Treatment>;
  getTreatmentCollectionBySlug?: Maybe<TreatmentCollection>;
  getTreatmentCollections?: Maybe<Array<TreatmentCollectionCard>>;
  getTreatments?: Maybe<Array<Treatment>>;
  getUser?: Maybe<User>;
  getUserBookingsHistory?: Maybe<UserBookingsHistoryResponse>;
  getUserByReferralCode?: Maybe<UserFullName>;
  getUserLoyaltyPoints?: Maybe<Scalars['Float']>;
  getUserSavedPaymentMethods?: Maybe<Array<PaymentMethod>>;
  getUserTreatments?: Maybe<UserTreatmentsResponse>;
  getVoucherBalanceBySerialNumber?: Maybe<Scalars['Float']>;
  getVouchers?: Maybe<Array<Maybe<Voucher>>>;
  rateLimitInfo?: Maybe<Array<UserRateLimitInfo>>;
  searchProducts?: Maybe<SearchProductsResponse>;
  searchUser?: Maybe<SearchUserResponse>;
};

export type QueryGetAppointmentTimeslotsArgs = {
  query: GetAppointmentTimeslotsInput;
};

export type QueryGetAppointmentsArgs = {
  page?: InputMaybe<PaginationInput>;
  query: AppointmentsInput;
};

export type QueryGetCartArgs = {
  forceUpdate?: InputMaybe<Scalars['Boolean']>;
  query: Scalars['String'];
};

export type QueryGetCartEnrichedArgs = {
  cartId: Scalars['String'];
};

export type QueryGetClinicsByTreatmentsArgs = {
  treatmentIds: Array<Scalars['String']>;
};

export type QueryGetMobileActionArgs = {
  query: MobileActionInput;
};

export type QueryGetOrderByIdArgs = {
  orderId: Scalars['String'];
};

export type QueryGetOrdersArgs = {
  lastOrderId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryGetPaymentIntentArgs = {
  intentId: Scalars['String'];
  intentType: PaymentIntentType;
};

export type QueryGetProductBySlugArgs = {
  query: Scalars['String'];
};

export type QueryGetProductGroupsArgs = {
  query: Scalars['String'];
};

export type QueryGetProductsArgs = {
  query: Array<Scalars['String']>;
};

export type QueryGetStaffByIdsArgs = {
  crmIds?: InputMaybe<Array<Scalars['String']>>;
  ids?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryGetTreatmentArgs = {
  input: GetTreatmentInput;
};

export type QueryGetTreatmentCollectionBySlugArgs = {
  query: Scalars['String'];
};

export type QueryGetTreatmentsArgs = {
  courseCrmIds?: InputMaybe<Array<Scalars['String']>>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  serviceCrmIds?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryGetUserArgs = {
  crmSync?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};

export type QueryGetUserBookingsHistoryArgs = {
  query: GetUserBookingsHistoryInput;
};

export type QueryGetUserByReferralCodeArgs = {
  referralCode: Scalars['String'];
};

export type QueryGetUserTreatmentsArgs = {
  query: GetUserTreatmentsInput;
};

export type QueryGetVoucherBalanceBySerialNumberArgs = {
  serialNumber: Scalars['String'];
};

export type QueryRateLimitInfoArgs = {
  query?: InputMaybe<Scalars['String']>;
};

export type QuerySearchProductsArgs = {
  query: ProductSearchInput;
};

export type QuerySearchUserArgs = {
  crmId?: InputMaybe<Scalars['String']>;
  emails?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id?: InputMaybe<Scalars['String']>;
  phoneNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RateLimitUser = {
  email?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export enum ReferralStatus {
  Pending = 'PENDING',
  Unsatisfied = 'UNSATISFIED',
  Used = 'USED',
}

export type SearchProductsResponse = {
  __typename?: 'SearchProductsResponse';
  availableFilters?: Maybe<Array<ProductSearchFilter>>;
  products?: Maybe<Array<Product>>;
};

export type SearchUserResponse = {
  __typename?: 'SearchUserResponse';
  cognito?: Maybe<Array<Maybe<GqlCognitoUser>>>;
  dash?: Maybe<Array<Maybe<User>>>;
  phorest?: Maybe<Array<Maybe<PhorestUser>>>;
};

export type ServiceSchedule = {
  __typename?: 'ServiceSchedule';
  alternativeStaffMember?: Maybe<Scalars['Boolean']>;
  appointmentId?: Maybe<Scalars['String']>;
  branchServiceGroupId?: Maybe<Scalars['String']>;
  clientCourseItemId?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  machineId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  serviceGroupItemOptionId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['String']>;
  staffRequest?: Maybe<Scalars['Boolean']>;
  startTime?: Maybe<Scalars['String']>;
};

export type Staff = {
  __typename?: 'Staff';
  crmBranchId?: Maybe<Scalars['String']>;
  crmId: Scalars['String'];
  deleted?: Maybe<Scalars['Boolean']>;
  disqualifiedServices?: Maybe<Array<Maybe<Scalars['String']>>>;
  firstName: Scalars['String'];
  hideFromAppointmentScreen?: Maybe<Scalars['Boolean']>;
  hideFromOnlineBookings?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  staffCategoryId?: Maybe<Scalars['String']>;
  staffCategoryName?: Maybe<Scalars['String']>;
};

export type Tags = {
  __typename?: 'Tags';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TimeSlot = {
  category?: InputMaybe<Scalars['String']>;
  endTime: Scalars['String'];
  machineId?: InputMaybe<Scalars['String']>;
  roomId?: InputMaybe<Scalars['String']>;
  serviceId: Scalars['String'];
  staffId: Scalars['String'];
  startTime: Scalars['String'];
  userTreatmentItemId?: InputMaybe<Scalars['String']>;
};

export type Treatment = TreatmentInterface & {
  __typename?: 'Treatment';
  bookAsTreatmentId?: Maybe<Scalars['String']>;
  bookOnPurchase?: Maybe<Scalars['Boolean']>;
  buyNowPayLater?: Maybe<BuyNowPayLater>;
  category?: Maybe<Scalars['String']>;
  collectionSlug: Scalars['String'];
  comparePrice?: Maybe<Scalars['Float']>;
  crmId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  image?: Maybe<Image>;
  nuaSlug?: Maybe<Scalars['String']>;
  numberOfCycles?: Maybe<Scalars['Int']>;
  numberOfSessions?: Maybe<Scalars['Int']>;
  onSale?: Maybe<Scalars['Boolean']>;
  payInClinic?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Float']>;
  priceWithTax?: Maybe<Scalars['Float']>;
  priceWithoutTax?: Maybe<Scalars['Float']>;
  slug: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  treatmentGroup?: Maybe<Scalars['String']>;
  treatmentType?: Maybe<Scalars['String']>;
};

export type TreatmentCollection = TreatmentCollectionInterface & {
  __typename?: 'TreatmentCollection';
  catalogCover?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  groupSections?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  image?: Maybe<Image>;
  name: Scalars['String'];
  sections?: Maybe<Array<TreatmentCollectionSection>>;
  slug?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  treatmentGroup?: Maybe<Scalars['String']>;
};

export type TreatmentCollectionCard = TreatmentCollectionInterface & {
  __typename?: 'TreatmentCollectionCard';
  catalogCover?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  groupSections?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  image?: Maybe<Image>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  treatmentGroup?: Maybe<Scalars['String']>;
};

export type TreatmentCollectionInterface = {
  catalogCover?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  groupSections?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  image?: Maybe<Image>;
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  treatmentGroup?: Maybe<Scalars['String']>;
};

export type TreatmentCollectionSection = {
  __typename?: 'TreatmentCollectionSection';
  order?: Maybe<Scalars['Int']>;
  subsections?: Maybe<Array<TreatmentCollectionSubsection>>;
  title: Scalars['String'];
};

export type TreatmentCollectionSubsection = {
  __typename?: 'TreatmentCollectionSubsection';
  collapse: Scalars['Boolean'];
  collapseByAttribute?: Maybe<Scalars['String']>;
  hideTitle: Scalars['Boolean'];
  items?: Maybe<Array<TreatmentCollectionSubsectionItem>>;
  order?: Maybe<Scalars['Int']>;
  readOnly: Scalars['Boolean'];
  title?: Maybe<Scalars['String']>;
};

export type TreatmentCollectionSubsectionItem = TreatmentInterface & {
  __typename?: 'TreatmentCollectionSubsectionItem';
  bookAsTreatmentId?: Maybe<Scalars['String']>;
  bookOnPurchase?: Maybe<Scalars['Boolean']>;
  buyNowPayLater?: Maybe<BuyNowPayLater>;
  category?: Maybe<Scalars['String']>;
  collectionSlug: Scalars['String'];
  comparePrice?: Maybe<Scalars['Float']>;
  crmId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  image?: Maybe<Image>;
  numberOfCycles?: Maybe<Scalars['Int']>;
  numberOfSessions?: Maybe<Scalars['Int']>;
  onSale?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  payInClinic?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Float']>;
  priceWithTax?: Maybe<Scalars['Float']>;
  priceWithoutTax?: Maybe<Scalars['Float']>;
  slug: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type TreatmentInterface = {
  bookAsTreatmentId?: Maybe<Scalars['String']>;
  bookOnPurchase?: Maybe<Scalars['Boolean']>;
  buyNowPayLater?: Maybe<BuyNowPayLater>;
  category?: Maybe<Scalars['String']>;
  collectionSlug: Scalars['String'];
  comparePrice?: Maybe<Scalars['Float']>;
  crmId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  image?: Maybe<Image>;
  numberOfCycles?: Maybe<Scalars['Int']>;
  numberOfSessions?: Maybe<Scalars['Int']>;
  onSale?: Maybe<Scalars['Boolean']>;
  payInClinic?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Float']>;
  priceWithTax?: Maybe<Scalars['Float']>;
  priceWithoutTax?: Maybe<Scalars['Float']>;
  slug: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type TreatmentSchedule = {
  __typename?: 'TreatmentSchedule';
  appointmentId?: Maybe<Scalars['String']>;
  clientCourseItemId?: Maybe<Scalars['String']>;
  crmTreatmentId?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  machineId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['String']>;
  staffName?: Maybe<Scalars['String']>;
  staffRequest?: Maybe<Scalars['Boolean']>;
  startTime?: Maybe<Scalars['String']>;
};

export type TreatmentScheduleInput = {
  appointmentDate: Scalars['String'];
  appointmentId: Scalars['String'];
  category?: InputMaybe<Scalars['String']>;
  clientCourseItemId?: InputMaybe<Scalars['String']>;
  endTime: Scalars['String'];
  machineId?: InputMaybe<Scalars['String']>;
  roomId?: InputMaybe<Scalars['String']>;
  serviceId: Scalars['String'];
  staffId: Scalars['String'];
  staffRequest: Scalars['Boolean'];
  startTime: Scalars['String'];
  version: Scalars['Int'];
};

export type TreatmentUpdateSchedule = {
  __typename?: 'TreatmentUpdateSchedule';
  appointmentDate?: Maybe<Scalars['String']>;
  appointmentId: Scalars['String'];
  clientCourseItemId?: Maybe<Scalars['String']>;
  endTime: Scalars['String'];
  machineId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['String']>;
  staffRequest?: Maybe<Scalars['Boolean']>;
  startTime: Scalars['String'];
  version: Scalars['Int'];
};

export type TriggerTransactionResponse = {
  __typename?: 'TriggerTransactionResponse';
  transactionId: Scalars['String'];
};

export type UpdatePaymentIntentResponse = {
  __typename?: 'UpdatePaymentIntentResponse';
  intent: Scalars['String'];
  status: UpdatePaymentIntentStatus;
};

export enum UpdatePaymentIntentStatus {
  IntentUpdatedSuccessfully = 'INTENT_UPDATED_SUCCESSFULLY',
}

export type UpdatePaymentIntentValuesInput = {
  amount?: InputMaybe<Scalars['Float']>;
  metadataJson?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<Scalars['String']>;
  setupFutureUsage?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateUserInput = {
  addresses?: InputMaybe<Array<InputMaybe<AddressInput>>>;
  birthGender?: InputMaybe<Scalars['String']>;
  birthdate?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  identifyingGender?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastSignedIn?: InputMaybe<Scalars['String']>;
  notificationPreferences?: InputMaybe<Array<Scalars['String']>>;
  preferredClinic?: InputMaybe<Scalars['String']>;
  servicesOfInterest?: InputMaybe<Array<Scalars['String']>>;
  skinConcern?: InputMaybe<Scalars['String']>;
  skinType?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  addresses?: Maybe<Array<Maybe<Address>>>;
  birthGender?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['String'];
  identifyingGender?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  lastSignedIn?: Maybe<Scalars['String']>;
  mergeInfo?: Maybe<MergeInfo>;
  notificationPreferences?: Maybe<Array<Scalars['String']>>;
  phoneNumber?: Maybe<Scalars['String']>;
  preferredClinic?: Maybe<Scalars['String']>;
  referralCode?: Maybe<Scalars['String']>;
  referredBy?: Maybe<UserReferredBy>;
  servicesOfInterest?: Maybe<Array<Scalars['String']>>;
  signedUpDate: Scalars['String'];
  skinConcern?: Maybe<Scalars['String']>;
  skinType?: Maybe<Scalars['String']>;
  userMigrated?: Maybe<Scalars['Boolean']>;
};

export type UserAppointmentCancellationInput = {
  appointmentId: Scalars['String'];
  category?: InputMaybe<Scalars['String']>;
  clinicId: Scalars['String'];
  dateTime?: InputMaybe<Scalars['String']>;
  treatmentId?: InputMaybe<Scalars['String']>;
};

export type UserBookingInput = {
  clinicId: Scalars['String'];
  slots: Array<TimeSlot>;
};

export type UserBookingUpdateInput = {
  newBooking: BookingUpdateInput;
  oldBooking: BookingUpdateInput;
};

export type UserBookingsHistoryResponse = {
  __typename?: 'UserBookingsHistoryResponse';
  bookingsHistory?: Maybe<Array<BookingHistory>>;
  page?: Maybe<Page>;
};

export type UserFullName = {
  __typename?: 'UserFullName';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type UserOrders = {
  __typename?: 'UserOrders';
  lastOrderId?: Maybe<Scalars['String']>;
  orders?: Maybe<Array<Order>>;
  user?: Maybe<User>;
};

export type UserRateLimitInfo = {
  __typename?: 'UserRateLimitInfo';
  key: Scalars['String'];
  ttl?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

export type UserReferredBy = {
  __typename?: 'UserReferredBy';
  crmId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  firstTransactionId?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  referralCode: Scalars['String'];
  status: ReferralStatus;
};

export type UserTreatment = {
  __typename?: 'UserTreatment';
  category?: Maybe<Scalars['String']>;
  crmTreatmentId: Scalars['String'];
  crmUserTreatmentId: Scalars['String'];
  expiryDate?: Maybe<Scalars['String']>;
  grossPrice?: Maybe<Scalars['Float']>;
  netPrice?: Maybe<Scalars['Float']>;
  purchaseDate: Scalars['String'];
  purchasingBranchId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  treatmentGroup?: Maybe<Scalars['String']>;
  treatmentId: Scalars['String'];
  userTreatmentItems: Array<UserTreatmentItem>;
};

export type UserTreatmentItem = {
  __typename?: 'UserTreatmentItem';
  crmSingleTreatmentId: Scalars['String'];
  crmTreatmentItemId: Scalars['String'];
  crmUserTreatmentId: Scalars['String'];
  crmUserTreatmentItemId: Scalars['String'];
  duration?: Maybe<Scalars['Int']>;
  grossPrice?: Maybe<Scalars['Float']>;
  initialUnits?: Maybe<Scalars['Int']>;
  netPrice?: Maybe<Scalars['Float']>;
  remainingUnits?: Maybe<Scalars['Int']>;
  singleTreatmentId: Scalars['String'];
};

export type UserTreatmentsResponse = {
  __typename?: 'UserTreatmentsResponse';
  page?: Maybe<Page>;
  treatments?: Maybe<Array<UserTreatment>>;
};

export type Voucher = {
  __typename?: 'Voucher';
  brandName: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Float'];
  slug: Scalars['String'];
  status: ProductStatus;
};

export type GetClinicsByTreatmentResponse = {
  __typename?: 'getClinicsByTreatmentResponse';
  clinics?: Maybe<Array<Maybe<ClinicIdName>>>;
};

export type RateLimitUserMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
}>;

export type RateLimitUserMutation = { __typename?: 'Mutation'; rateLimitUser?: boolean | null };

export type UpdateUserAddressInfoMutationVariables = Exact<{
  id: Scalars['String'];
  addresses?: InputMaybe<Array<InputMaybe<AddressInput>> | InputMaybe<AddressInput>>;
}>;

export type UpdateUserAddressInfoMutation = {
  __typename?: 'Mutation';
  updateUser?: {
    __typename?: 'User';
    id: string;
    addresses?: Array<{
      __typename?: 'Address';
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber?: string | null;
      city?: string | null;
      country?: string | null;
      county?: string | null;
      state?: string | null;
      line1?: string | null;
      line2?: string | null;
      postcode?: string | null;
      defaultShipping?: boolean | null;
      defaultBilling?: boolean | null;
    } | null> | null;
  } | null;
};

export type UpdateUserMarketingPreferencesMutationVariables = Exact<{
  id: Scalars['String'];
  marketingPreferences?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type UpdateUserMarketingPreferencesMutation = {
  __typename?: 'Mutation';
  updateUser?: {
    __typename?: 'User';
    id: string;
    notificationPreferences?: Array<string> | null;
  } | null;
};

export type UpdateUserPreferredClinicMutationVariables = Exact<{
  id: Scalars['String'];
  preferredClinic: Scalars['String'];
}>;

export type UpdateUserPreferredClinicMutation = {
  __typename?: 'Mutation';
  updateUser?: { __typename?: 'User'; id: string; preferredClinic?: string | null } | null;
};

export type GetClinicsQueryVariables = Exact<{ [key: string]: never }>;

export type GetClinicsQuery = {
  __typename?: 'Query';
  getClinics?: {
    __typename?: 'ClinicsData';
    clinics?: Array<{
      __typename?: 'Clinic';
      address1: string;
      address2?: string | null;
      city?: string | null;
      country?: string | null;
      crmId: string;
      id: string;
      latitude?: string | null;
      longitude?: string | null;
      name: string;
      state?: string | null;
      timeZone?: string | null;
      zip?: string | null;
    } | null> | null;
  } | null;
};

export type GetStaffByIdsQueryVariables = Exact<{
  crmIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetStaffByIdsQuery = {
  __typename?: 'Query';
  getStaffByIds?: Array<{
    __typename?: 'Staff';
    crmBranchId?: string | null;
    crmId: string;
    deleted?: boolean | null;
    disqualifiedServices?: Array<string | null> | null;
    firstName: string;
    hideFromAppointmentScreen?: boolean | null;
    hideFromOnlineBookings?: boolean | null;
    id: string;
    lastName?: string | null;
    staffCategoryId?: string | null;
    staffCategoryName?: string | null;
  }> | null;
};

export type GetUserQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  getUser?: {
    __typename?: 'User';
    birthdate?: string | null;
    birthGender?: string | null;
    crmId?: string | null;
    email?: string | null;
    firstName: string;
    identifyingGender?: string | null;
    lastName: string;
    notificationPreferences?: Array<string> | null;
    phoneNumber?: string | null;
    preferredClinic?: string | null;
    signedUpDate: string;
    skinConcern?: string | null;
    skinType?: string | null;
    id: string;
    referralCode?: string | null;
    addresses?: Array<{
      __typename?: 'Address';
      city?: string | null;
      country?: string | null;
      county?: string | null;
      state?: string | null;
      defaultBilling?: boolean | null;
      defaultShipping?: boolean | null;
      firstName?: string | null;
      lastName?: string | null;
      line1?: string | null;
      line2?: string | null;
      phoneNumber?: string | null;
      postcode?: string | null;
    } | null> | null;
    referredBy?: {
      __typename?: 'UserReferredBy';
      crmId?: string | null;
      referralCode: string;
      status: ReferralStatus;
      firstName: string;
      lastName: string;
    } | null;
  } | null;
};

export type GetUserLoyaltyPointsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserLoyaltyPointsQuery = {
  __typename?: 'Query';
  getUserLoyaltyPoints?: number | null;
};

export type CreateUserMutationVariables = Exact<{
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  password: Scalars['String'];
}>;

export type CreateUserMutation = { __typename?: 'Mutation'; createUser?: string | null };

export type ForceResetUserPasswordMutationVariables = Exact<{
  sendCode: Scalars['Boolean'];
}>;

export type ForceResetUserPasswordMutation = {
  __typename?: 'Mutation';
  forceResetUserPassword?: boolean | null;
};

export type SetUserPasswordMutationVariables = Exact<{
  password: Scalars['String'];
}>;

export type SetUserPasswordMutation = { __typename?: 'Mutation'; setUserPassword?: boolean | null };

export type CancelUserAppointmentsMutationVariables = Exact<{
  appointments: Array<UserAppointmentCancellationInput> | UserAppointmentCancellationInput;
}>;

export type CancelUserAppointmentsMutation = {
  __typename?: 'Mutation';
  cancelUserAppointments?: Array<{
    __typename?: 'CancelUserAppointmentsResponseGql';
    appointmentId: string;
    clinicId: string;
    error?: string | null;
    status: CancelAppointmentStatusGql;
  } | null> | null;
};

export type CreateUserBookingMutationVariables = Exact<{
  clinicId: Scalars['String'];
  slots: Array<TimeSlot> | TimeSlot;
}>;

export type CreateUserBookingMutation = {
  __typename?: 'Mutation';
  createUserBooking?: {
    __typename?: 'CreateUserBookingResponse';
    bookingId?: string | null;
    clientAppointmentSchedules?: Array<{
      __typename?: 'ClientAppointmentSchedule';
      serviceSchedules?: Array<{
        __typename?: 'ServiceSchedule';
        appointmentId?: string | null;
        clientCourseItemId?: string | null;
        endTime?: string | null;
        serviceId?: string | null;
        staffId?: string | null;
        startTime?: string | null;
        roomId?: string | null;
        machineId?: string | null;
      } | null> | null;
    }> | null;
  } | null;
};

export type UpdateUserBookingMutationVariables = Exact<{
  newBooking: BookingUpdateInput;
  oldBooking: BookingUpdateInput;
}>;

export type UpdateUserBookingMutation = {
  __typename?: 'Mutation';
  updateUserBooking?: Array<{
    __typename?: 'TreatmentUpdateSchedule';
    appointmentDate?: string | null;
    appointmentId: string;
    clientCourseItemId?: string | null;
    endTime: string;
    serviceId?: string | null;
    staffId?: string | null;
    staffRequest?: boolean | null;
    startTime: string;
    version: number;
  }> | null;
};

export type GetAppointmentTimeslotsQueryVariables = Exact<{
  businessId: Scalars['String'];
  clinicId: Scalars['String'];
  fromDateTime: Scalars['String'];
  toDateTime: Scalars['String'];
  crmTreatmentIds: Array<Scalars['String']> | Scalars['String'];
  staffId?: InputMaybe<Scalars['String']>;
  returnAlternativeStaff?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetAppointmentTimeslotsQuery = {
  __typename?: 'Query';
  getAppointmentTimeslots: {
    __typename?: 'GetAppointmentTimeslotsResponse';
    availableStaff?: Array<{ __typename?: 'AvailableStaff'; id: string; name: string }> | null;
    availableTimeslots?: Array<{
      __typename?: 'AvailableTimeslots';
      startTime?: string | null;
      treatmentSchedules?: Array<{
        __typename?: 'TreatmentSchedule';
        appointmentId?: string | null;
        clientCourseItemId?: string | null;
        crmTreatmentId?: string | null;
        endTime?: string | null;
        staffName?: string | null;
        staffId?: string | null;
        staffRequest?: boolean | null;
        startTime?: string | null;
        roomId?: string | null;
        machineId?: string | null;
      }> | null;
    }> | null;
  };
};

export type GetAppointmentsQueryVariables = Exact<{
  clinicId: Scalars['String'];
  fetchCancelled: Scalars['Boolean'];
  fromDate: Scalars['String'];
  toDate: Scalars['String'];
  page: PaginationInput;
}>;

export type GetAppointmentsQuery = {
  __typename?: 'Query';
  getAppointments?: {
    __typename?: 'GetAppointmentsResponse';
    appointments?: Array<{
      __typename?: 'Appointment';
      appointmentDate?: string | null;
      appointmentId?: string | null;
      endTime?: string | null;
      serviceId?: string | null;
      staffId?: string | null;
      staffRequest?: boolean | null;
      version?: number | null;
      startTime?: string | null;
      roomId?: string | null;
      machineId?: string | null;
    }> | null;
    page?: {
      __typename?: 'Page';
      totalPages?: number | null;
      totalElements?: number | null;
      size?: number | null;
      number?: number | null;
    } | null;
  } | null;
};

export type GetClinicsByTreatmentsQueryVariables = Exact<{
  treatmentIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetClinicsByTreatmentsQuery = {
  __typename?: 'Query';
  getClinicsByTreatments?: {
    __typename?: 'getClinicsByTreatmentResponse';
    clinics?: Array<{
      __typename?: 'ClinicIdName';
      crmId?: string | null;
      id: string;
      name: string;
    } | null> | null;
  } | null;
};

export type GetUserBookingsHistoryQueryVariables = Exact<{
  page?: InputMaybe<PaginationInput>;
}>;

export type GetUserBookingsHistoryQuery = {
  __typename?: 'Query';
  getUserBookingsHistory?: {
    __typename?: 'UserBookingsHistoryResponse';
    bookingsHistory?: Array<{
      __typename?: 'BookingHistory';
      date?: string | null;
      branchId?: string | null;
      bookingId?: string | null;
      bookings?: Array<{
        __typename?: 'BookingHistoryItem';
        appointmentId?: string | null;
        cancelled?: boolean | null;
        crmUserTreatmentItemId?: string | null;
        duration?: number | null;
        serviceId?: string | null;
        treatmentCategory?: string | null;
        time?: string | null;
        treatmentTitle?: string | null;
        treatmentId?: string | null;
      }> | null;
    }> | null;
    page?: {
      __typename?: 'Page';
      number?: number | null;
      size?: number | null;
      totalElements?: number | null;
      totalPages?: number | null;
    } | null;
  } | null;
};

export type GetUserTreatmentsQueryVariables = Exact<{
  page?: InputMaybe<PaginationInput>;
  includeArchived?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetUserTreatmentsQuery = {
  __typename?: 'Query';
  getUserTreatments?: {
    __typename?: 'UserTreatmentsResponse';
    page?: {
      __typename?: 'Page';
      number?: number | null;
      size?: number | null;
      totalElements?: number | null;
      totalPages?: number | null;
    } | null;
    treatments?: Array<{
      __typename?: 'UserTreatment';
      title?: string | null;
      purchaseDate: string;
      crmTreatmentId: string;
      crmUserTreatmentId: string;
      category?: string | null;
      treatmentId: string;
      userTreatmentItems: Array<{
        __typename?: 'UserTreatmentItem';
        remainingUnits?: number | null;
        initialUnits?: number | null;
        duration?: number | null;
        crmTreatmentItemId: string;
        crmUserTreatmentId: string;
        crmUserTreatmentItemId: string;
        crmSingleTreatmentId: string;
        singleTreatmentId: string;
      }>;
    }> | null;
  } | null;
};

export type AddCartItemMutationVariables = Exact<{
  cartId: Scalars['String'];
  itemId: Scalars['String'];
  quantity: Scalars['Int'];
  type: CartItemType;
}>;

export type AddCartItemMutation = {
  __typename?: 'Mutation';
  addCartItem?: {
    __typename?: 'Cart';
    createdAt: string;
    id: string;
    deliveryCost?: number | null;
    updatedAt?: string | null;
    totalCost?: number | null;
    subtotalCost?: number | null;
    items?: Array<{
      __typename?: 'CartItem';
      type: string;
      quantity: number;
      itemId: string;
      totalCost: number;
      itemCost: number;
      addedAt: string;
      updatedAt: string;
    }> | null;
  } | null;
};

export type CreateOrderMutationVariables = Exact<{
  payments: Array<PurchasePayment> | PurchasePayment;
  channelSource: ChannelSourceInput;
  billingAddress?: InputMaybe<AddressInput>;
  shippingAddress?: InputMaybe<AddressInput>;
}>;

export type CreateOrderMutation = {
  __typename?: 'Mutation';
  createOrder?: { __typename?: 'CreateOrderResponse'; orderId: string } | null;
};

export type CreatePurchaseTransactionSyncMutationVariables = Exact<{
  payments: Array<PurchasePayment> | PurchasePayment;
  channelSource: ChannelSourceInput;
  billingAddress?: InputMaybe<AddressInput>;
  shippingAddress?: InputMaybe<AddressInput>;
}>;

export type CreatePurchaseTransactionSyncMutation = {
  __typename?: 'Mutation';
  createPurchaseTransactionSync?: {
    __typename?: 'CreatePurchaseTransactionResponse';
    id: string;
    status?: PaymentTransactionStatus | null;
  } | null;
};

export type EmptyCartMutationVariables = Exact<{
  cartId: Scalars['String'];
}>;

export type EmptyCartMutation = {
  __typename?: 'Mutation';
  emptyCart?: {
    __typename?: 'Cart';
    id: string;
    createdAt: string;
    deliveryCost?: number | null;
    updatedAt?: string | null;
    totalCost?: number | null;
    subtotalCost?: number | null;
    items?: Array<{
      __typename?: 'CartItem';
      type: string;
      quantity: number;
      itemId: string;
      itemCost: number;
      totalCost: number;
      addedAt: string;
      updatedAt: string;
    }> | null;
  } | null;
};

export type RefreshCartAfterLoginMutationVariables = Exact<{
  cartId: Scalars['String'];
}>;

export type RefreshCartAfterLoginMutation = {
  __typename?: 'Mutation';
  refreshCartAfterLogin?: {
    __typename?: 'Cart';
    id: string;
    deliveryCost?: number | null;
    createdAt: string;
    updatedAt?: string | null;
    totalCost?: number | null;
    subtotalCost?: number | null;
    items?: Array<{
      __typename?: 'CartItem';
      type: string;
      quantity: number;
      itemId: string;
      itemCost: number;
      totalCost: number;
      addedAt: string;
      updatedAt: string;
    }> | null;
  } | null;
};

export type TriggerTransactionMutationVariables = Exact<{
  orderId: Scalars['String'];
}>;

export type TriggerTransactionMutation = {
  __typename?: 'Mutation';
  triggerTransaction?: { __typename?: 'TriggerTransactionResponse'; transactionId: string } | null;
};

export type GetCartQueryVariables = Exact<{
  id: Scalars['String'];
  forceUpdate?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetCartQuery = {
  __typename?: 'Query';
  getCart?: {
    __typename?: 'Cart';
    id: string;
    deliveryCost?: number | null;
    createdAt: string;
    updatedAt?: string | null;
    totalCost?: number | null;
    subtotalCost?: number | null;
    items?: Array<{
      __typename?: 'CartItem';
      type: string;
      quantity: number;
      itemId: string;
      itemCost: number;
      totalCost: number;
      addedAt: string;
      updatedAt: string;
      priceChanged?: boolean | null;
    }> | null;
  } | null;
};

export type GetCartEnrichedQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetCartEnrichedQuery = {
  __typename?: 'Query';
  getCartEnriched?: {
    __typename?: 'EnrichedCartGql';
    createdAt: string;
    deliveryCost: number;
    grossSubtotalCost: number;
    grossTotalCost: number;
    id: string;
    netSubtotalCost: number;
    netTotalCost: number;
    subtotalCost: number;
    totalCost: number;
    totalDiscount: number;
    updatedAt?: string | null;
    items?: Array<{
      __typename?: 'EnrichedCartItemGql';
      addedAt: string;
      grossItemCost: number;
      id: string;
      itemCost: number;
      itemDiscount: number;
      itemId: string;
      netItemCost: number;
      priceChanged?: boolean | null;
      quantity: number;
      totalCost: number;
      type: string;
      updatedAt: string;
    }> | null;
  } | null;
};

export type GetOrderByIdQueryVariables = Exact<{
  orderId: Scalars['String'];
}>;

export type GetOrderByIdQuery = {
  __typename?: 'Query';
  getOrderById?: {
    __typename?: 'Order';
    userId: string;
    mergedFromUserId?: string | null;
    total: number;
    taxes: number;
    paymentStatus: PaymentStatus;
    paymentStatusLastChange?: string | null;
    cardPayment?: number | null;
    loyaltyPointsPayment: number;
    orderId: string;
    publicId?: string | null;
    fulfillmentStatus: FulfillmentStatus;
    deliveryCost: number;
    date: string;
    isDeleted?: boolean | null;
    items: Array<{
      __typename?: 'OrderItem';
      itemCost: number;
      itemId: string;
      name: string;
      quantity: number;
      totalCost: number;
      type: string;
      taxRate?: number | null;
      grossItemCost?: number | null;
      netItemCost?: number | null;
      itemDiscount?: number | null;
      totalDiscount?: number | null;
    }>;
    billingDetails?: {
      __typename?: 'Address';
      postcode?: string | null;
      phoneNumber?: string | null;
      line2?: string | null;
      line1?: string | null;
      lastName?: string | null;
      firstName?: string | null;
      defaultShipping?: boolean | null;
      defaultBilling?: boolean | null;
      country?: string | null;
      county?: string | null;
      state?: string | null;
      city?: string | null;
    } | null;
    voucherPayment?: { __typename?: 'OrderVoucher'; amount: number; serialNumber: string } | null;
    shippingDetails?: {
      __typename?: 'Address';
      postcode?: string | null;
      phoneNumber?: string | null;
      line2?: string | null;
      line1?: string | null;
      lastName?: string | null;
      firstName?: string | null;
      defaultShipping?: boolean | null;
      defaultBilling?: boolean | null;
      country?: string | null;
      county?: string | null;
      state?: string | null;
      city?: string | null;
    } | null;
    vouchers?: Array<{
      __typename?: 'CreatedVoucher';
      expiryDate: string;
      remainingBalance: number;
      serialNumber: string;
    } | null> | null;
  } | null;
};

export type GetOrderPaymentStatusQueryVariables = Exact<{
  orderId: Scalars['String'];
}>;

export type GetOrderPaymentStatusQuery = {
  __typename?: 'Query';
  getOrderById?: { __typename?: 'Order'; paymentStatus: PaymentStatus } | null;
};

export type GetUserSavedPaymentMethodsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserSavedPaymentMethodsQuery = {
  __typename?: 'Query';
  getUserSavedPaymentMethods?: Array<{
    __typename?: 'PaymentMethod';
    id: string;
    card?: {
      __typename?: 'Card';
      brand: string;
      last4: string;
      exp_year: number;
      exp_month: number;
    } | null;
  }> | null;
};

export type GetVoucherBalanceBySerialNumberQueryVariables = Exact<{
  serialNumber: Scalars['String'];
}>;

export type GetVoucherBalanceBySerialNumberQuery = {
  __typename?: 'Query';
  getVoucherBalanceBySerialNumber?: number | null;
};

export type DeletePaymentMethodMutationVariables = Exact<{
  paymentMethodId: Scalars['String'];
}>;

export type DeletePaymentMethodMutation = {
  __typename?: 'Mutation';
  deletePaymentMethod?: {
    __typename?: 'DeletePaymentMethodResponse';
    error?: string | null;
    status: DeletePaymentMethodStatus;
  } | null;
};

export type SendVouchersMutationVariables = Exact<{
  orderId: Scalars['String'];
}>;

export type SendVouchersMutation = { __typename?: 'Mutation'; sendVouchers?: boolean | null };

export type UpdateUserPersonalInfoMutationVariables = Exact<{
  id: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  birthGender: Scalars['String'];
  identifyingGender: Scalars['String'];
  birthdate: Scalars['String'];
  skinConcern: Scalars['String'];
  skinType: Scalars['String'];
  preferredClinic: Scalars['String'];
}>;

export type UpdateUserPersonalInfoMutation = {
  __typename?: 'Mutation';
  updateUser?: {
    __typename?: 'User';
    id: string;
    crmId?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    firstName: string;
    lastName: string;
    birthGender?: string | null;
    identifyingGender?: string | null;
    birthdate?: string | null;
    skinConcern?: string | null;
    skinType?: string | null;
    preferredClinic?: string | null;
    signedUpDate: string;
  } | null;
};

export type GetLookupValuesQueryVariables = Exact<{ [key: string]: never }>;

export type GetLookupValuesQuery = {
  __typename?: 'Query';
  getLookupValues?: Array<{
    __typename?: 'LookupValue';
    name: string;
    values: Array<string>;
  }> | null;
};

export type GetOrdersQueryVariables = Exact<{
  lastOrderId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetOrdersQuery = {
  __typename?: 'Query';
  getOrders?: {
    __typename?: 'UserOrders';
    lastOrderId?: string | null;
    orders?: Array<{
      __typename?: 'Order';
      userId: string;
      mergedFromUserId?: string | null;
      total: number;
      taxes: number;
      paymentStatus: PaymentStatus;
      paymentStatusLastChange?: string | null;
      cardPayment?: number | null;
      loyaltyPointsPayment: number;
      orderId: string;
      publicId?: string | null;
      fulfillmentStatus: FulfillmentStatus;
      deliveryCost: number;
      date: string;
      items: Array<{
        __typename?: 'OrderItem';
        itemCost: number;
        itemId: string;
        name: string;
        quantity: number;
        totalCost: number;
        type: string;
        taxRate?: number | null;
        grossItemCost?: number | null;
        netItemCost?: number | null;
        itemDiscount?: number | null;
        totalDiscount?: number | null;
      }>;
      billingDetails?: {
        __typename?: 'Address';
        postcode?: string | null;
        phoneNumber?: string | null;
        line2?: string | null;
        line1?: string | null;
        lastName?: string | null;
        firstName?: string | null;
        defaultShipping?: boolean | null;
        defaultBilling?: boolean | null;
        country?: string | null;
        county?: string | null;
        state?: string | null;
        city?: string | null;
      } | null;
      voucherPayment?: { __typename?: 'OrderVoucher'; amount: number; serialNumber: string } | null;
      shippingDetails?: {
        __typename?: 'Address';
        postcode?: string | null;
        phoneNumber?: string | null;
        line2?: string | null;
        line1?: string | null;
        lastName?: string | null;
        firstName?: string | null;
        defaultShipping?: boolean | null;
        defaultBilling?: boolean | null;
        country?: string | null;
        county?: string | null;
        state?: string | null;
        city?: string | null;
      } | null;
      vouchers?: Array<{
        __typename?: 'CreatedVoucher';
        expiryDate: string;
        remainingBalance: number;
        serialNumber: string;
      } | null> | null;
    }> | null;
  } | null;
};

export type GetProductBySlugQueryVariables = Exact<{
  query: Scalars['String'];
}>;

export type GetProductBySlugQuery = {
  __typename?: 'Query';
  getProductBySlug?: {
    __typename?: 'Product';
    id?: string | null;
    crmId?: string | null;
    name?: string | null;
    sizeUnit?: string | null;
    sizeValue?: number | null;
    comparePrice?: number | null;
    price?: number | null;
    priceWithTax?: number | null;
    priceWithoutTax?: number | null;
    description?: string | null;
    outOfStock?: boolean | null;
    status?: ProductStatus | null;
    images?: Array<{
      __typename?: 'Image';
      original?: string | null;
      thumbnail?: string | null;
    }> | null;
  } | null;
};

export type GetProductGroupsQueryVariables = Exact<{
  component: Scalars['String'];
}>;

export type GetProductGroupsQuery = {
  __typename?: 'Query';
  getProductGroups?: Array<{
    __typename?: 'ProductGroup';
    id: string;
    name: string;
    image: string;
    description?: string | null;
    order: number;
    component: string;
    searchFilter: {
      __typename?: 'ProductSearch';
      name?: string | null;
      brandName?: string | null;
      productType?: Array<string> | null;
      skinType?: Array<string> | null;
      skinConcern?: Array<string> | null;
      productRange?: string | null;
    };
  }> | null;
};

export type GetProductsQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetProductsQuery = {
  __typename?: 'Query';
  getProducts?: Array<{
    __typename?: 'Product';
    id?: string | null;
    name?: string | null;
    brandName?: string | null;
    crmId?: string | null;
    price?: number | null;
    priceWithTax?: number | null;
    priceWithoutTax?: number | null;
    slug?: string | null;
    outOfStock?: boolean | null;
    images?: Array<{
      __typename?: 'Image';
      original?: string | null;
      thumbnail?: string | null;
    }> | null;
  }> | null;
};

export type GetVouchersQueryVariables = Exact<{ [key: string]: never }>;

export type GetVouchersQuery = {
  __typename?: 'Query';
  getVouchers?: Array<{
    __typename?: 'Voucher';
    id: string;
    name: string;
    slug: string;
    status: ProductStatus;
    price: number;
    brandName: string;
  } | null> | null;
};

export type SearchProductsQueryVariables = Exact<{
  filters: ProductSearchInput;
}>;

export type SearchProductsQuery = {
  __typename?: 'Query';
  searchProducts?: {
    __typename?: 'SearchProductsResponse';
    products?: Array<{
      __typename?: 'Product';
      id?: string | null;
      crmId?: string | null;
      name?: string | null;
      price?: number | null;
      priceWithTax?: number | null;
      priceWithoutTax?: number | null;
      slug?: string | null;
      comparePrice?: number | null;
      brandName?: string | null;
      status?: ProductStatus | null;
      outOfStock?: boolean | null;
      images?: Array<{
        __typename?: 'Image';
        thumbnail?: string | null;
        original?: string | null;
      }> | null;
    }> | null;
    availableFilters?: Array<{
      __typename?: 'ProductSearchFilter';
      name: string;
      values: Array<string>;
    }> | null;
  } | null;
};

export type GetUserByReferralCodeQueryVariables = Exact<{
  referralCode: Scalars['String'];
}>;

export type GetUserByReferralCodeQuery = {
  __typename?: 'Query';
  getUserByReferralCode?: {
    __typename?: 'UserFullName';
    firstName: string;
    lastName: string;
  } | null;
};

export type CreatePaymentIntentMutationVariables = Exact<{
  intentType: PaymentIntentType;
  values: CreatePaymentIntentValuesInput;
}>;

export type CreatePaymentIntentMutation = {
  __typename?: 'Mutation';
  createPaymentIntent?: {
    __typename?: 'CreatePaymentIntentResponse';
    status: CreatePaymentIntentStatus;
    intent: string;
  } | null;
};

export type DeletePaymentIntentMutationVariables = Exact<{
  intentId: Scalars['String'];
  intentType: PaymentIntentType;
}>;

export type DeletePaymentIntentMutation = {
  __typename?: 'Mutation';
  deletePaymentIntent?: {
    __typename?: 'DeletePaymentIntentResponse';
    status: DeletePaymentIntentStatus;
    intent: string;
  } | null;
};

export type GetPaymentIntentQueryVariables = Exact<{
  intentId: Scalars['String'];
  intentType: PaymentIntentType;
}>;

export type GetPaymentIntentQuery = { __typename?: 'Query'; getPaymentIntent?: string | null };

export type UpdatePaymentIntentMutationVariables = Exact<{
  intentId: Scalars['String'];
  intentType: PaymentIntentType;
  values: UpdatePaymentIntentValuesInput;
}>;

export type UpdatePaymentIntentMutation = {
  __typename?: 'Mutation';
  updatePaymentIntent?: {
    __typename?: 'UpdatePaymentIntentResponse';
    status: UpdatePaymentIntentStatus;
    intent: string;
  } | null;
};

export type GetPaymentServiceUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetPaymentServiceUserQuery = {
  __typename?: 'Query';
  getPaymentServiceUser: { __typename?: 'PaymentServiceUser'; id?: string | null };
};

export type GetTreatmentQueryVariables = Exact<{
  input: GetTreatmentInput;
}>;

export type GetTreatmentQuery = {
  __typename?: 'Query';
  getTreatment?: {
    __typename?: 'Treatment';
    bookOnPurchase?: boolean | null;
    category?: string | null;
    collectionSlug: string;
    comparePrice?: number | null;
    crmId?: string | null;
    description?: string | null;
    id: string;
    numberOfSessions?: number | null;
    onSale?: boolean | null;
    payInClinic?: boolean | null;
    price?: number | null;
    priceWithTax?: number | null;
    priceWithoutTax?: number | null;
    slug: string;
    nuaSlug?: string | null;
    title?: string | null;
    duration?: number | null;
    bookAsTreatmentId?: string | null;
    treatmentGroup?: string | null;
    treatmentType?: string | null;
    buyNowPayLater?: {
      __typename?: 'BuyNowPayLater';
      enabled?: boolean | null;
      installments?: number | null;
      surchargePercentage?: number | null;
    } | null;
    image?: { __typename?: 'Image'; original?: string | null; thumbnail?: string | null } | null;
  } | null;
};

export type GetTreatmentCollectionBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetTreatmentCollectionBySlugQuery = {
  __typename?: 'Query';
  getTreatmentCollectionBySlug?: {
    __typename?: 'TreatmentCollection';
    description?: string | null;
    id: string;
    name: string;
    groupSections?: boolean | null;
    catalogCover?: string | null;
    slug?: string | null;
    title: string;
    treatmentGroup?: string | null;
    image?: { __typename?: 'Image'; original?: string | null; thumbnail?: string | null } | null;
    sections?: Array<{
      __typename?: 'TreatmentCollectionSection';
      order?: number | null;
      title: string;
      subsections?: Array<{
        __typename?: 'TreatmentCollectionSubsection';
        title?: string | null;
        hideTitle: boolean;
        collapse: boolean;
        collapseByAttribute?: string | null;
        order?: number | null;
        readOnly: boolean;
        items?: Array<{
          __typename?: 'TreatmentCollectionSubsectionItem';
          bookOnPurchase?: boolean | null;
          bookAsTreatmentId?: string | null;
          category?: string | null;
          collectionSlug: string;
          comparePrice?: number | null;
          crmId?: string | null;
          description?: string | null;
          id: string;
          numberOfSessions?: number | null;
          numberOfCycles?: number | null;
          onSale?: boolean | null;
          order?: number | null;
          payInClinic?: boolean | null;
          price?: number | null;
          priceWithTax?: number | null;
          priceWithoutTax?: number | null;
          slug: string;
          title?: string | null;
          buyNowPayLater?: {
            __typename?: 'BuyNowPayLater';
            enabled?: boolean | null;
            installments?: number | null;
            surchargePercentage?: number | null;
          } | null;
          image?: {
            __typename?: 'Image';
            original?: string | null;
            thumbnail?: string | null;
          } | null;
        }> | null;
      }> | null;
    }> | null;
  } | null;
};

export type GetTreatmentCollectionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTreatmentCollectionsQuery = {
  __typename?: 'Query';
  getTreatmentCollections?: Array<{
    __typename?: 'TreatmentCollectionCard';
    description?: string | null;
    id: string;
    name: string;
    order?: number | null;
    slug?: string | null;
    title: string;
    treatmentGroup?: string | null;
    groupSections?: boolean | null;
    catalogCover?: string | null;
    image?: { __typename?: 'Image'; original?: string | null; thumbnail?: string | null } | null;
  }> | null;
};

export type GetTreatmentsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  crmIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  courseCrmIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetTreatmentsQuery = {
  __typename?: 'Query';
  getTreatments?: Array<{
    __typename?: 'Treatment';
    bookOnPurchase?: boolean | null;
    category?: string | null;
    comparePrice?: number | null;
    collectionSlug: string;
    crmId?: string | null;
    description?: string | null;
    id: string;
    numberOfSessions?: number | null;
    onSale?: boolean | null;
    payInClinic?: boolean | null;
    price?: number | null;
    priceWithTax?: number | null;
    priceWithoutTax?: number | null;
    slug: string;
    nuaSlug?: string | null;
    title?: string | null;
    duration?: number | null;
    bookAsTreatmentId?: string | null;
    treatmentGroup?: string | null;
    treatmentType?: string | null;
    buyNowPayLater?: {
      __typename?: 'BuyNowPayLater';
      enabled?: boolean | null;
      installments?: number | null;
      surchargePercentage?: number | null;
    } | null;
    image?: { __typename?: 'Image'; original?: string | null; thumbnail?: string | null } | null;
  }> | null;
};
