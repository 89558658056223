import { QueryClient } from 'react-query';
import { log } from '@core/logging/logging';
import { initialiseCartState, setCartState } from '@features/cart-and-checkout/store/cart.store';
import { enrichItems } from '@utils/enrichment/item-enrichment';
import {
  Cart,
  CartItem,
  CartItemType,
  EnrichedCartGql,
  EnrichedCartItemGql,
  OrderItem,
} from '@/data/graphql/types';

export const initialiseCartStore = async (
  cart: Cart | EnrichedCartGql,
  queryClient: QueryClient
) => {
  const hasItems = cart?.items && cart?.items?.length > 0;

  if (hasItems) {
    try {
      await enrichItems(cart?.items!, queryClient);
    } catch (e) {
      log({
        level: 'error',
        category: 'cart',
        message: 'cart_enrich_error',
        messageContext: {
          error: e,
        },
      });
    }
  }

  setCartState(cart);
  initialiseCartState();
};

export const getItemsHaveProducts = (
  items: CartItem[] | OrderItem[] | EnrichedCartItemGql[] | null = []
) => {
  return !!items?.some((item) => item.type === CartItemType.Product);
};
