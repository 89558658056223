import { getFormattedPrice } from '@utils/functions/price';

export const REFER_A_FRIEND_REWARD_AMOUNT = +process.env.NEXT_PUBLIC_REFER_A_FRIEND_REWARD_AMOUNT!;
export const REFER_A_FRIEND_REWARD_THRESHOLD =
  +process.env.NEXT_PUBLIC_REFER_A_FRIEND_REWARD_THRESHOLD!;

export const { integerPrice: REFER_A_FRIEND_REWARD_PRICE } = getFormattedPrice(
  REFER_A_FRIEND_REWARD_AMOUNT
);

export const { integerPrice: REFER_A_FRIEND_REWARD_THRESHOLD_PRICE } = getFormattedPrice(
  REFER_A_FRIEND_REWARD_THRESHOLD
);
