import React, { PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'next-i18next';

import { Button, Text, cx, Icon, TextProps } from '@therapie-ecommerce-ui/ui';

import { LinkText, LinkButton } from '@components/link/Link';
import { SITE_URLS } from '@utils/constants/site-urls.constants';
import {
  NavigationMenu,
  useNavigationMenuItemContext,
} from '@components/navigation-menu/NavigationMenu';

import {
  NavigationItem,
  NavigationColumnsType,
  NavigationLinkProps,
  NavigationTitle,
} from '../../navigation/Navigation.types';
import { getSkinShopLinks, getTreatmentLinks } from '../../navigation/Navigation.utils';

import styles from './navigation-desktop.module.scss';

export const NavigationDesktop = () => {
  const { t } = useTranslation(['navbar', 'common']);
  const treatmentsLinks = useMemo(() => getTreatmentLinks(t), [t]);
  const skinShopLinks = useMemo(() => getSkinShopLinks(t), [t]);

  return (
    <NavigationMenu className={styles['nav']}>
      <LinkButton
        className={styles['nav__button']}
        href={SITE_URLS.MY_ACCOUNT_BOOKINGS}
        variant="text"
        textProps={{ className: styles['nav__text'] }}
      >
        {t('navbar:myBookings')}
      </LinkButton>
      <NavigationMenu.Item menuId="treatments">
        <NavigationDesktopButton>{t('navbar:shopTreatments')}</NavigationDesktopButton>
        <NavigationDesktopSubmenu columns={treatmentsLinks} />
      </NavigationMenu.Item>
      <NavigationMenu.Item menuId="skin-shop">
        <NavigationDesktopButton>{t('navbar:shopProducts')}</NavigationDesktopButton>
        <NavigationDesktopSubmenu columns={skinShopLinks} />
      </NavigationMenu.Item>
    </NavigationMenu>
  );
};

const NavigationDesktopButton = ({ children }: PropsWithChildren) => {
  const { isActive, handleToggle, menuItemAtts } = useNavigationMenuItemContext();
  return (
    <Button
      variant="text"
      onClick={handleToggle}
      className={styles['nav__button']}
      textProps={{
        className: cx(styles['nav__text'], {
          [styles['nav__text--active']]: isActive,
        }),
      }}
      rightIcon={
        <Icon
          family="therapie"
          name="drop_down"
          color="night-300"
          className={cx(styles['nav__icon'], { [styles['nav__icon--active']]: isActive })}
        />
      }
      {...menuItemAtts}
    >
      {children}
    </Button>
  );
};

const NavigationDesktopSubmenu = ({ columns }: { columns: NavigationColumnsType }) => (
  <NavigationMenu.Submenu className={styles['menu']}>
    <div
      className={cx(styles['menu__content'], {
        [styles['menu__content--narrow']]: columns.length <= 3,
      })}
    >
      <NavigationColumns columns={columns} />
    </div>
  </NavigationMenu.Submenu>
);

const NavigationColumns = ({ columns }: { columns: NavigationColumnsType }) => {
  if (!columns || !columns.length) {
    return null;
  }

  return (
    <>
      {columns.map((items, columnIndex) => (
        <div key={columnIndex}>
          {items.map((item, index) => {
            if (item.children) {
              return (
                <nav key={index} aria-label={item.text} className={styles['links__group']}>
                  <NavigationTitle>{item.text}</NavigationTitle>
                  <NavigationLinks links={item.children} />
                </nav>
              );
            }
            return (
              <NavigationTitle key={index} href={item.href!}>
                {item.text}
              </NavigationTitle>
            );
          })}
        </div>
      ))}
    </>
  );
};

const NavigationTitle = ({ children, href }: NavigationTitle) => {
  const textProps = { variant: 'h6', weight: 'semi-bold', color: 'night-100' } as TextProps;
  if (href) {
    return (
      <LinkText
        href={href!}
        className={styles['links__title']}
        textProps={{ ...textProps, hoverColor: 'trp-purple' }}
      >
        {children}
      </LinkText>
    );
  }
  return (
    <Text className={styles['links__title']} {...textProps}>
      {children}
    </Text>
  );
};

const NavigationLinks = ({ links }: { links: NavigationItem[] }) => (
  <>
    {links.map((link, index) => (
      <NavigationLink key={index} href={link.href!}>
        {link.text}
      </NavigationLink>
    ))}
  </>
);

const NavigationLink = ({ children, href }: NavigationLinkProps) => (
  <LinkText
    href={href}
    prefetch={false}
    className={styles['links__link']}
    textProps={{ variant: 'h5', color: 'night-400', weight: 'semi-bold', hoverColor: 'trp-purple' }}
  >
    {children}
  </LinkText>
);
