import { AddressInput, User } from '@/data/graphql/types';
import {
  DEFAULT_ADDRESS_BACKBONE,
  MAX_NUMBER_OF_ADDRESSES,
} from '@utils/constants/address.constants';

export const mergeNewUserAddress = (addressList: User['addresses'], newAddress: AddressInput) => {
  const fullNewAddress = { ...DEFAULT_ADDRESS_BACKBONE, ...newAddress };

  // First address will be default for both shipping and billing
  if (!addressList || !addressList.length)
    return [{ ...fullNewAddress, defaultShipping: true, defaultBilling: true }];

  // If the address doesn't default shipping nor billing, we only add it
  if (!fullNewAddress.defaultBilling && !fullNewAddress.defaultShipping)
    return [...addressList, fullNewAddress];

  // If the new address is the default shipping or billing, the rest get deactivated
  return [
    ...addressList.map((address) => {
      const defaultShipping = fullNewAddress.defaultShipping ? false : !!address?.defaultShipping;
      const defaultBilling = fullNewAddress.defaultBilling ? false : !!address?.defaultBilling;
      return { ...address, defaultShipping, defaultBilling };
    }),
    fullNewAddress,
  ];
};

export const updateUserAddresses = (
  addressList: User['addresses'],
  addressId: number,
  newAddress: AddressInput
) => {
  if (!addressList || !addressList[addressId]) return addressList;
  const fullNewAddress = { ...DEFAULT_ADDRESS_BACKBONE, ...newAddress };
  return [
    ...addressList.map((address, index) => {
      if (index === addressId) {
        const defaultShipping = fullNewAddress.defaultShipping ?? false;
        const defaultBilling = fullNewAddress.defaultBilling ?? false;
        return { ...fullNewAddress, defaultShipping, defaultBilling };
      } else {
        const defaultShipping = fullNewAddress.defaultShipping ? false : !!address?.defaultShipping;
        const defaultBilling = fullNewAddress.defaultBilling ? false : !!address?.defaultBilling;
        return { ...address, defaultShipping, defaultBilling };
      }
    }),
  ];
};

export const userCanHaveMoreAddresses = (addresses: User['addresses'] = []) => {
  const numAddressess = addresses?.length ?? 0;
  return numAddressess < MAX_NUMBER_OF_ADDRESSES;
};
