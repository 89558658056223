import { REGION_ADDRESS_CONFIG } from '@utils/constants/address.constants';
import { formatOptionsLabel } from '@utils/functions/format';
import { RegionSectionsType } from './AddressFields.types';

export const getRegionCountyOptions = (field: RegionSectionsType) =>
  REGION_ADDRESS_CONFIG[field].map((county) => ({
    value: county,
    label: formatCountyLabel(county, REGION_ADDRESS_CONFIG.country),
  }));

export const formatCountyLabel = (
  county: string | null | undefined,
  country: string | null | undefined
) => {
  if (typeof county !== 'string') return '';
  const _country = country || '';

  // If it's Ireland and not Dublin, we add the `Co. ` prefix
  const prefix = _country.match(/ireland/i) && !county.match(/dublin/i) ? 'Co. ' : '';

  return formatOptionsLabel(`${prefix}${county}`);
};
