import {
  TrackEventProperties,
  TrackEvents,
  PageEventProperties,
  SendPageEventData,
  SendIdentifyEventData,
  SendTrackEventData,
} from './segment-analytics.types';

/**
 * Wraps the analytics.js API that is exposed via the global window object.
 *
 * The API is made available via the SegmentAnalytics script in _app.page.tsx.
 */
class SegmentAnalyticsService {
  commonProperties: {
    page?: PageEventProperties;
    track: TrackEventProperties;
  };

  constructor() {
    this.commonProperties = {
      track: { currency: process.env.NEXT_PUBLIC_DEFAULT_CURRENCY! },
    };
  }

  /**
   * Record whenever a user sees a page of your website, along with any optional properties about the page.
   *
   * https://segment.com/docs/connections/spec/page/
   * */
  sendPageEvent({
    category,
    name,
    properties = {},
    options = {},
    callback,
  }: SendPageEventData = {}) {
    if (!window?.analytics) return;

    window.analytics.page(category, name, properties, options, callback);
  }

  /**
   * Associate a user to their actions and record traits about them.
   *
   * https://segment.com/docs/connections/spec/identify/
   * */
  sendIdentifyEvent({ userId, traits = {}, options = {}, callback }: SendIdentifyEventData) {
    if (!window?.analytics) return;

    window.analytics.identify(userId, traits, options, callback);
  }

  /**
   * Record any actions your users perform, along with any properties that describe the action.
   *
   * https://segment.com/docs/connections/spec/track/
   */
  sendTrackEvent<TEvent extends TrackEvents = TrackEvents>({
    event,
    properties,
    options = {},
    callback,
  }: SendTrackEventData<TEvent>) {
    if (!window?.analytics) return;

    const commonProperties = this.commonProperties.track;

    window.analytics.track(event, { ...commonProperties, ...properties }, options, callback);
  }
}

export const segmentAnalytics = new SegmentAnalyticsService();
