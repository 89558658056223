import React, { useRef } from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import { Drawer, Title, Text } from '@therapie-ecommerce-ui/ui';

import { CartItemType } from '@/data/graphql/types';
import { Price } from '@components/price/Price';
import { useImage } from '@hooks/useImage/useImage';
import { useProductName } from '@features/product-catalog/hooks/useProductName/useProductName';

import { CheckoutButton } from '../../checkout-button/CheckoutButton';
import { CartAddedItemModalProps } from '../modal/CartAddedItemModal.types';
import { ContinueShoppingButton } from '../../continue-shopping-button/ContinueShoppingButton';

import { BodyContentProps, FooterContentProps } from './CartAddedItemDrawer.types';

import styles from './cart-added-item-drawer.module.scss';

export const CartAddedItemDrawer = ({
  isOpen,
  onClose,
  onExited,
  item,
  totalCartItems,
  cartSubtotal,
}: CartAddedItemModalProps) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const { t } = useTranslation('cart');

  return (
    <Drawer
      placement="bottom"
      size="lg"
      isOpen={isOpen}
      onExited={onExited}
      onClose={onClose}
      closeButtonProps={{
        'aria-label': t('continueShopping'),
        className: styles['drawer__close-button'],
      }}
      containerClassName={styles['drawer__container']}
      headerClassName={styles['drawer__header']}
      bodyClassName={styles['drawer__body']}
      footerClassName={styles['drawer__footer']}
      initialFocusRef={buttonRef}
      headerContent={<HeaderContent />}
      bodyContent={
        <BodyContent
          isOpen={isOpen}
          item={item}
          totalCartItems={totalCartItems}
          cartSubtotal={cartSubtotal}
        />
      }
      footerContent={<FooterContent buttonRef={buttonRef} onClose={onClose} />}
    />
  );
};

const HeaderContent = () => {
  const { t } = useTranslation('cart');

  return (
    <Title variant="h3" weight="semi-bold">
      {t('addedToYourCart')}
    </Title>
  );
};

const BodyContent = ({ isOpen, item, totalCartItems, cartSubtotal }: BodyContentProps) => {
  const { t } = useTranslation('cart');
  const { src } = useImage({ imageSrc: item?.images?.thumbnail });

  const isTreatment = item?.type === CartItemType.Treatment;

  const name = useProductName({ type: item?.type!, name: item?.name! });

  return (
    <div className={styles['product']}>
      <div className={styles['product__image']}>
        {isOpen && <Image objectFit="contain" layout="fill" src={src} alt={item?.name} />}
      </div>
      <div className={styles['product__details']}>
        <div>
          {isTreatment && item.treatmentGroup && <Text weight="light">{item.treatmentGroup}</Text>}
          <Text weight="semi-bold">{name}</Text>
        </div>
        <div>
          <Title variant="h2" tag="p" weight="light">
            <Price>{item?.price!}</Price>
            {!isTreatment && ` x ${item.quantity}`}
          </Title>
        </div>
        <Text weight="light">
          {t('cartSubtotal')}:{' '}
          <Text weight="bold" tag="span">
            <Price>{cartSubtotal}</Price>{' '}
          </Text>
          <Text variant="small-print" tag="span">
            ({t('itemsInCart', { count: totalCartItems })})
          </Text>
        </Text>
      </div>
    </div>
  );
};

const FooterContent = ({ buttonRef, onClose }: FooterContentProps) => {
  return (
    <>
      <CheckoutButton ref={buttonRef} onClick={onClose} className={styles['drawer__cta']} />
      <ContinueShoppingButton onClick={onClose} className={styles['drawer__cta']} />
    </>
  );
};
