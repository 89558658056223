import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';

import { RouteEventHandlerType, UseRouteChangeProps } from './useRouteChange.types';

export const useRouteChange = ({
  onRouteChangeComplete,
  onRouteChangeStart,
}: UseRouteChangeProps) => {
  const router = useRouter();
  const routerEvents = router ? router.events : null;

  const onRouteChangeStartCallback = useCallback(
    (...[url, opts]: RouteEventHandlerType) => onRouteChangeStart?.(routerEvents, url, opts),
    [onRouteChangeStart, routerEvents]
  );

  const onRouteChangeCompleteCallback = useCallback(
    (...[url, opts]: RouteEventHandlerType) => onRouteChangeComplete?.(routerEvents, url, opts),
    [onRouteChangeComplete, routerEvents]
  );

  useEffect(() => {
    if (!routerEvents) {
      return;
    }

    routerEvents.on('routeChangeStart', onRouteChangeStartCallback);
    routerEvents.on('routeChangeComplete', onRouteChangeCompleteCallback);

    return () => {
      routerEvents.off('routeChangeStart', onRouteChangeStartCallback);
      routerEvents.off('routeChangeComplete', onRouteChangeCompleteCallback);
    };
  }, [routerEvents, onRouteChangeCompleteCallback, onRouteChangeStartCallback]);
};
