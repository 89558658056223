import Script from 'next/script';
import { isDevEnv } from '@utils/environment';

export const CookieBanner = () => {
  if (
    isDevEnv() &&
    typeof window !== 'undefined' &&
    window.location.hostname.includes('localhost')
  ) {
    return null;
  }

  return (
    <Script
      id="cookie-banner"
      src="https://consent.cookiebot.com/uc.js"
      data-cbid="c4d2ea5c-0a57-4a02-8a36-74c3fad9a108"
      data-blockingmode="auto"
      type="text/javascript"
    />
  );
};
