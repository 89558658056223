import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { IconButton } from '@therapie-ecommerce-ui/ui';
import { useAuthenticationStore } from '@therapie-ecommerce-ui/auth';

import { useDevice } from '@hooks/useDevice/useDevice';
import { SITE_URLS } from '@utils/constants/site-urls.constants';

import { MyAccountButtonProps } from './MyAccountButton.types';

export const MyAccountButton = ({ className }: MyAccountButtonProps) => {
  const { t } = useTranslation('header');
  const isUserAuthenticated = useAuthenticationStore((s) => s.isUserAuthenticated);
  const { isDesktop } = useDevice();

  const link = isUserAuthenticated ? SITE_URLS.MY_ACCOUNT_TREATMENTS : SITE_URLS.LOGIN;

  return (
    <Link href={link} passHref prefetch={false}>
      <IconButton
        colorScheme={isUserAuthenticated ? 'grape' : 'night'}
        size="md"
        tag="a"
        className={className}
        aria-label={t('userLinkLabel')}
        iconProps={{ name: 'account', family: 'therapie', size: !isDesktop ? 'md' : 'lg' }}
      />
    </Link>
  );
};
