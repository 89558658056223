import { useMutation, useQueryClient } from 'react-query';
import { ErrorWithResponse } from '@core/fetch/client.types';
import { graphqlGatewayClient } from '@core/graphql/gateway/gateway';
import { generateAuthHeaders } from '@features/authentication/headers';
import { buildLinkUrlForCartItem } from '@features/booking/utils/booking.utils';
import { useCartId } from '@features/cart-and-checkout/hooks/useCartId';
import { CART_ACTIONS } from '@features/cart-and-checkout/store/cart.constants';
import {
  setRemovedItemCartState,
  updateGlobalOperationsCartState,
  updateRemoveItemOperationCartState,
} from '@features/cart-and-checkout/store/cart.store';
import { ChangeItemQuantityProps } from '@features/cart-and-checkout/store/cart.types';
import { setCartAndCheckoutSteps } from '@features/cart-and-checkout/utils/cart-and-checkout.utils';
import { useErrorToast } from '@hooks/useErrorToast/useErrorToast';
import { useSegmentAnalytics } from '@hooks/useSegmentAnalytics/useSegmentAnalytics';
import { getItemDataFromQueryCache } from '@utils/enrichment/item-enrichment';
import { AddCartItemMutation, AddCartItemMutationVariables } from '@/data/graphql/types';

export function useRemoveFromCartMutation() {
  const cartId = useCartId();
  const errorToast = useErrorToast();
  const queryClient = useQueryClient();
  const { sendTrackEvent } = useSegmentAnalytics();

  const { mutate: handleRemoveFromCartMutation, ...rest } = useMutation<
    AddCartItemMutation['addCartItem'],
    ErrorWithResponse,
    ChangeItemQuantityProps,
    unknown
  >(
    async ({ itemId, quantity, type }) => {
      // Determine the cartId: for now, just use offline, later will require the real user ID

      const payload = {
        cartId: cartId!,
        itemId,
        quantity: -Math.abs(quantity),
        type,
      };

      const { addCartItem: cart } = await graphqlGatewayClient.post<
        AddCartItemMutationVariables,
        AddCartItemMutation
      >('/AddCartItem', payload, { headers: generateAuthHeaders() });

      return cart;
    },
    {
      mutationKey: CART_ACTIONS.REMOVE_ITEM,
      onSuccess: (cart, { itemId, quantity, type }) => {
        setRemovedItemCartState({ itemId, removedQuantity: quantity });
        setCartAndCheckoutSteps(cart!);

        const item = getItemDataFromQueryCache(itemId, queryClient);
        sendTrackEvent({
          event: 'Product Removed',
          properties: {
            cartId: cart?.id!,
            productId: itemId,
            crmProductId: item?.crmId!,
            type,
            treatmentType: item?.treatmentType,
            name: item?.name!,
            price: item?.price!,
            quantity,
            url: buildLinkUrlForCartItem(type, item?.slug),
            category: item?.treatmentCollection,
          },
        });
      },
      onError: () => {
        errorToast();
      },
      onMutate: ({ itemId }) => {
        updateGlobalOperationsCartState(1);
        updateRemoveItemOperationCartState(itemId);
      },
      onSettled: (_, __, { itemId }) => {
        updateGlobalOperationsCartState(-1);
        updateRemoveItemOperationCartState(itemId, { shouldRemove: true });
      },
    }
  );

  return { handleRemoveFromCartMutation, rest };
}
