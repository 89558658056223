import React from 'react';

import { cx } from '@therapie-ecommerce-ui/ui';

import { WrapperProps } from './Wrapper.types';

import styles from './wrapper.module.scss';

export const Wrapper = ({ children, className }: WrapperProps) => {
  const LAYOUT_CLASSES = cx(styles['wrapper'], className);

  return <div className={LAYOUT_CLASSES}>{children}</div>;
};
