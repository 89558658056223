import { cx } from '@therapie-ecommerce-ui/ui';

import { useCartItems } from '@features/cart-and-checkout/hooks/useCartItems';

import { CheckoutButton } from '../checkout-button/CheckoutButton';
import { ContinueShoppingButton } from '../continue-shopping-button/ContinueShoppingButton';
import { CartCtaProps } from './CartCta.types';

import styles from './cart-cta.module.scss';

export const CartCta = ({
  className,
  checkoutButtonProps,
  continueShoppingButtonProps,
}: CartCtaProps) => {
  // We can have free items in the cart which can be
  // checked out. So it's better to disable the button
  // by checking how many items we have.
  const { totalItems } = useCartItems();

  return (
    <div className={cx(styles['cart-cta'], className)}>
      <CheckoutButton {...checkoutButtonProps} isDisabled={totalItems === 0} />
      <ContinueShoppingButton {...continueShoppingButtonProps} />
    </div>
  );
};
