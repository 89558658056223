import { useTranslation } from 'next-i18next';
import { Button, Icon, Spinner } from '@therapie-ecommerce-ui/ui';
import { useLogOut } from '@features/authentication/hooks/useLogOut';
import { LogOutProps } from './LogOut.types';

export function LogOut({
  onLogOutSuccess,
  textProps = {},
  shouldShowSpinner = true,
  ...rest
}: LogOutProps) {
  const { t } = useTranslation('common');
  const { canLogOut, isLoading, handleLogOut } = useLogOut();

  const textColor = textProps.color;

  if (!canLogOut) return null;

  return (
    <Button
      variant="outline"
      textProps={textProps}
      leftIcon={<Icon size="sm" family="therapie" name="logout" color={textColor} />}
      isLoading={shouldShowSpinner && isLoading}
      onClick={handleLogOut}
      {...rest}
    >
      {t('buttons.logOut')}
    </Button>
  );
}
