import React from 'react';
import { cx } from '@therapie-ecommerce-ui/ui';

import { ContainerProps } from './Container.types';
import styles from './container.module.scss';

const CONTAINER_CLASS = 'ecomm-container';

export const Container = ({
  children,
  className = '',
  variant = 'responsive',
  tag: Tag = 'div',
  shouldStretch,
  ...restProps
}: ContainerProps) => (
  <Tag
    className={cx(
      styles[`${CONTAINER_CLASS}--${variant}`],
      { [styles[`${CONTAINER_CLASS}--stretch`]]: shouldStretch },
      className
    )}
    {...restProps}
  >
    {children}
  </Tag>
);
