import { AddressInput } from '@/data/graphql/types';
import { getCountiesForRegion } from '@utils/functions/address';
import { AddressConfigListType } from '@utils/types/address.types';
import { ENV_REGION } from './region.constants';

export const MAX_NUMBER_OF_ADDRESSES = 5;

export const ADDRESS_CONFIG_LIST_BY_REGION = {
  roi: {
    country: 'Ireland',
    counties: getCountiesForRegion('roi'),
    states: [],
    defaultCounty: '',
    defaultState: '',
  },
  uk: {
    country: 'UK',
    counties: getCountiesForRegion('uk'),
    states: [],
    defaultCounty: '',
    defaultState: '',
  },
  us: {
    country: 'United States',
    counties: [],
    states: getCountiesForRegion('us'),
    defaultCounty: '',
    defaultState: 'NEW YORK',
  },
} as AddressConfigListType;

export const REGION_ADDRESS_CONFIG = ADDRESS_CONFIG_LIST_BY_REGION[ENV_REGION];

export const DEFAULT_ADDRESS_BACKBONE = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  line1: '',
  line2: '',
  city: '',
  state: REGION_ADDRESS_CONFIG.defaultState,
  county: REGION_ADDRESS_CONFIG.defaultCounty,
  postcode: '',
  country: REGION_ADDRESS_CONFIG.country,
  defaultShipping: false,
  defaultBilling: false,
} as AddressInput;
