import { useEffect } from 'react';

import { publish } from '@therapie-ecommerce-ui/events';

import { log } from '@core/logging/logging';
import { useAddToCartMutation } from '@features/cart-and-checkout/api/hooks/useAddToCartMutation';
import { useSubscribe } from '@hooks/useSubscribe/useSubscribe';
import { useRouteChange } from '@hooks/useRouteChange/useRouteChange';
import { useClearCacheOnLogOut } from '@features/authentication/hooks/useLogOut';

import { useCartItems } from '../hooks/useCartItems';
import {
  clearItemsFromCartState,
  clearRemovedItemsCartState,
  initialiseCartState,
  useCartStore,
} from '../store/cart.store';
import { useGetCartQuery } from '../api/hooks/useGetCartQuery';
import { useGetUserLoyaltyPoints } from '@/api/hooks/useGetUserLoyaltyPoints';

export const useCartSetup = () => {
  const { totalItems } = useCartItems();
  const { mutate: handleAddToCartMutation } = useAddToCartMutation();

  const { handleClearCacheOnLogOut } = useClearCacheOnLogOut();
  useGetUserLoyaltyPoints();
  useGetCartQuery();
  const status = useCartStore((state) => state.status);

  useRouteChange({
    onRouteChangeComplete: () => {
      clearRemovedItemsCartState();
    },
  });

  useEffect(() => {
    const globalOperationsUnsubscribe = useCartStore.subscribe(
      (state) => state.status.globalOperations,
      (globalOperations) => publish('CART/UPDATE-GLOBAL-OPERATIONS', globalOperations)
    );

    const addItemOperationUnsubscribe = useCartStore.subscribe(
      (state) => state.status.operations,
      ({ add_item_to_cart }) => publish('CART/ADD_ITEM_OPERATION', { add_item_to_cart })
    );

    return () => {
      globalOperationsUnsubscribe();
      addItemOperationUnsubscribe();
    };
  }, []);

  useEffect(() => {
    if (status.isInitialised) {
      publish('CART/UPDATE_CART_ITEM_TOTAL', totalItems);
    }
  }, [status.isInitialised, totalItems]);

  useSubscribe('CART/ADD-ITEM', (_, payload) => handleAddToCartMutation(payload));
  useSubscribe('AUTHENTICATION/SIGN-IN', (user) => {
    log({
      level: 'info',
      category: 'login',
      message: 'authentication_sign_in',
      messageContext: { user },
    });
  });
  useSubscribe('AUTHENTICATION/SIGN-OFF', () => {
    handleClearCacheOnLogOut();
    clearItemsFromCartState();
    initialiseCartState();
  });
};
