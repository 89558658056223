import { useEffect, useState } from 'react';
import {
  EventsPayloads,
  subscribe,
  SubscribeHandlerType,
  Topics,
  unsubscribe,
} from '@therapie-ecommerce-ui/events';

/**
 *  This hook is used to subscribe to a pubsub topic.
 *
 *  It returns the current (reactive) value of the topic, and optionally takes a handler function that runs when the value updates.
 *
 *  Example:
 *
 *  1. const value = useSubcribe('TOPIC');
 *
 *  2. useSubscribe('TOPIC', (value) => { // do something with value });
 *
 * */
export const useSubscribe = <T extends Topics>(topic: T, handler?: SubscribeHandlerType<T>) => {
  const [value, setValue] = useState<EventsPayloads[T]>();

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const token = subscribe(topic, (msg, payload) => {
      if (handler) {
        handler(msg, payload);
      }
      setValue(payload as EventsPayloads[T]);
    });

    return () => {
      unsubscribe(token);
    };
  }, [topic, handler]);

  return value;
};
