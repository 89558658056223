import { Maybe } from 'graphql/jsutils/Maybe';
import { AddressFieldKeys } from '@components/forms/address-fields/AddressFields.types';
import { ParseAddressFunctionType } from '@features/cart-and-checkout/components/checkout-workflow/CheckoutWorkflow.types';
import { Address, AddressInput, User } from '@/data/graphql/types';
import { CheckIsSameAddressType } from './CheckoutWorkflow.types';

const ALLOWED_ADDRESS_KEYS: AddressFieldKeys[] = [
  'firstName',
  'lastName',
  'phoneNumber',
  'line1',
  'line2',
  'postcode',
  'city',
  'country',
  'county',
  'state',
];

export const parseAddressObject: ParseAddressFunctionType = (address) => {
  return ALLOWED_ADDRESS_KEYS.reduce<any[]>((parsedAddress, key) => {
    const value = address[key as AddressFieldKeys] ?? '';

    return [...parsedAddress, value];
  }, []).join(', ');
};

export const checkIsSameAddress: CheckIsSameAddressType = (address1, address2) => {
  return parseAddressObject(address1) === parseAddressObject(address2);
};

export const validateAndUseAddressIfExists = (
  addresses: Maybe<Address>[] = [],
  prevBillingAddress?: AddressInput
): AddressInput | undefined => {
  if (!prevBillingAddress?.line1) return undefined;

  for (const address of addresses) {
    if (checkIsSameAddress(address ?? {}, prevBillingAddress)) {
      return prevBillingAddress;
    }
  }
};

export const getUsersFullName = (user?: User) => `${user?.firstName} ${user?.lastName}`;
