import { cx } from '@therapie-ecommerce-ui/ui';

import { QuantitySpinButtons } from '@components/quantity-spin-buttons/QuantitySpinButtons';

import { CartItemControlsProps } from './CartItemControls.types';

import styles from './cart-item-controls.module.scss';

export const CartItemControls = ({
  className,
  quantitySpinButtonsProps,
  isRemoved,
  outOfStock,
}: CartItemControlsProps) => {
  if (isRemoved || outOfStock) return null;

  return (
    <QuantitySpinButtons
      className={cx(styles['controls'], className)}
      buttonClassName={styles['controls__buttons']}
      quantityClassName={styles['controls__value']}
      {...quantitySpinButtonsProps}
    />
  );
};
