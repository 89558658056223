import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { cx, IconButton, useDebounce } from '@therapie-ecommerce-ui/ui';

import { useCartItems } from '@features/cart-and-checkout/hooks/useCartItems';
import { useCartOperations } from '@features/cart-and-checkout/hooks/useCartOperations';
import { SITE_URLS } from '@utils/constants/site-urls.constants';

import { CartIconProps } from './CartIcon.types';

import styles from './cart-icon.module.scss';

export const CartIcon = ({ className, iconClassName, size, onClick }: CartIconProps) => {
  const { t } = useTranslation('header');
  const { totalItems } = useCartItems();
  const cartGlobalOperations = useCartOperations();

  const hasItemsInCart = !!totalItems;

  const isCartPage = useRouter()?.route === SITE_URLS.CART;
  const debouncedIsLoadingCartGlobalOperations = useDebounce(cartGlobalOperations > 0, 300);

  const isLoading = cartGlobalOperations === 0 ? false : debouncedIsLoadingCartGlobalOperations;

  return (
    <div className={styles['cart-icon']}>
      <IconButton
        isLoading={isLoading}
        colorScheme={hasItemsInCart ? 'grape' : 'night'}
        size="md"
        className={cx(
          styles['cart-icon__button'],
          { [styles['cart-icon__button--disabled']]: isCartPage },
          className
        )}
        iconProps={{
          family: 'therapie',
          name: 'shopping-cart',
          size: size,
          className: cx(styles['cart-icon__icon'], iconClassName),
        }}
        aria-label={t('storeLinkLabel')}
        // Remove onClick handler when on the Cart page, refer to hooks/useCartProductItem
        onClick={isCartPage ? undefined : onClick}
        tabIndex={isCartPage ? -1 : 0}
      />
      {hasItemsInCart && <div className={styles['cart-icon__badge']}>{totalItems}</div>}
    </div>
  );
};
