import { useEffect } from 'react';
import { IS_NEW_ASYNC_PAYMENT_FLOW_ENABLED } from '@features/cart-and-checkout/constants/checkout.constants';
import { useCancelPendingIntents } from '@features/stripe/hooks/useCancelPendingIntents/useCancelPendingIntents';

export const CancelPendingIntents = () => {
  const { handleCancelPendingIntentsSync } = useCancelPendingIntents();

  useEffect(() => {
    function onUnload() {
      // This will trigger when going to Affirm, disable for now
      if (IS_NEW_ASYNC_PAYMENT_FLOW_ENABLED) return null;
      handleCancelPendingIntentsSync();

      // Return null so the browser will not show a confirmation dialog
      return null;
    }

    window.addEventListener('beforeunload', onUnload);

    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, [handleCancelPendingIntentsSync]);

  return null;
};
