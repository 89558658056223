import { useQuery } from 'react-query';
import { useAuthenticationStore } from '@therapie-ecommerce-ui/auth';
import {
  GetPaymentServiceUserQuery,
  GetPaymentServiceUserQueryVariables,
} from '@/data/graphql/types';
import { graphqlGatewayClient } from '@core/graphql/gateway/gateway';
import { generateAuthHeaders } from '@features/authentication/headers';

export const GET_PAYMENT_SERVICE_USER = 'GET_PAYMENT_SERVICE_USER';

export const useGetPaymentServiceUserQuery = () => {
  const id = useAuthenticationStore((s) => s.user?.username!);

  return useQuery(
    GET_PAYMENT_SERVICE_USER,
    async () => {
      const { getPaymentServiceUser: user } = await graphqlGatewayClient.post<
        GetPaymentServiceUserQueryVariables,
        GetPaymentServiceUserQuery
      >('/GetPaymentServiceUser', undefined, { headers: generateAuthHeaders() });

      return user;
    },
    {
      enabled: !!id,
    }
  );
};
