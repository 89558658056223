export const ALL = 'all';
export const SKIN_TREATMENTS = 'skinTreatments';
export const SKIN_CONCERNS = 'skinConcerns';
export const BRANDS = 'brands';
export const PRODUCT_TYPE = 'productType';
export const SKIN_TYPE = 'skinType';
export const ANTI_OXIDANT = 'antiOxidant';
export const GLYCOLIC = 'glycolic';
export const PEPTIDE = 'peptide';
export const SALICYLIC = 'salicylic';
export const VITAMIN_C = 'vitaminC';
