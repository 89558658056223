import { SITE_URLS } from '@utils/constants/site-urls.constants';
import { SiteURLKeysType, SiteURLsType, UrlParams } from '@utils/types/url.types';

// Pass undefined if no params are required
export const buildLinkUrl = <
  TKey extends SiteURLKeysType,
  TValue extends typeof SITE_URLS[TKey] = typeof SITE_URLS[TKey]
>(
  key: TKey,
  params: UrlParams<TValue> | undefined
) => {
  return interpolateLinkUrl(SITE_URLS[key], params as any);
};

export const interpolateLinkUrl = <TPath extends SiteURLsType>(
  path: TPath,
  params: UrlParams<TPath> | undefined
): string => {
  let interpolatedUrl = path as string;

  const queryParams = new URLSearchParams();

  for (const key in params) {
    const regex = new RegExp(`\\[${key}\\]`, 'g');
    if (interpolatedUrl.match(regex)) {
      interpolatedUrl = interpolatedUrl.replace(regex, (params as any)[key]);
    } else {
      const queryParam = (params as any)[key];
      if (queryParam) queryParams.append(key, queryParam);
    }
  }

  const queryString = queryParams.toString();

  return `${interpolatedUrl}${queryString ? `?${queryString}` : ''}`;
};
