import { QueryClient, setLogger } from 'react-query';

if (process.env.NEXT_PUBLIC_LOG_DISABLE_CONSOLE === '1') {
  setLogger({
    error: () => {},
    log: () => {},
    warn: () => {},
  });
}

export const setupQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });
