import NextImage from 'next/image';
import { useTranslation } from 'next-i18next';

import { cx } from '@therapie-ecommerce-ui/ui';

import { CartItemLink } from '../cart-item-link/CartItemLink';
import { CartItemImageProps } from './CartItemImage.types';

import styles from './cart-item-image.module.scss';

export const CartItemImage = ({
  name,
  hasImg,
  imgSrc,
  itemHref,
  isLoading,
  isRemoved,
  handleSendAnalytics,
}: CartItemImageProps) => {
  const { t } = useTranslation('common');

  return (
    <CartItemLink
      href={itemHref}
      className={cx(styles['item-image'], {
        [styles['item-image--removed']]: isRemoved,
      })}
      onClick={handleSendAnalytics}
      isDisabled={isLoading || isRemoved}
    >
      <NextImage
        src={imgSrc}
        alt={hasImg ? name! : t('images.noImageAvailable')}
        layout="fill"
        objectFit="contain"
      />
    </CartItemLink>
  );
};
