import React, { useState } from 'react';

import { createContext, cx, useClickOutside } from '@therapie-ecommerce-ui/ui';
import { useRouteChange } from '@hooks/useRouteChange/useRouteChange';

import {
  MenuSubmenuProps,
  MenuIdType,
  NavigationMenuItemContextType,
  MenuItemProps,
  NavigationMenuContextType,
  NavigationMenuProps,
} from './NavigationMenu.types';

import styles from './navigation-menu.module.scss';

const MENU_ITEM_ID_PREFIX = 'nav-menu-item';
const MENU_SUBMENU_ID_PREFIX = 'nav-menu-submenu';

export const [NavigationMenuContextProvider, useNavigationMenuContext] =
  createContext<NavigationMenuContextType>();

export const [MenuItemContextProvider, useNavigationMenuItemContext] =
  createContext<NavigationMenuItemContextType>();

/** Displays a list Navigation items inline */
export const NavigationMenu = ({ tag: Tag = 'nav', children, className }: NavigationMenuProps) => {
  const [selectedItem, setSelectedItem] = useState<MenuIdType>();

  useRouteChange({ onRouteChangeComplete: () => setSelectedItem(null) });

  const clickOutsideRef = useClickOutside(() => setSelectedItem(null));

  return (
    <NavigationMenuContextProvider value={{ selectedItem, setSelectedItem }}>
      <Tag ref={clickOutsideRef} className={className}>
        {children}
      </Tag>
    </NavigationMenuContextProvider>
  );
};

const MenuItem = ({ menuId, tag, className, children }: MenuItemProps) => {
  const { selectedItem, setSelectedItem } = useNavigationMenuContext();

  const isActive = selectedItem === menuId;

  const handleToggle = () => {
    setSelectedItem?.((prev) => (prev === menuId ? null : menuId));
  };

  const menuItemAtts = {
    id: `${MENU_ITEM_ID_PREFIX}-${menuId}`,
    'aria-controls': `${MENU_SUBMENU_ID_PREFIX}-${menuId}`,
    'aria-expanded': isActive,
  };

  const Tag = tag ?? (className ? 'div' : undefined);

  return (
    <MenuItemContextProvider value={{ menuId, isActive, handleToggle, menuItemAtts }}>
      {Tag ? <Tag className={className}>{children}</Tag> : children}
    </MenuItemContextProvider>
  );
};

const MenuSubmenu = ({ children, tag: Tag = 'nav', className }: MenuSubmenuProps) => {
  const { menuId, isActive } = useNavigationMenuItemContext();
  return (
    <Tag
      id={`${MENU_SUBMENU_ID_PREFIX}-${menuId}`}
      aria-labelledby={`${MENU_ITEM_ID_PREFIX}-${menuId}`}
      className={cx(styles['submenu'], { [styles['submenu--hidden']]: !isActive }, className)}
    >
      {children}
    </Tag>
  );
};

/** An item on the NavigationMenu, wraps MenuTrigger and MenuSubmenu */
NavigationMenu.Item = MenuItem;
/** Contains the Menu content */
NavigationMenu.Submenu = MenuSubmenu;
