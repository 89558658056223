import React from 'react';

import { useTranslation } from 'next-i18next';

import { Title } from '@therapie-ecommerce-ui/ui';

import { Container } from '@components/container/Container';

import { FooterSocialLinks } from '../footer-social-links/FooterSocialLinks';
import { FooterCopyright } from '../footer-copyright/FooterCopyright';
import { FooterProps } from '../Footer.types';

import styles from './footer-desktop.module.scss';

/**
 * @todo Replace keys for links with href's when they are unique
 */
export const FooterDesktop = ({ groups }: FooterProps) => {
  const { t } = useTranslation('footer');

  return (
    <footer className={styles['footer']}>
      <Container>
        <FooterSocialLinks />
        <div className={styles['footer__columns']}>
          {groups.map((group) => {
            return (
              <div key={group.title} className={styles['footer__column']}>
                <header className={styles['footer__column-header']}>
                  <Title weight="bold" variant="h6" color="night-200">
                    {t(`navigation.${group.title}.title`)}
                  </Title>
                </header>
                <nav aria-labelledby={group.title}>
                  <ul className={styles['footer__column-list']}>
                    {group.links.map((link, index) => (
                      <li
                        key={`footer-column-item-${index}-${link.props.href}`}
                        className={styles['footer__column-item']}
                      >
                        {link}
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            );
          })}
        </div>
      </Container>
      <FooterCopyright />
    </footer>
  );
};
