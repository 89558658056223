import { useBreakpoints } from '@therapie-ecommerce-ui/ui';
import { RESPONSIVE_MEDIA_QUERIES } from '@utils/constants/media-queries.constants';

export const useDevice = () => {
  const uiBreakpoints = useBreakpoints(RESPONSIVE_MEDIA_QUERIES);

  const isDesktop = uiBreakpoints.isXxl || uiBreakpoints.isXl;
  const isTablet = uiBreakpoints.isLg;
  const isMobile = !isDesktop && !isTablet;

  return {
    ...uiBreakpoints,
    isDesktop,
    isTablet,
    isMobile,
  };
};
