import React, { ErrorInfo } from 'react';
import { withTranslation } from 'react-i18next';

import { log } from '@core/logging/logging';
import { Text } from '@therapie-ecommerce-ui/ui';

import {
  ErrorBoundaryCustomisableProps,
  ErrorBoundaryProps,
  ErrorBoundaryState,
} from './ErrorBoundary.types';

class ErrorBoundaryComponent extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static displayName = 'ErrorBoundary';

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: any): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    log({
      level: 'error',
      category: 'internal',
      message: 'error_boundary_error_caught',
      messageContext: { error: error.toString(), errorInfo },
    });

    this.props.onError?.(error, errorInfo);
  }

  render() {
    const { t } = this.props;

    if (this.state.hasError) {
      const fallbackContent = this.props.fallbackContent;

      if (fallbackContent) {
        return fallbackContent;
      }

      return (
        <Text variant={{ sm: 'h6', md: 'h5' }} color="scarlett-500">
          {t('errors.unexpectedError')}
        </Text>
      );
    }

    return this.props.children;
  }
}

/** Component that catches and handles errors within it's children */
export const ErrorBoundary = withTranslation('common')(ErrorBoundaryComponent);

/** HOC to wrap a component with an `ErrorBoundary` */
export const withErrorBoundary = <T extends {}>(
  Component: React.ComponentType<T>,
  errorBoundaryProps?: ErrorBoundaryCustomisableProps
): React.ComponentType<T> => {
  const WrappedComponent: React.ComponentType<T> = (props) => (
    <ErrorBoundary {...errorBoundaryProps}>
      <Component {...props} />
    </ErrorBoundary>
  );

  const name = Component.displayName || Component.name || 'Unknown';

  WrappedComponent.displayName = `withErrorBoundary(${name})`;

  return WrappedComponent;
};
