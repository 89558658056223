import { useCallback } from 'react';
import { useCartStore } from '@features/cart-and-checkout/store/cart.store';
import { CartOperations, CartState } from '@features/cart-and-checkout/store/cart.types';

export function useCartOperations<T extends keyof CartOperations | undefined>(operation?: T) {
  const selector = useCallback(
    (state: CartState) => {
      return operation
        ? state.status.operations[operation as keyof CartOperations]
        : state.status.globalOperations;
    },
    [operation]
  );

  return useCartStore(selector) as T extends undefined
    ? number
    : CartOperations[T & keyof CartOperations];
}
