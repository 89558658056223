import { useRouter } from 'next/router';
import { ParsedUrlQueryInput } from 'querystring';
import { SITE_URLS } from '@utils/constants/site-urls.constants';
import { log } from '@core/logging/logging';
import {
  GetLinkPropsWithOriginProps,
  HandleRedirectKeepOriginProps,
  HandleRedirectOriginProps,
  HandleRedirectToOriginProps,
  HandleRedirectWithOriginProps,
} from './useRedirectOrigin.types';

/**
 * Query param to control the origin
 */
export const ORIGIN_KEY = 'origin';
/**
 * Query param to control the behaviour of `handleRedirectOrigin`
 */
export const SHOULD_REDIRECT_TO_ORIGIN_KEY = 'redirectToOrigin';

/**
 * @description This hook handles redirections that need to add/keep/lead to an `origin` path
 */
export const useRedirectOrigin = () => {
  const router = useRouter();

  const origin = (router?.query?.[ORIGIN_KEY] ?? '') as string;
  const shouldRedirectToOrigin =
    origin && router?.query?.[SHOULD_REDIRECT_TO_ORIGIN_KEY] === 'true';

  /**
   * Makes a redirection to a given path and adds a param from with the current path as the value
   * @param param.path - Path to redirect to.
   */
  const handleRedirectWithOrigin = ({ path, query = {} }: HandleRedirectWithOriginProps) => {
    router.push(
      {
        pathname: path,
        query: { ...query, [ORIGIN_KEY]: router.asPath },
      },
      path
    );
  };

  /**
   * Makes a redirection to a given path and keeps the param from coming from the current url if present.
   * Otherwise will do a plain redirection.
   * @param param.path - Path to redirect to.
   */
  const handleRedirectKeepOrigin = ({ path, query = {} }: HandleRedirectKeepOriginProps) => {
    router.push(
      {
        pathname: path,
        query: {
          ...query,
          ...(origin?.length ? { [ORIGIN_KEY]: origin } : {}),
        },
      },
      path
    );
  };

  /**
   * Makes a redirection to the origin path coming from the param from in the current url.
   * The function has a fallback param where we can specify where we want to lead the user
   * @param options.fallback - Fallback path when there is no `origin` detected. (Default: Homepage)
   */
  const handleRedirectToOrigin = (options: HandleRedirectToOriginProps = {}) => {
    const { fallback = SITE_URLS.HOME } = options;
    const destinationPage = typeof origin === 'string' && origin ? origin : fallback;

    // TODO: We've removed Query params for now, but we should add them back in the future

    try {
      return router.push(destinationPage);
    } catch {
      log({
        level: 'error',
        category: 'internal',
        message: 'router_redirect_to_origin_error',
        messageContext: {
          destinationPage,
        },
      });
    }
  };

  /**
   * Makes a redirection to either the origin or the given path depending on the value of `?redirectToOrigin`
   *
   * `?redirectToOrigin=true` - redirect to the origin and consume it
   *
   * `?redirectToOrigin=false` - redirect to the given path and keep the origin
   */
  const handleRedirectOrigin = ({ path, fallback }: HandleRedirectOriginProps) => {
    shouldRedirectToOrigin
      ? handleRedirectToOrigin({ fallback })
      : handleRedirectKeepOrigin({ path });
  };

  /**
   * Returns an object with the props `{ href, as }` that will be used as props for a
   * `Next Link` component. It generates them dynamically out of a given path.
   * @param param.path - Path the component will link to.
   */
  const getLinkPropsWithOrigin = ({ href, ...rest }: GetLinkPropsWithOriginProps) => {
    let newPathname;
    let newQuery = {} as ParsedUrlQueryInput;

    if (typeof href === 'string') {
      newPathname = shouldRedirectToOrigin && origin ? origin : href;
    } else {
      const { pathname, query } = href;
      newPathname = shouldRedirectToOrigin ? origin : pathname ?? '';
      newQuery = (query || {}) as ParsedUrlQueryInput;
    }

    if (!shouldRedirectToOrigin && origin && !newQuery?.[ORIGIN_KEY]) {
      Object.assign(newQuery, { [ORIGIN_KEY]: origin });
    }

    return {
      href: {
        pathname: newPathname,
        query: newQuery,
      },
      as: newPathname,
      ...rest,
    };
  };

  return {
    origin,
    shouldRedirectToOrigin,
    handleRedirectWithOrigin,
    handleRedirectKeepOrigin,
    handleRedirectToOrigin,
    handleRedirectOrigin,
    getLinkPropsWithOrigin,
  };
};
