export const CHECKOUT_ACTIONS = {
  SET_CHECKOUT_STATUS: 'SET_CHECKOUT_STATUS',
  SET_VOUCHER: 'SET_VOUCHER',
  SET_LOYALTY_POINTS: 'SET_LOYALTY_POINTS',
  REMOVE_VOUCHER: 'REMOVE_VOUCHER',
  SET_CHECKOUT_ERROR: 'SET_CHECKOUT_ERROR',
  SET_CHECKOUT_DATA: 'SET_CHECKOUT_DATA',
  SET_STEPS: 'SET_STEPS',
  SET_INITIAL_STEP: 'SET_INITIAL_STEP',
  RESET_CHECKOUT: 'RESET_CHECKOUT',
};
