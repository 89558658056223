import { cx } from '@therapie-ecommerce-ui/ui';
import { CartItemType } from '@/data/graphql/types';
import { CartItemControls } from './cart-item-controls/CartItemControls';
import { CartItemImage } from './cart-item-image/CartItemImage';
import { CartItemLoadingOverlay } from './cart-item-loading-overlay/CartItemLoadingOverlay';
import { CartProductName } from './cart-item-name/cart-product-name/CartProductName';
import { CartTreatmentName } from './cart-item-name/cart-treatment-name/CartTreatmentName';
import { CartItemOutOfStock } from './cart-item-out-of-stock/CartItemOutOfStock';
import { CartItemPrice } from './cart-item-price/CartItemPrice';
import { CartItemQuantity } from './cart-item-quantity/CartItemQuantity';
import { CartItemRemoveButton } from './cart-item-remove-button/CartItemRemoveButton';
import { CartItemRemoved } from './cart-item-removed/CartItemRemoved';
import { CartItemSubtotal } from './cart-item-subtotal/CartItemSubtotal';
import { useCartItem } from './hooks/useCartItem';
import { CartItemDesktopProps, CartItemProps } from './CartItem.types';
import styles from './cart-item.module.scss';

// Used in CartSidebar, CheckoutCart and CartPage (mobile)
export const CartItem = ({ item, containerClassName, hasControls = true }: CartItemProps) => {
  const cartItemProps = useCartItem(item);

  const { quantity, handleIncrementQuantity, handleDecrementQuantity, isLoading } = cartItemProps;

  const isTreatment = item.type === CartItemType.Treatment;

  return (
    <article className={styles['item']}>
      <div
        className={cx(styles['item__container'], containerClassName, {
          [styles['item__container--loading']]: isLoading,
        })}
      >
        <CartItemImage {...cartItemProps} />
        <div className={styles['item__info']}>
          <div
            className={cx(styles['item__info__row'], {
              [styles['item__info__row--single-item']]: !hasControls,
            })}
          >
            {isTreatment ? (
              <CartTreatmentName
                textProps={{ variant: { sm: 'h6', md: 'h5' } }}
                {...cartItemProps}
              />
            ) : (
              <CartProductName textProps={{ variant: { sm: 'h6', md: 'h5' } }} {...cartItemProps} />
            )}

            {hasControls && <CartItemRemoveButton {...cartItemProps} />}
          </div>
          <div className={styles['item__info__row']}>
            <CartItemRemoved {...cartItemProps} />
            {hasControls ? (
              <CartItemControls
                quantitySpinButtonsProps={{
                  quantity,
                  decrementDisabled: quantity === 0,
                  handleDecrement: handleDecrementQuantity,
                  handleIncrement: handleIncrementQuantity,
                }}
                {...cartItemProps}
              />
            ) : (
              <CartItemQuantity {...cartItemProps} />
            )}
            <CartItemOutOfStock
              textProps={{ className: styles['item__info__quantity'] }}
              {...cartItemProps}
            />
            <CartItemSubtotal {...cartItemProps} />
          </div>
        </div>
      </div>
      {isLoading && <CartItemLoadingOverlay />}
    </article>
  );
};

// Used in CartPage (desktop)
export const CartItemDesktop = ({ item }: CartItemDesktopProps) => {
  const cartItemProps = useCartItem(item);

  const { quantity, handleIncrementQuantity, handleDecrementQuantity, isLoading } = cartItemProps;

  const isTreatment = item.type === CartItemType.Treatment;

  return (
    <div className={styles['item-desktop']}>
      <div
        className={cx(styles['item-desktop__container'], {
          [styles['item-desktop__container--loading']]: isLoading,
        })}
      >
        <div className={styles['item-desktop__info']}>
          <CartItemImage {...cartItemProps} />
          {isTreatment ? (
            <CartTreatmentName textProps={{ variant: 'h3' }} {...cartItemProps} />
          ) : (
            <CartProductName textProps={{ variant: 'h3' }} {...cartItemProps} />
          )}
        </div>
        <div className={styles['item-desktop__price']}>
          <CartItemPrice {...cartItemProps} />
        </div>
        <div className={styles['item-desktop__quantity']}>
          <CartItemRemoved textProps={{ variant: 'h5' }} {...cartItemProps} />
          <CartItemOutOfStock
            textProps={{ className: styles['item-desktop__out-of-stock'], variant: 'h5' }}
            {...cartItemProps}
          />
          <CartItemControls
            quantitySpinButtonsProps={{
              buttonClassName: styles['item-desktop__quantity__button'],
              quantityClassName: styles['item-desktop__quantity__value'],
              quantity,
              decrementDisabled: quantity === 0,
              handleDecrement: handleDecrementQuantity,
              handleIncrement: handleIncrementQuantity,
            }}
            {...cartItemProps}
          />
        </div>
        <div className={styles['item-desktop__subtotal']}>
          <CartItemSubtotal textProps={{ weight: 'semi-bold' }} {...cartItemProps} />
        </div>
        <CartItemRemoveButton {...cartItemProps} />
      </div>
      {isLoading && <CartItemLoadingOverlay />}
    </div>
  );
};
