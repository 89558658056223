import { getWPTenantForRegion, getWPUrlForRegion } from '@utils/functions/region';
import { SiteRegionURLsType } from '@utils/types/url.types';

export const ENV_REGION = process.env.NEXT_PUBLIC_DEFAULT_REGION as SiteRegionURLsType;

export const SORTED_ACTIVE_REGIONS = ['uk', 'roi', 'us'] as SiteRegionURLsType[];

export const WP_TENANT = getWPTenantForRegion(ENV_REGION);
/** This is the Wordpress URL that matches the region specified with `NEXT_PUBLIC_DEFAULT_REGION` */
export const WORDPRESS_URL = getWPUrlForRegion(ENV_REGION);
