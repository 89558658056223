import { useCallback, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { TFunction } from 'react-i18next';
import { TreatmentGroupType } from '@features/treatments/pages/treatments-page/TreatmentsPage.types';
import { TREATMENT_GROUP_QUERY_MAP } from './useTreatmentGroups.constants';
import { GetTreatmentGroupNameProps } from './useTreatmentGroups.types';

export function getTreatmentGroups(t: TFunction<'navbar'>) {
  const treatmentGroupsAvailable = t('treatmentGroupsLinks', { returnObjects: true });
  const treatmentGroupsRef = t('treatmentGroups', { returnObjects: true }) as any;
  return treatmentGroupsAvailable.map(([key]) => {
    const { slug, label } = treatmentGroupsRef[key];

    return {
      value: slug,
      label,
    };
  });
}

export const useTreatmentGroups = () => {
  const { t } = useTranslation('navbar');

  const allTreatmentGroupsInfo = useMemo(() => getTreatmentGroups(t), [t]);

  // UI-TODO: Leverage this to the BE?
  const getTreatmentGroupName = useCallback(
    ({ treatmentGroup, shouldFallback = true }: GetTreatmentGroupNameProps) => {
      const fallback = shouldFallback ? t('uncategorised') : undefined;
      const translationKey = treatmentGroup
        ? TREATMENT_GROUP_QUERY_MAP[treatmentGroup as TreatmentGroupType]
        : '';
      const treatmentGroupName = translationKey ? (t(translationKey) as string) : '';

      // If the translation is found (different from the i18n key)
      return treatmentGroupName !== translationKey ? treatmentGroupName : fallback;
    },
    [t]
  );

  return { allTreatmentGroupsInfo, getTreatmentGroupName };
};
