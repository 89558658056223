import { toast } from 'react-toastify';
import { Toast, ToastVariantType } from '@therapie-ecommerce-ui/ui';
import { datadogLogs } from '@datadog/browser-logs';
import { isDevEnv } from '@utils/environment';
import { LogLevelToastMap, LogOptions } from './logging.types';

const LOG_LEVEL_TOAST_MAP: LogLevelToastMap = {
  log: 'neutral',
  debug: 'neutral',
  info: 'neutral',
  warn: 'warning',
  error: 'danger',
};

/**
 * Log function that can be used to handle errors.
 * A log must have a message and a level, and can have:
 * - a user message, that will be displayed with a Toast
 * - a context object, to give additional information
 *
 * To send logs to Datadog, a client token must be set in env vars.
 * Logs are sent to Datadog in two ways: by calling this function or
 * automatically by using console.* .
 *
 * Note: only for client-side logging, does not work on NextJS API
 */

export function log({
  level,
  message,
  category,
  messageContext = {},
  sensitiveContext = {},
  userMessage,
}: LogOptions) {
  if (typeof window === 'undefined') {
    return;
  }

  if (userMessage) {
    toast(<Toast variant={LOG_LEVEL_TOAST_MAP[level] as ToastVariantType}>{userMessage}</Toast>);
  }

  const hasConsented = window?.Cookiebot?.consent?.marketing || isDevEnv();

  let userData = { username: '' };
  try {
    userData = JSON.parse(localStorage.getItem('USER_DATA') ?? '{}');
  } catch (e) {}

  const baseContext = {
    offlineUserId: localStorage.getItem('CART_OFFLINE_USER_ID'),
    userId: hasConsented ? userData.username : undefined,
    type: 'function_call',
    commit: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA
      ? process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA.slice(0, 6)
      : 'local',
  };

  const context = {
    ...baseContext,
    messageContext,
    category,
    ...(hasConsented && { sensitiveContext }),
  };

  if (process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN) {
    datadogLogs.logger[level](message, context);
  }

  if (process.env.NEXT_PUBLIC_LOG_DISABLE_CONSOLE !== '1') {
    console[level](message, context);
  }
}
