import { PaymentIntent, SetupIntent, StripeError } from '@stripe/stripe-js';
import { log } from '@core/logging/logging';
import { IS_NEW_ASYNC_PAYMENT_FLOW_ENABLED } from '@features/cart-and-checkout/constants/checkout.constants';
import { ENV_REGION } from '@utils/constants/region.constants';
import { DASH_ENV } from '@utils/environment';
import { SiteRegionURLsType } from '@utils/types/url.types';
import { PaymentIntentType } from '@/data/graphql/types';
import { PAYMENT_ERRORS, PaymentError } from '../errors';
import { STRIPE_AFFIRM_MINIMUM_THRESHOLD } from '../store/stripe.constants';

export type IntentMetadata = {
  preferredClinicId?: string;
  preferredClinicName?: string;
  [key: string]: string | undefined;
};

export type ParsedSetupIntent = SetupIntent & { metadata?: IntentMetadata };
export type ParsedPaymentIntent = PaymentIntent & { metadata?: IntentMetadata };

type IntentRecord = {
  setup_intent: ParsedSetupIntent;
  payment_intent: ParsedPaymentIntent;
};

export function parseIntentString<TIntent extends keyof IntentRecord>(
  intent?: string
): IntentRecord[TIntent] | null {
  try {
    if (!intent) {
      throw new Error('intent is undefined');
    }
    // message is parsed, extract the intent
    return JSON.parse(intent);
  } catch (error) {
    // message could not be parsed
    log({
      message: 'stripe_intent_parse_error',
      category: 'payments',
      messageContext: {
        error,
      },
      level: 'error',
    });
    throw new PaymentError({
      name: PAYMENT_ERRORS.PARSE_INTENT_STRING_ERROR,
      message: 'could_not_parse_intent_string',
      messageContext: {
        error,
      },
    });
  }
}

export function shouldDisplayStripeError(error: StripeError) {
  if (error.type === 'card_error' || error.type === 'validation_error') {
    return true;
  }

  if (error.code === 'payment_intent_authentication_failure') {
    return true;
  }

  return false;
}

export function stripClientSecret<TIntent extends PaymentIntent | SetupIntent>(
  intent: TIntent | null
): Omit<TIntent, 'client_secret'> | null {
  if (!intent) return null;

  const { client_secret, ...rest } = intent;

  return rest;
}

export function getCountryFromRegion(region: SiteRegionURLsType) {
  switch (region) {
    case 'roi':
      return 'IE';
    case 'uk':
      return 'GB';
    case 'us':
      return 'US';
    default:
      log({
        category: 'payments',
        message: 'stripe_country_and_env_region_not_found',
        messageContext: {
          region,
        },
        level: 'error',
      });
      if (DASH_ENV !== 'production')
        throw new Error(`Need to assign a Stripe Country to for '${region}' region`);
      return '';
  }
}

export const getHasAffirmPaymentMethod = (amount: number) =>
  IS_NEW_ASYNC_PAYMENT_FLOW_ENABLED
    ? amount >= STRIPE_AFFIRM_MINIMUM_THRESHOLD && ENV_REGION === 'us'
    : false;

export function getIntentPaymentMethodTypes(intentType: PaymentIntentType, amount: number) {
  const hasAffirm = getHasAffirmPaymentMethod(amount);

  const paymentMethodTypes = ['card'];

  switch (intentType) {
    case PaymentIntentType.PaymentIntent:
      if (hasAffirm) {
        paymentMethodTypes.push('affirm');
      }
  }

  return paymentMethodTypes;
}
