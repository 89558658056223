import { useCallback, useState } from 'react';
import { useRouteChange } from '@hooks/useRouteChange/useRouteChange';

export const useCartState = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenCartSidebar = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleCloseCartSidebar = useCallback(() => setIsOpen(false), []);

  useRouteChange({ onRouteChangeComplete: handleCloseCartSidebar });

  return {
    isOpen,
    handleOpenCartSidebar,
    handleCloseCartSidebar,
  };
};
