import { LinkProps } from 'next/link';

export function separateLinkProps<TProps extends LinkProps>(props: TProps) {
  const { href, as, replace, scroll, shallow, locale, prefetch, passHref = true, ...rest } = props;

  const linkProps = {
    href,
    as,
    replace,
    scroll,
    shallow,
    locale,
    prefetch,
    passHref,
  };

  return {
    linkProps,
    ...rest,
  };
}
