import { buildLinkUrl } from '@utils/functions/links';
import {
  BrandNameValues,
  ProductSkinConcernValues,
  ProductSkinTypeValues,
  ProductTypeValues,
} from './ProductsWithFilterPage.types';

export const getBrandLink = (brandName: BrandNameValues) => {
  return buildLinkUrl('PRODUCTS_BY_BRAND', {
    brandName,
  });
};

export const getProductTypeLink = (productType: ProductTypeValues) => {
  return buildLinkUrl('PRODUCTS_BY_TYPE', {
    productType,
  });
};

export const getProductSkinTypeLink = (skinType: ProductSkinTypeValues) => {
  return buildLinkUrl('PRODUCTS_BY_SKIN_TYPE', {
    skinType,
  });
};

export const getProductSkinConcernLink = (skinConcern: ProductSkinConcernValues) => {
  return buildLinkUrl('PRODUCTS_BY_SKIN_CONCERN', {
    skinConcern,
  });
};
