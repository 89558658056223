export const getFormattedPrice = (price: number) => {
  const formatter = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: process.env.NEXT_PUBLIC_DEFAULT_CURRENCY,
  });

  const formattedPriceInfo = formatter.formatToParts(price).reduce(
    (acc, p) => {
      let { fullPrice, noCurrencyPrice, currencySymbol, integerPrice } = acc;

      if (p.type === 'currency') {
        currencySymbol += p.value;
        integerPrice += p.value;
      } else {
        noCurrencyPrice += p.value;
        if (p.type === 'integer') {
          integerPrice += p.value;
        }
      }
      fullPrice += p.value;

      return { fullPrice, noCurrencyPrice, currencySymbol, integerPrice };
    },
    { fullPrice: '', noCurrencyPrice: '', currencySymbol: '', integerPrice: '' }
  );

  return formattedPriceInfo;
};
