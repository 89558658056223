import { cx, Text } from '@therapie-ecommerce-ui/ui';

import { Price } from '@components/price/Price';

import { CartItemPriceProps } from './CartItemPrice.types';

import styles from './cart-item-price.module.scss';

export const CartItemPrice = ({
  className,
  textProps,
  price,
  isRemoved,
  outOfStock,
}: CartItemPriceProps) => {
  if (isRemoved || outOfStock) return null;

  return (
    <Text
      className={cx(styles['item-price'], className)}
      variant="h2"
      weight="semi-bold"
      {...textProps}
    >
      <Price>{price}</Price>
    </Text>
  );
};
