import { useIsFetching, useQuery } from 'react-query';
import { useAuthenticationStore } from '@therapie-ecommerce-ui/auth';
import { graphqlGatewayClient } from '@core/graphql/gateway/gateway';
import { generateAuthHeaders } from '@features/authentication/headers';
import { checkoutActionsState } from '@features/cart-and-checkout/store/checkout/checkout.store';
import { WEB_VIEW_STALE_TIME_MS } from '@features/web-view/constants/queries';
import { useIsWebView } from '@features/web-view/hooks/useIsWebView/useIsWebView';
import {
  GetUserLoyaltyPointsQuery,
  GetUserLoyaltyPointsQueryVariables,
} from '@/data/graphql/types';

export const GET_USER_LOYALTY_POINTS_KEY = 'GET_USER_LOYALTY_POINTS';

export const useGetUserLoyaltyPoints = () => {
  const isUserAuthenticated = useAuthenticationStore((s) => s.isUserAuthenticated);
  const { isWebView } = useIsWebView();

  return useQuery(
    GET_USER_LOYALTY_POINTS_KEY,
    async () => {
      const { getUserLoyaltyPoints: loyaltyPoints } = await graphqlGatewayClient.post<
        GetUserLoyaltyPointsQueryVariables,
        GetUserLoyaltyPointsQuery
      >('/GetUserLoyaltyPoints', undefined, { headers: generateAuthHeaders() });
      checkoutActionsState.setLoyaltyPoints(loyaltyPoints ?? 0);
      return loyaltyPoints;
    },
    {
      enabled: isUserAuthenticated,
      refetchOnWindowFocus: isWebView,
      staleTime: isWebView ? WEB_VIEW_STALE_TIME_MS : Infinity,
    }
  );
};

export const useIsGetUserLoyaltyPointsLoading = () => !!useIsFetching(GET_USER_LOYALTY_POINTS_KEY);
