import { BasicLayout } from '@components/layout/basic-layout/BasicLayout';
import { useIsWebView } from '@features/web-view/hooks/useIsWebView/useIsWebView';
import styles from '@components/layout/basic-layout/basic-layout.module.scss';

const DefaultKill = () => {
  const { isWebView } = useIsWebView();

  return (
    <BasicLayout
      hasHeader={!isWebView}
      headerClassName={styles['layout__header']}
      contentClassName={styles['layout__content']}
    >
      <div style={{ padding: 20 }}>
        <div style={{ background: '#ececec', padding: 20 }}>
          <p style={{ fontWeight: 'bold', fontSize: 20, marginBottom: 28 }}>Upgrade available</p>
          <p style={{ marginBottom: 28 }}>
            <span style={{ fontWeight: 'bold' }}>Attention Apple iOS users!</span>
            <br />
            The new version of our iOS app is here and we&apos;re excited for you to try it! Upgrade
            now to enjoy the latest features. The current version is no longer supported, so update
            today to stay connected!{' '}
            <a
              href="https://apps.apple.com/us/app/therapie-clinic/id1449019058"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none', color: '#7472B2' }}
            >
              <span style={{ fontWeight: 'bold', fontSize: 16, textDecoration: 'underline' }}>
                Download now from App Store
              </span>
            </a>
            .
          </p>

          <p style={{ marginBottom: 28 }}>
            <span style={{ fontWeight: 'bolder' }}>Good news for Android users!</span>
            <br />A brand-new Android app is on its way, and we can&apos;t wait to share it with
            you. In the meantime, you can continue to access all our features on our website at{' '}
            <a
              href="https://therapieclinic.com"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none', color: '#7472B2' }}
            >
              <span style={{ fontWeight: 'bold', fontSize: 16, textDecoration: 'underline' }}>
                therapieclinic.com
              </span>
            </a>
            .
          </p>

          <p style={{ fontStyle: 'italic' }}>Thank you for your patience!</p>
        </div>
      </div>
    </BasicLayout>
  );
};

export default DefaultKill;
