// https://w3c.github.io/webcrypto/#Crypto-method-randomUUID
export const generateUUIDv4 = () => {
  const RFC4122_TEMPLATE = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
  const replacePlaceholders = (placeholder: string) => {
    const randomNibble = generateRandomNumberBetween(0, 15);
    const value = placeholder === 'x' ? randomNibble : (randomNibble & 0x3) | 0x8;
    return value.toString(16);
  };
  return RFC4122_TEMPLATE.replace(/[xy]/g, replacePlaceholders);
};

const generateRandomNumberBetween = (start: number, end: number) => {
  return Math.floor(Math.random() * (end - start + 1) + start);
};
