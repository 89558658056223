import { Title } from '@therapie-ecommerce-ui/ui';
import { useTranslation } from 'next-i18next';

import styles from './cart-empty-state.module.scss';

export const CartEmptyState = () => {
  const { t } = useTranslation('cart');

  return (
    <Title className={styles['empty-state-text']} variant="h2" tag="p" weight="semi-bold">
      {t('yourCartIsEmpty')}
    </Title>
  );
};
