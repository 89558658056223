import { BreakpointType, GetTargetBreakpointsProps } from './ResponsiveSSR.types';

export const getTargetBreakpoints = ({
  only,
  up,
  down,
}: GetTargetBreakpointsProps): Array<BreakpointType> => {
  if (only) {
    return [only];
  }

  if (down === 'sm') {
    return ['sm'];
  }
  if (down === 'md') {
    return ['sm', 'md'];
  }
  if (down === 'lg') {
    return ['sm', 'md', 'lg'];
  }
  if (down === 'xl') {
    return ['sm', 'md', 'lg', 'xl'];
  }
  if (down === 'xxl') {
    return ['sm', 'md', 'lg', 'xl', 'xxl'];
  }

  if (up === 'sm') {
    return ['sm', 'md', 'lg', 'xl', 'xxl'];
  }
  if (up === 'md') {
    return ['md', 'lg', 'xl', 'xxl'];
  }
  if (up === 'lg') {
    return ['lg', 'xl', 'xxl'];
  }
  if (up === 'xl') {
    return ['xl', 'xxl'];
  }
  if (up === 'xxl') {
    return ['xxl'];
  }

  return [];
};
