import React, { useRef } from 'react';

import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import { Modal, Title, useIsMounted } from '@therapie-ecommerce-ui/ui';

import { CartItemType } from '@/data/graphql/types';
import { CheckoutButton } from '@features/cart-and-checkout/components/checkout-button/CheckoutButton';
import { useProgressiveImage } from '@hooks/useProgressiveImage/useProgressiveImage';
import { Price } from '@components/price/Price';
import { useProductName } from '@features/product-catalog/hooks/useProductName/useProductName';

import {
  ModalImageProps,
  ModalBodyProps,
  CartAddedItemModalProps,
} from './CartAddedItemModal.types';
import { ContinueShoppingButton } from '../../continue-shopping-button/ContinueShoppingButton';

import styles from './cart-added-item-modal.module.scss';

export const CartAddedItemModal = ({
  isOpen,
  onClose,
  onExited,
  item,
  totalCartItems,
  cartSubtotal,
}: CartAddedItemModalProps) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const { t } = useTranslation('cart');

  return (
    <Modal
      closeButtonProps={{
        'aria-label': t('continueShopping'),
        size: 'lg',
        iconProps: {
          size: 'lg',
        },
        className: styles['modal__close-button'],
      }}
      isOpen={isOpen}
      onClose={onClose}
      onExited={onExited}
      initialFocusRef={buttonRef}
      containerClassName={styles['modal']}
      dialogClassName={styles['modal__dialog']}
      headerClassName={styles['modal__header']}
      bodyClassName={styles['modal__body']}
      headerContent={<ModalHeaderContent />}
      bodyContent={
        <ModalBodyContent
          buttonRef={buttonRef}
          onClose={onClose}
          totalCartItems={totalCartItems}
          cartSubtotal={cartSubtotal}
          item={item}
        />
      }
    />
  );
};

const ModalHeaderContent = () => {
  const { t } = useTranslation('cart');

  return (
    <Title variant={{ sm: 'h3', md: 'h1' }} tag="h3" weight="bold">
      {t('addedToYourCart')}
    </Title>
  );
};

const ModalBodyContent = ({
  buttonRef,
  item,
  totalCartItems,
  cartSubtotal,
  onClose,
}: ModalBodyProps) => {
  const isMounted = useIsMounted();
  const { t } = useTranslation('cart');

  const isTreatment = item?.type === CartItemType.Treatment;

  const name = useProductName({ type: item?.type!, name: item?.name! });

  return (
    <>
      {isMounted() && <ModalImage alt={item?.name} images={item?.images} />}
      <div className={styles['modal__details']}>
        <div>
          {isTreatment && item.treatmentGroup && (
            <Title variant="h3" tag="p" weight="light">
              {item.treatmentGroup}
            </Title>
          )}
          <Title variant="h3" tag="p" weight="semi-bold">
            {name}
          </Title>
        </div>
        <div>
          <Title variant="h1" tag="p" weight="light">
            <Price>{item?.price!}</Price>
            {!isTreatment && ` x ${item.quantity}`}
          </Title>
        </div>
        <div>
          <Title variant="h2" tag="p" weight="light">
            {t('cartSubtotal')}:{' '}
            <Title variant="h2" weight="bold" tag="span">
              <Price>{cartSubtotal}</Price>
            </Title>
          </Title>
          <Title variant="h3" tag="p" weight="light">
            ({t('itemsInCart', { count: totalCartItems })})
          </Title>
        </div>
        <CheckoutButton ref={buttonRef} />
        <ContinueShoppingButton onClick={onClose} />
      </div>
    </>
  );
};

const ModalImage = ({ alt, images }: ModalImageProps) => {
  const { src, style } = useProgressiveImage({
    lowQualitySrc: images?.thumbnail ?? '',
    highQualitySrc: images?.original ?? '',
  });

  return (
    <div className={styles['modal__image']} style={style}>
      <Image src={src} layout="fill" objectFit="contain" alt={alt} />
    </div>
  );
};
