import { addMinutes, differenceInHours } from 'date-fns';
import { getFormattedDate, getSiteDate } from '@utils/functions/dates';
import { buildLinkUrl } from '@utils/functions/links';
import { validateLocale } from '@utils/locale';
import marketingConfig from '@utils/marketing/config.json';
import { BookingHistory, BookingHistoryItem, CartItemType } from '@/data/graphql/types';
import { GetIsImmutableAppointmentType } from '../hooks/useFindImmutableAppointment/useFindImmutableAppointment.types';
import { BookingWithAppointment } from '../hooks/useFlattenBookingsWithAppointments/useFlattenBookingsWithAppointments.types';
import { BOOKING_CANCELLATION_PERIOD_HOURS } from './booking.constants';
import {
  BookingHistoryParamType,
  FreeConsultationTreatmentFields,
  NextSessionsByServiceReturnType,
  SplitUpcomingAppointmentsReturnType,
} from './booking.types';

export const formatAppointmentSchedule = (appointment: BookingHistoryItem) => {
  const endTime = addMinutes(Date.parse(appointment?.time!), appointment.duration || 0); // if duration is null or undefined, it throws an error

  return `${getFormattedDate({
    date: appointment?.time!,
    format: 'HOURS_MINUTES',
  })} - ${getFormattedDate({ date: endTime, format: 'HOURS_MINUTES' })}`;
};

export const removeCancelledAppointments = (bookings: BookingHistoryParamType = []) => {
  if (!bookings.length) return [];

  return bookings.reduce((acc, booking) => {
    const newBooking = {
      ...booking,

      bookings: booking.bookings?.filter((appointment) => !appointment?.cancelled) ?? [],
    };

    if (newBooking.bookings.length > 0) {
      return [...acc, newBooking];
    }

    return acc;
  }, [] as BookingHistory[]);
};

export const splitUpcomingAppointments = (bookings: BookingHistoryParamType = []) => {
  const { previousBookings = [], upcomingBookings = [] } =
    {} as SplitUpcomingAppointmentsReturnType;

  if (bookings.length) {
    const currentTime = new Date().getTime();

    bookings.forEach((booking) => {
      if (!booking.bookings) {
        return;
      }
      const { previousAppointments, upcomingAppointments } = booking.bookings.reduce(
        (acc, appointment) => {
          const appointmentTime = new Date(appointment.time!).getTime();

          if (appointmentTime > currentTime) {
            return { ...acc, upcomingAppointments: [...acc.upcomingAppointments, appointment] };
          } else {
            return { ...acc, previousAppointments: [...acc.previousAppointments, appointment] };
          }
        },
        {
          previousAppointments: [] as BookingHistoryItem[],
          upcomingAppointments: [] as BookingHistoryItem[],
        }
      );

      if (previousAppointments.length) {
        previousBookings.push({ ...booking, bookings: previousAppointments });
      }
      if (upcomingAppointments.length) {
        upcomingBookings.push({ ...booking, bookings: upcomingAppointments });
      }
    });
  }

  return { previousBookings, upcomingBookings };
};

export const getNextSessionsByService = (userBookings: BookingHistoryParamType = []) => {
  const nextSessionsByService = {} as NextSessionsByServiceReturnType;

  userBookings.forEach(({ bookings, ...booking }) => {
    bookings?.forEach((appointment) => {
      const { serviceId } = appointment;
      // If the service isn't logged or the date is higher, add that service
      if (
        !nextSessionsByService[serviceId!] ||
        nextSessionsByService[serviceId!].appointment.time! > appointment.time!
      ) {
        nextSessionsByService[serviceId!] = { booking, appointment };
      }
    });
  });

  return nextSessionsByService;
};

export const buildLinkUrlForCartItem = (itemType: CartItemType, slug?: string) => {
  return buildLinkUrl(itemType === 'VOUCHER' ? 'GIFT_VOUCHERS' : itemType, { slug });
};

export const getHoursDifferenceForAppointment = (time: string) => {
  const timeNow = getSiteDate(new Date());
  const hoursDifference = differenceInHours(getSiteDate(time), timeNow);

  return hoursDifference;
};

export const getBookingConditions = (time: BookingHistoryItem['time']) => {
  let isPastBooking = false;
  let isWithinCancellationPeriod = false;
  if (time) {
    const hoursDifference = getHoursDifferenceForAppointment(time);

    isPastBooking = hoursDifference < 0;
    isWithinCancellationPeriod = hoursDifference < BOOKING_CANCELLATION_PERIOD_HOURS;
  }

  return {
    isPastBooking,
    isWithinCancellationPeriod,
  };
};

export const findImmutableBookingHistoryItem = (
  appointments: BookingHistoryItem[],
  getIsImmutableAppointment: GetIsImmutableAppointmentType
) =>
  appointments.find((appt) => {
    const { isPastBooking, isWithinCancellationPeriod } = getBookingConditions(appt.time!);

    return isPastBooking || (isWithinCancellationPeriod && getIsImmutableAppointment(appt));
  });

export const findImmutableAppointment = (
  appointments: BookingWithAppointment[],
  getIsImmutableAppointment: GetIsImmutableAppointmentType
) =>
  findImmutableBookingHistoryItem(
    appointments.map((appt) => appt.appointment),
    getIsImmutableAppointment
  );

export const checkAppointmentsContainsDuplicateTreatments = (
  appointments: BookingHistoryItem[]
) => {
  const treatmentIds = appointments.map((appointment) => appointment.treatmentId);
  const treatmentIdsDeduplicated = [...new Set(treatmentIds)];
  return treatmentIds.length !== treatmentIdsDeduplicated.length;
};

export const getTreatmentHasConsultationCategory = (treatment: FreeConsultationTreatmentFields) => {
  return !!treatment?.category?.toLowerCase()?.includes('consultation');
};

export const getIsMarketingFreeConsultation = (
  treatment: FreeConsultationTreatmentFields,
  locale: string
) => {
  const hasConsultationCategory = getTreatmentHasConsultationCategory(treatment);

  if (!hasConsultationCategory) return false;

  if (validateLocale(locale)) {
    return marketingConfig[locale]['free-consultations'].some(
      (consultationId) => consultationId === treatment?.id
    );
  }

  return false;
};

/**
 * Checks if the treatmentId matches the ecommerce ID pattern.
 * Example: 0f9fe65c-46bf-3b29-8d9a-860618ba1b3d
 * Logic is asserting if there are 5 group of strings divided by 4 slashes (`-`)
 * @param treatmentId
 * @returns boolean
 */
export const getIsEcommId = (treatmentId: string | undefined | null) => {
  if (!treatmentId) return false;

  return treatmentId.split('-').length >= 5;
};
