import { useEffect, useState, useRef } from 'react';

import { useDebounce } from '@therapie-ecommerce-ui/ui';

import { useImage } from '@hooks/useImage/useImage';
import { useChangeItemQuantityMutation } from '@features/cart-and-checkout/api/hooks/useChangeItemQuantityMutation';
import { useRemoveFromCartMutation } from '@features/cart-and-checkout/api/hooks/useRemoveFromCartMutation';
import { getItemHref } from '@features/cart-and-checkout/components/cart-items/cart-item/hooks/useCartItem.utils';
import { useCartOperations } from '@features/cart-and-checkout/hooks/useCartOperations';
import { CartItemType } from '@/data/graphql/types';
import { EnrichedCartItemType } from '@features/cart-and-checkout/store/cart.types';
import { clearRemovedItemsCartState } from '@features/cart-and-checkout/store/cart.store';
import { useSegmentAnalytics } from '@hooks/useSegmentAnalytics/useSegmentAnalytics';

/**
 * On the CartPage when the CartSidebar is open and and you try to increment/decrement/remove an item via the CartSidebar,
 * it will cause an infinite loop of the action.
 *
 * It seems to be caused by the useEffects in this hook (CartPage + CartSidebar being open means there is two instances of this hook).
 *
 * The solution is to disable the CartSidebar on the CartPage.
 */
export const useCartItem = (item: EnrichedCartItemType) => {
  const {
    itemId,
    name,
    quantity,
    type,
    slug,
    itemCost,
    images,
    totalCost = quantity * itemCost,
    removedQuantity,
    price,
    crmId,
    treatmentCollection,
    treatmentType,
  } = item;

  const { sendTrackEvent } = useSegmentAnalytics();
  const [localQuantity, setLocalQuantity] = useState(quantity);
  const debouncedQuantity = useDebounce(localQuantity, 300);
  const prevDebouncedQuantityRef = useRef(debouncedQuantity);
  const quantityRef = useRef(quantity);

  useEffect(() => {
    setLocalQuantity(quantity);
    quantityRef.current = quantity;
  }, [quantity]);

  const { handleChangeItemQuantityMutation } = useChangeItemQuantityMutation();
  const { handleRemoveFromCartMutation } = useRemoveFromCartMutation();

  const changingItemIds = useCartOperations('change_item_quantity');
  const removingItemIds = useCartOperations('remove_item_from_cart');

  const isLoading = changingItemIds.includes(itemId) || removingItemIds.includes(itemId);

  const isRemoved = quantity === 0;

  const { hasImg, src: imgSrc } = useImage({ imageSrc: images?.thumbnail });

  const itemHref = getItemHref(type as CartItemType, slug ?? '');

  useEffect(() => {
    const quantityAfterDebounce = debouncedQuantity - quantityRef.current;

    if (quantityAfterDebounce === 0) return;

    // This could be, for example, 5 + 5 = 10 if incremented 5 times, or 5 + -5 = 0 if decremented 5 times.
    const isBeingRemovedInstead = quantityRef.current + quantityAfterDebounce === 0;

    if (isBeingRemovedInstead) return;

    handleChangeItemQuantityMutation({
      itemId,
      quantity: quantityAfterDebounce,
      type,
    });
  }, [debouncedQuantity, handleChangeItemQuantityMutation, itemId, type]);

  useEffect(() => {
    const shouldRemoveDecrementedItem = localQuantity === 0 && quantityRef.current !== 0;

    if (shouldRemoveDecrementedItem) {
      handleRemoveFromCartMutation({ itemId, type, quantity: quantityRef.current });
    }
  }, [localQuantity, handleRemoveFromCartMutation, itemId, type]);

  const handleIncrementQuantity = () => {
    if (isLoading) return;
    setLocalQuantity((prevLocalQuantity) => prevLocalQuantity + 1);
  };

  const handleDecrementQuantity = () => {
    if (isLoading) return;
    setLocalQuantity((prevLocalQuantity) => prevLocalQuantity - 1);
  };

  const handleRemoveItem = () => {
    if (isLoading) return;
    handleRemoveFromCartMutation({ itemId, quantity, type });
  };

  const handleRestoreRemovedItem = () => {
    if (isLoading) return;

    handleChangeItemQuantityMutation(
      { itemId, quantity: removedQuantity!, type },
      {
        onSuccess: () => {
          clearRemovedItemsCartState(itemId);
          // Reset to initial state similar to mount
          setLocalQuantity(removedQuantity!);
          prevDebouncedQuantityRef.current = removedQuantity!;
        },
      }
    );
  };

  const handleSendAnalytics = () => {
    sendTrackEvent({
      event: 'Product Clicked',
      properties: {
        productId: itemId,
        crmProductId: crmId!,
        type,
        treatmentType,
        name,
        price,
        quantity,
        url: itemHref,
        category: treatmentCollection,
      },
    });
  };

  return {
    ...item,
    quantity: localQuantity,
    handleIncrementQuantity,
    handleDecrementQuantity,
    handleRemoveItem,
    handleRestoreRemovedItem,
    handleSendAnalytics,
    hasImg,
    imgSrc,
    itemHref,
    isRemoved,
    isLoading,
    totalPrice: totalCost,
    price,
  };
};
