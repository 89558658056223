import React from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { cx } from '@therapie-ecommerce-ui/ui';
import { Container } from '@components/container/Container';
import { Link, LinkButton } from '@components/link/Link';
import { Cart } from '@features/cart-and-checkout/components/cart/Cart';
import { THERAPIE_LOGO_SRC } from '@utils/constants/images.constants';
import { SITE_URLS } from '@utils/constants/site-urls.constants';
import { NavigationDesktop } from '../../navigation/desktop/NavigationDesktop';
import styles from '../header.module.scss';

export const HeaderDesktop = () => {
  const { t } = useTranslation('header');

  return (
    <div className={styles['main-header']}>
      <Container className={cx(styles['main-header__body'], styles['main-header__body--desktop'])}>
        <div className={styles['main-header__col']}>
          <Link href={SITE_URLS.HOME} aria-label={t('logoLinkLabel')} prefetch={false}>
            <Image src={THERAPIE_LOGO_SRC} alt="Thérapie Logo" width="173" height="35" />
          </Link>
        </div>

        <div className={styles['main-header__col']}>
          <NavigationDesktop />
          <Cart />
        </div>

        <div className={styles['main-header__col']}>
          <LinkButton
            href={SITE_URLS.BOOK_YOUR_CONSULTATION}
            variant="filled"
            color="trp-purple" // UI-TODO: color prop doesn't work
            className={`${styles['main-header__button']}`}
          >
            {t('freeConsultation')}
          </LinkButton>
        </div>
      </Container>
    </div>
  );
};
