import { useMemo } from 'react';
import { useCartStore } from '@features/cart-and-checkout/store/cart.store';
import { CartState } from '@features/cart-and-checkout/store/cart.types';
import { CartItemType } from '@/data/graphql/types';
import { useGetEnrichedCartItems } from './useGetEnrichedCartItems/useGetEnrichedCartItems';

const itemsSelector = (state: CartState) => state.items;
const removedItemsSelector = (state: CartState) => state.removedItems;
const subtotalCostSelector = (state: CartState) => state.subtotalCost;

export const useCartItems = () => {
  const items = useCartStore(itemsSelector);
  const removedItems = useCartStore(removedItemsSelector);
  const totalCost = useCartStore(subtotalCostSelector);

  /**
   * Removed items are saved separately from the items list, so we need to merge them
   * based on the index they were removed from.
   */
  const itemsWithMaybeRemoved = [...items];
  for (let i = 0; i < removedItems.length; i++) {
    itemsWithMaybeRemoved.splice(removedItems[i].removedIndex!, 0, removedItems[i]);
  }

  const enrichedCartItems = useGetEnrichedCartItems(itemsWithMaybeRemoved);

  const totalItems = useMemo(
    () => items.reduce((total, item) => total + item.quantity, 0),
    [items]
  );

  const hasProducts = useMemo(
    () => items.some((item) => item.type === CartItemType.Product),
    [items]
  );

  const hasTreatment = useMemo(
    () => !!items.find((item) => item.type === CartItemType.Treatment),
    [items]
  );

  const hasOutOfStockItem = useMemo(
    () => !!enrichedCartItems.find((item) => item.quantity > 0 && item.outOfStock),
    [enrichedCartItems]
  );

  return {
    items: enrichedCartItems,
    hasTreatment,
    hasOutOfStockItem,
    hasProducts,
    totalItems,
    totalCost,
  };
};
