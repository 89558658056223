import { QueryClient } from 'react-query';
import { CartEventsPayloads } from '@therapie-ecommerce-ui/events';
import { graphqlGatewayClient } from '@core/graphql/gateway/gateway';
import { GIFT_VOUCHER_IMAGE } from '@features/product-catalog/constants/gift-vouchers';
import { SITE_URLS } from '@utils/constants/site-urls.constants';
import { buildLinkUrl } from '@utils/functions/links';
import {
  CartItem,
  CartItemType as ItemType,
  EnrichedCartItemGql,
  GetProductsQuery,
  GetProductsQueryVariables,
  GetTreatmentCollectionsQuery,
  GetTreatmentCollectionsQueryVariables,
  GetTreatmentsQuery,
  GetTreatmentsQueryVariables,
  OrderItem,
  Product,
  Treatment,
  TreatmentCollectionCard,
  Voucher,
} from '@/data/graphql/types';
import {
  FormattedItemForCache,
  ProductToFormat,
  TreatmentToFormat,
  VoucherToFormat,
} from './item-enrichment.types';

const ITEM_KEY = {
  base: { name: 'ITEM' },
  id: (id: string) => ({ ...ITEM_KEY.base, id }),
};

/**
 * Given a list of Cart or Order items, query for additional item
 * information and set RQ cache with key `{ name: 'ITEM', id: item.id }`
 */
export const enrichItems = async (
  items: CartItem[] | OrderItem[] | EnrichedCartItemGql[],
  queryClient: QueryClient
) => {
  const productIds: Set<string> = new Set();
  const treatmentIds: Set<string> = new Set();
  const vouchers: (Pick<Voucher, 'id' | 'price' | 'name'> & { quantity: number })[] = [];

  items?.forEach(({ type, itemId, itemCost, quantity }) => {
    if (getItemDataFromQueryCache(itemId, queryClient)) return;

    switch (type) {
      case ItemType.Product:
        productIds.add(itemId);
        break;
      case ItemType.Treatment:
        treatmentIds.add(itemId);
        break;
      case ItemType.Voucher:
        vouchers.push({ id: itemId, price: itemCost, quantity, name: `Voucher ${itemCost}` });
        break;
    }
  });

  const isEmpty = productIds.size === 0 && treatmentIds.size === 0 && vouchers.length === 0;

  if (isEmpty) {
    return;
  }

  let products: Product[] = [];
  let treatments: Treatment[] = [];
  let treatmentCollections: TreatmentCollectionCard[] = [];

  if (productIds.size > 0) {
    products = await getProducts([...productIds]);
  }

  if (treatmentIds.size > 0) {
    treatments = await getTreatments([...treatmentIds]);
    treatmentCollections = await getTreatmentCollections();
  }

  queryClient.setQueryDefaults([ITEM_KEY.base], {
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  products.map((product) => {
    setItemDataInQueryCache({
      item: formatProductData({
        ...product,
        images: {
          original: product.images?.[0]?.original ?? '',
          thumbnail: product.images?.[0]?.thumbnail ?? '',
        },
      }),
      queryClient,
    });
  });

  treatments.map(({ slug, collectionSlug, image, ...restTreatment }) => {
    const treatmentCollection = treatmentCollections?.find((tc) => tc.slug === collectionSlug);
    let treatmentSlug = '';

    if (treatmentCollection) {
      treatmentSlug = buildLinkUrl('TREATMENT_COLLECTIONS_SINGLE_TREATMENT', {
        group: treatmentCollection.treatmentGroup,
        collection: collectionSlug,
        slug,
      });
    } else {
      treatmentSlug = buildLinkUrl('TREATMENT_WP', {
        nuaSlug: restTreatment.nuaSlug,
      });
    }
    setItemDataInQueryCache({
      item: formatTreatmentData({
        ...restTreatment,
        images: {
          original: image?.original ?? '',
          thumbnail: image?.thumbnail ?? '',
        },
        slug: treatmentSlug,
        treatmentGroup: treatmentCollection?.treatmentGroup ?? '',
        treatmentCollection: treatmentCollection?.slug ?? '',
      }),
      queryClient,
    });
  });

  vouchers.map((voucher) => {
    setItemDataInQueryCache({
      item: formatVoucherToCartData(voucher),
      queryClient,
    });
  });
};

const getProducts = async (ids: string[]) => {
  if (ids.length === 0) return [];

  const { getProducts: products } = await graphqlGatewayClient.post<
    GetProductsQueryVariables,
    GetProductsQuery
  >('/GetProducts', {
    ids,
  });
  return products ?? [];
};

const getTreatments = async (ids: string[]) => {
  if (ids.length === 0) return [];

  const { getTreatments: treatments } = await graphqlGatewayClient.post<
    GetTreatmentsQueryVariables,
    GetTreatmentsQuery
  >('/GetTreatments', {
    ids,
  });
  return treatments ?? [];
};

const getTreatmentCollections = async () => {
  const { getTreatmentCollections: collections } = await graphqlGatewayClient.post<
    GetTreatmentCollectionsQueryVariables,
    GetTreatmentCollectionsQuery
  >('/GetTreatmentCollections');
  return collections ?? [];
};

const formatProductData = ({
  name,
  id,
  price,
  images,
  slug,
  crmId,
  outOfStock,
  priceWithTax,
  priceWithoutTax,
}: ProductToFormat): FormattedItemForCache => {
  return {
    crmId: crmId ?? '',
    name: name ?? '',
    itemId: id ?? '',
    price: price ?? 0,
    priceWithTax: priceWithTax ?? 0,
    priceWithoutTax: priceWithoutTax ?? 0,
    images: {
      original: images?.original ?? '',
      thumbnail: images?.thumbnail ?? '',
    },
    slug: slug ?? '',
    type: ItemType.Product,
    outOfStock: outOfStock ?? false,
  };
};

const formatTreatmentData = ({
  title,
  id,
  price,
  images,
  slug,
  crmId,
  treatmentGroup,
  treatmentCollection,
  treatmentType,
  priceWithTax,
  priceWithoutTax,
}: TreatmentToFormat): FormattedItemForCache => {
  return {
    crmId: crmId ?? '',
    images: {
      original: images?.original ?? '',
      thumbnail: images?.thumbnail ?? '',
    },
    name: title ?? '',
    price: price ?? 0,
    priceWithTax: priceWithTax ?? 0,
    priceWithoutTax: priceWithoutTax ?? 0,
    itemId: id ?? '',
    slug: slug ?? '',
    type: ItemType.Treatment,
    treatmentGroup: treatmentGroup ?? undefined,
    treatmentCollection: treatmentCollection ?? undefined,
    treatmentType: treatmentType ?? undefined,
  };
};

const formatVoucherToCartData = ({ id, name, price }: VoucherToFormat): FormattedItemForCache => {
  const _price = price ?? 0;
  return {
    crmId: '',
    images: {
      original: GIFT_VOUCHER_IMAGE.ORIGINAL,
      thumbnail: GIFT_VOUCHER_IMAGE.THUMBNAIL,
    },
    name: name ?? '',
    price: _price,
    priceWithoutTax: _price,
    priceWithTax: _price,
    itemId: id ?? '',
    slug: SITE_URLS.GIFT_VOUCHERS ?? '',
    type: ItemType.Voucher,
  };
};

export const formatAddedItem = (
  item: CartEventsPayloads['CART/ADD-ITEM']
): FormattedItemForCache => {
  switch (item.type) {
    case ItemType.Treatment:
      return formatTreatmentData({
        ...item,
        id: item.itemId,
        title: item.name,
        images: {
          original: item.images?.original ?? '',
          thumbnail: item.images?.thumbnail ?? '',
        },
      });
    case ItemType.Voucher:
      return formatVoucherToCartData({
        ...item,
        id: item.itemId,
      });
    default:
      return formatProductData({
        ...item,
        id: item.itemId,
        images: {
          original: item.images?.original ?? '',
          thumbnail: item.images?.thumbnail ?? '',
        },
      });
  }
};

/**
 * Manually set RQ cache data for an item with key `{ name: 'ITEM', id: item.id }`
 */
export const setItemDataInQueryCache = ({
  item,
  queryClient,
}: {
  item: FormattedItemForCache;
  queryClient: QueryClient;
}) => {
  return queryClient.setQueryData<FormattedItemForCache>([ITEM_KEY.id(item.itemId)], {
    ...item,
  });
};

/**
 * Get RQ cached data for an item with key `{ name: 'ITEM', id: item.id }`
 */
export const getItemDataFromQueryCache = (id: string, queryClient: QueryClient) => {
  return queryClient.getQueryData<FormattedItemForCache>([{ ...ITEM_KEY.id(id) }]);
};
