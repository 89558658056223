import { cx, useIsMounted } from '@therapie-ecommerce-ui/ui';

import { useDevice } from '@hooks/useDevice/useDevice';

import { ResponsiveSSRProps } from './ResponsiveSSR.types';
import { getTargetBreakpoints } from './ResponsiveSSR.utils';

import styles from './responsive-ssr.module.scss';

// UI-TODO: add a prop for atTablet
export const ResponsiveSSR = ({
  atMobile,
  atDesktop,
  only,
  up,
  down,
  children,
}: ResponsiveSSRProps) => {
  const isMounted = useIsMounted();
  const { active } = useDevice();

  if (atMobile) {
    down = 'md';
  } else if (atDesktop) {
    up = 'lg';
  }

  const classes = cx({
    [styles[`responsive__only--${only}`]]: only,
    [styles[`responsive__up--${up}`]]: up,
    [styles[`responsive__down--${down}`]]: down,
  });

  const targetBreakpoints = getTargetBreakpoints({ only, up, down });

  // after hydration and component has mounted, return children for matching breakpoint, otherwise return nothing (unmount)
  if (isMounted()) {
    if (targetBreakpoints.includes(active)) {
      return <>{children}</>;
    }
    return null;
  }

  // for SSR
  return <div className={classes}>{children}</div>;
};
