import React from 'react';

import { useDevice } from '@hooks/useDevice/useDevice';

import { useCartAddedItem } from './hooks/useCartAddedItem';
import { CartAddedItemModal } from './modal/CartAddedItemModal';
import { CartAddedItemDrawer } from './drawer/CartAddedItemDrawer';

export const CartAddedItem = () => {
  const { isMobile } = useDevice();
  const cartAddedItemProps = useCartAddedItem({ isMobile });

  const Component = isMobile ? CartAddedItemDrawer : CartAddedItemModal;

  return <Component {...cartAddedItemProps} />;
};
