import { useQuery } from 'react-query';
import { useAuthenticationStore } from '@therapie-ecommerce-ui/auth';
import { graphqlGatewayClient } from '@core/graphql/gateway/gateway';
import { log } from '@core/logging/logging';
import { generateAuthHeaders } from '@features/authentication/headers';
import { PAYMENT_ERRORS } from '@features/stripe/errors';
import { WEB_VIEW_STALE_TIME_MS } from '@features/web-view/constants/queries';
import { useIsWebView } from '@features/web-view/hooks/useIsWebView/useIsWebView';
import {
  GetUserSavedPaymentMethodsQuery,
  GetUserSavedPaymentMethodsQueryVariables,
} from '@/data/graphql/types';

export const GET_USER_SAVED_PAYMENT_METHODS_KEY = 'GET_USER_SAVED_PAYMENT_METHODS';

export const useGetUserSavedPaymentMethodsQuery = ({
  isEnabled = true,
}: {
  isEnabled?: boolean;
} = {}) => {
  const isUserAuthenticated = useAuthenticationStore((s) => s.isUserAuthenticated);
  const { isWebView } = useIsWebView();

  return useQuery(
    GET_USER_SAVED_PAYMENT_METHODS_KEY,
    async () => {
      const { getUserSavedPaymentMethods: response } = await graphqlGatewayClient.post<
        GetUserSavedPaymentMethodsQueryVariables,
        GetUserSavedPaymentMethodsQuery
      >('');

      return response;
    },
    {
      enabled: isUserAuthenticated && isEnabled,
      onError: (error) => {
        log({
          level: 'error',
          category: 'query',
          message: PAYMENT_ERRORS.GET_CUSTOMER_CARDS_ERROR,
          messageContext: {
            source: 'useGetUserSavedPaymentMethodsQuery',
            error,
          },
        });
      },
      staleTime: isWebView ? WEB_VIEW_STALE_TIME_MS : Infinity,
      refetchOnWindowFocus: isWebView ? true : false,
    }
  );
};
