import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import {
  AccordionBody,
  AccordionGroup,
  AccordionHeader,
  AccordionIcon,
  AccordionItem,
  useAccordion,
} from '@therapie-ecommerce-ui/ui';
import { FooterSocialLinks } from '../footer-social-links/FooterSocialLinks';
import { FooterCopyright } from '../footer-copyright/FooterCopyright';
import { createDropdownGroups } from './FooterMobile.utils';
import { FooterProps } from '../Footer.types';
import styles from './footer-mobile.module.scss';

/**
 * @todo UI-TODO Replace keys for links with href's when they are unique
 */

export const FooterMobile = ({ groups }: FooterProps) => {
  const { t } = useTranslation('footer');
  const { getBodyProps, getHeaderProps, isActiveItem } = useAccordion({
    canRecloseItems: true,
  });

  const accordionItems = useMemo(() => createDropdownGroups(groups, t), [groups, t]);

  return (
    <footer className={styles['footer']}>
      <div className={styles['footer__wrapper']}>
        <FooterSocialLinks />
        <AccordionGroup hasBorders>
          {accordionItems.map((item) => {
            const isOpen = isActiveItem(item.id);
            return (
              <AccordionItem className={styles['footer__accordion-item']} key={item.id}>
                <AccordionHeader
                  aria-current={isOpen}
                  className={styles['footer__accordion-header']}
                  {...getHeaderProps(item.id)}
                >
                  {item.heading(isOpen)}
                  <AccordionIcon
                    iconProps={{
                      family: 'therapie',
                      name: isOpen ? 'remove' : 'add',
                      color: 'night-200',
                    }}
                    isOpen={isOpen}
                  />
                </AccordionHeader>
                <AccordionBody
                  {...getBodyProps(item.id)}
                  wrapperClassName={styles['footer__accordion-body-wrapper']}
                  isOpen={isOpen}
                >
                  {item.body(isOpen)}
                </AccordionBody>
              </AccordionItem>
            );
          })}
        </AccordionGroup>
      </div>
      <FooterCopyright />
    </footer>
  );
};
