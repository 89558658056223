import React from 'react';
import { useTranslation } from 'next-i18next';
import { Icon } from '@therapie-ecommerce-ui/ui';
import { LinkButton } from '@components/link/Link';
import { SITE_URLS } from '@utils/constants/site-urls.constants';
import styles from './free-consultation-banner.module.scss';
import { useRouter } from 'next/router';

export const FreeConsultationBanner = () => {
  const router = useRouter();
  const currentRoute = router.asPath.split('/');
  const { t } = useTranslation('common');
  return currentRoute[1] === 'products' ? (
    <LinkButton
      size="lg"
      rightIcon={<Icon family="therapie" name="chevron_right" />}
      href={SITE_URLS.GIFT_VOUCHERS}
      className={styles['button']}
      textProps={{
        weight: 'semi-bold',
        color: 'inherit',
        variant: 'h5',
      }}
    >
      Gift Vouchers
    </LinkButton>
  ) : (
    <LinkButton
      size="lg"
      rightIcon={<Icon family="therapie" name="chevron_right" />}
      href={SITE_URLS.BOOK_YOUR_CONSULTATION}
      className={styles['button']}
      textProps={{
        weight: 'semi-bold',
        color: 'inherit',
        variant: 'h5',
      }}
    >
      {t('freeConsultation')}
    </LinkButton>
  );
};
