import { useTranslation } from 'next-i18next';

import { Text, Icon, Button, TextProps, IconButton, cx } from '@therapie-ecommerce-ui/ui';
import { useAuthenticationStore } from '@therapie-ecommerce-ui/auth';
import { useRouter } from 'next/router';

import { Container } from '@components/container/Container';
import { REFER_A_FRIEND_REWARD_PRICE } from '@features/refer-a-friend/constants/rewards.constants';
import { WORDPRESS_URLS, SITE_URLS } from '@utils/constants/site-urls.constants';
import { LoginRegister } from '@features/authentication/components/login-register/LoginRegister';
import {
  NavigationMenu,
  useNavigationMenuItemContext,
} from '@components/navigation-menu/NavigationMenu';
import { Flag } from '@components/flag/Flag';
import { LinkText } from '@components/link/Link';

import { RegionMenu } from './service-submenu/region-menu/RegionMenu';
import { MyAccountMenu } from './service-submenu/my-account-menu/MyAccountMenu';
import { ServiceBarButtonProps, ServiceBarLinkProps } from './ServiceBar.types';

import styles from './service-bar.module.scss';

const textProps = {
  variant: 'h5',
  color: 'inherit',
  hoverColor: 'inherit',
  isUnderlined: false,
  weight: 'regular',
} as TextProps;

export const ServiceBar = () => {
  const { t } = useTranslation('common');
  const isUserAuthenticated = useAuthenticationStore((s) => s.isUserAuthenticated);

  return (
    <div className={styles['service-bar']}>
      <Container className={styles['service-bar__container']}>
        <div className={styles['service-bar__group']}>
          <Button
            tag="a"
            href={WORDPRESS_URLS.HOME}
            variant="link"
            className={styles['service-bar__back-to-main-site']}
            leftIcon={<Icon family="therapie" name="arrow_back" size="sm" />}
            textProps={{ ...textProps, variant: 'body' }}
          >
            {t('backToTherapieWebsite')}
          </Button>
        </div>
        <NavigationMenu className={styles['service-bar__group']}>
          <ServiceBarLink href={SITE_URLS.REFER_A_FRIEND}>
            {t('referAFriend.cta', { amount: REFER_A_FRIEND_REWARD_PRICE })}
          </ServiceBarLink>
          {isUserAuthenticated ? (
            <NavigationMenu.Item
              menuId="my-account"
              className={cx(styles['service-bar__item'], styles['service-bar__auth'])}
            >
              <ServiceBarButton
                text={t('buttons.myAccount')}
                icon={<Icon family="therapie" name="person" size="sm" />}
              />
              <NavigationMenu.Submenu className={styles['service-bar__submenu']}>
                <MyAccountMenu />
              </NavigationMenu.Submenu>
            </NavigationMenu.Item>
          ) : (
            <div className={styles['service-bar__auth']}>
              <LoginRegister iconSize="sm" textProps={textProps} />
            </div>
          )}
          <NavigationMenu.Item menuId="region" className={styles['service-bar__item']}>
            <ServiceBarButton label={t('regions.chooseRegion')} icon={<Flag />} />
            <NavigationMenu.Submenu className={styles['service-bar__submenu']}>
              <Text variant="h7" className={styles['service-bar__submenu-title']}>
                {t('regions.chooseRegion')}
              </Text>
              <RegionMenu />
            </NavigationMenu.Submenu>
          </NavigationMenu.Item>
        </NavigationMenu>
      </Container>
    </div>
  );
};

const ServiceBarLink = ({ href, children, className }: ServiceBarLinkProps) => {
  const router = useRouter();
  const isActive = href === router.pathname;

  const cn = cx(
    styles['service-bar__link'],
    { [styles['service-bar__link--active']]: isActive },
    className
  );

  return (
    <LinkText textProps={{ variant: 'h5' }} href={href} className={cn}>
      {children}
    </LinkText>
  );
};

const ServiceBarButton = ({ label, text, icon }: ServiceBarButtonProps) => {
  const { isActive, handleToggle, menuItemAtts } = useNavigationMenuItemContext();

  const className = cx(styles['service-bar__button'], {
    [styles['service-bar__button--active']]: isActive,
  });

  if (text) {
    return (
      <Button
        variant="text"
        className={className}
        leftIcon={icon}
        textProps={textProps}
        onClick={handleToggle}
        {...menuItemAtts}
      >
        {text}
      </Button>
    );
  }

  return (
    <IconButton className={className} aria-label={label!} onClick={handleToggle} {...menuItemAtts}>
      {icon}
    </IconButton>
  );
};
