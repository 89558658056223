import React from 'react';
import { TFunction } from 'next-i18next';
import { Text } from '@therapie-ecommerce-ui/ui';
import { FooterGroupWithLinks } from '../Footer.types';
import styles from './footer-mobile.module.scss';

export const createDropdownGroups = (groups: FooterGroupWithLinks[], t: TFunction) =>
  groups.map((group) => ({
    id: group.title,
    heading: (_: boolean) => (
      <Text weight="bold" color="night-200">
        {t(`navigation.${group.title}.title`) as string}
      </Text>
    ),
    body: (isOpen: boolean) => (
      <nav aria-hidden={!isOpen} aria-labelledby={group.title}>
        <ul className={styles['footer__accordion-list']}>
          {group.links.map((link, index) => (
            <Text
              tag="li"
              weight="regular"
              key={index}
              className={styles['footer__accordion-list-item']}
            >
              {React.cloneElement(link, {
                tabIndex: isOpen ? 0 : -1,
              })}
            </Text>
          ))}
        </ul>
      </nav>
    ),
  }));
