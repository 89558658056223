import { useTranslation } from 'next-i18next';

import { Text, cx } from '@therapie-ecommerce-ui/ui';

import { useCartItems } from '@features/cart-and-checkout/hooks/useCartItems';
import { Price } from '@components/price/Price';

import { CartSummaryProps } from './CartSummary.types';

import styles from './cart-summary.module.scss';

export const CartSummary = ({ className, textProps }: CartSummaryProps) => {
  const { t } = useTranslation('cart');
  const { totalCost } = useCartItems();

  return (
    <Text
      variant={{ sm: 'h2', md: 'h1' }}
      weight="regular"
      className={cx(styles['summary'], className)}
      {...textProps}
    >
      {t('cartSubtotal')}:{' '}
      <Text variant={{ sm: 'h2', md: 'h1' }} weight="light" tag="span" {...textProps}>
        <Price>{totalCost}</Price>
      </Text>
    </Text>
  );
};
