import { addHours, millisecondsToHours, parse, startOfDay } from 'date-fns';
import { formatInTimeZone, getTimezoneOffset, utcToZonedTime } from 'date-fns-tz';
import {
  CLIENT_TIMEZONE,
  DATE_FORMATS,
  SITE_LOCALE,
  SITE_TIMEZONE,
} from '@utils/constants/dates.constants';

export const formatDateFns = ({
  date,
  formatStr = 'PP',
  timezone = CLIENT_TIMEZONE,
}: {
  date: Date | number;
  formatStr?: string;
  timezone?: string;
}) =>
  formatInTimeZone(date, timezone, formatStr, {
    locale: SITE_LOCALE,
  });

/**
 *
 * Takes a format and an (optional) Date object or Date string and returns the date in the desired format.
 *
 * If the Date object or string is omitted, it will create a new Date object to be formatted.
 */
export const getFormattedDate = ({
  date,
  format,
  timezone = 'site',
}: {
  date?: string | number | Date | null;
  format: keyof typeof DATE_FORMATS;
  timezone?: 'client' | 'site';
}) => {
  const clientDate = !date ? new Date() : new Date(date);

  const toFormatDate = timezone === 'client' ? clientDate : getSiteDate(clientDate);

  return formatDateFns({
    date: toFormatDate,
    formatStr: DATE_FORMATS[format],
  });
};

export const getSiteDate = (date: string | Date) => {
  const dateInUTC = typeof date === 'string' ? date : date.toISOString();

  return utcToZonedTime(dateInUTC, SITE_TIMEZONE);
};

export function isValidTimeZone(timeZone: unknown) {
  try {
    if (
      typeof timeZone !== 'string' ||
      !Intl ||
      !Intl.DateTimeFormat().resolvedOptions().timeZone
    ) {
      return false;
    }

    // Throws an error if timezone is not valid
    Intl.DateTimeFormat(undefined, { timeZone });
    return true;
  } catch (error) {
    return false;
  }
}

export const transformDateToUtc = (date: Date) => {
  const offsetHours = millisecondsToHours(getTimezoneOffset(CLIENT_TIMEZONE));
  return addHours(date, offsetHours);
};

/**
 * Takes a date as a string and creates a new Date object with the time
 * matching 00:00:00 in the tenants timezone for that same date.
 *
 * Example: Client in NYC, tenant at GMT. NYC is at -5, so this will
 * create a Date at 05:00 in NYC time === 00:00 in GMT, so that we can
 * ensure that the actual date doesn't drop into yesterday.

 */

export const newDateTranslatedToSiteTZ = (dateString?: string | null): Date => {
  const date = dateString
    ? parse(dateString, DATE_FORMATS.BACKEND_DATE, new Date())
    : startOfDay(new Date());

  return new Date(startOfDay(date).getTime() + getClientToSiteOffsetDifference());
};

const getClientToSiteOffsetDifference = (): number => {
  const clientTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const sizeTZ = SITE_TIMEZONE;
  const clientOffset = getTimezoneOffset(clientTZ, new Date());
  const sizeOffset = getTimezoneOffset(sizeTZ);
  const offsetDiff = Math.abs(clientOffset - sizeOffset);
  return offsetDiff;
};
