import { useMutation, useQueryClient } from 'react-query';
import { graphqlGatewayClient } from '@core/graphql/gateway/gateway';
import { generateAuthHeaders } from '@features/authentication/headers';
import { buildLinkUrlForCartItem } from '@features/booking/utils/booking.utils';
import { useCartId } from '@features/cart-and-checkout/hooks/useCartId';
import { CART_ACTIONS } from '@features/cart-and-checkout/store/cart.constants';
import {
  updateChangeItemQuantityOperationCartState,
  updateGlobalOperationsCartState,
} from '@features/cart-and-checkout/store/cart.store';
import { ChangeItemQuantityProps } from '@features/cart-and-checkout/store/cart.types';
import { setCartAndCheckoutSteps } from '@features/cart-and-checkout/utils/cart-and-checkout.utils';
import { useErrorToast } from '@hooks/useErrorToast/useErrorToast';
import { useSegmentAnalytics } from '@hooks/useSegmentAnalytics/useSegmentAnalytics';
import { getItemDataFromQueryCache } from '@utils/enrichment/item-enrichment';
import {
  AddCartItemMutation,
  AddCartItemMutationVariables,
  CartItemType,
} from '@/data/graphql/types';

export function useChangeItemQuantityMutation() {
  const cartId = useCartId();
  const errorToast = useErrorToast();
  const queryClient = useQueryClient();
  const { sendTrackEvent } = useSegmentAnalytics();

  const { mutate: handleChangeItemQuantityMutation, ...rest } = useMutation<
    AddCartItemMutation['addCartItem'],
    Error,
    ChangeItemQuantityProps,
    unknown
  >(
    async ({ itemId, quantity, type }) => {
      const payload = {
        cartId: cartId!,
        itemId,
        quantity,
        type: type as CartItemType,
      };

      const { addCartItem: cart } = await graphqlGatewayClient.post<
        AddCartItemMutationVariables,
        AddCartItemMutation
      >('/AddCartItem', payload, { headers: generateAuthHeaders() });

      return cart;
    },
    {
      mutationKey: CART_ACTIONS.ADD_ITEM,
      onSuccess: (cart, { itemId, type, quantity }) => {
        setCartAndCheckoutSteps(cart!);

        const item = getItemDataFromQueryCache(itemId, queryClient);
        sendTrackEvent({
          event: quantity >= 0 ? 'Product Added' : 'Product Removed',
          properties: {
            cartId: cart?.id!,
            productId: itemId,
            crmProductId: item?.crmId!,
            type,
            treatmentType: item?.treatmentType,
            category: item?.treatmentCollection,
            name: item?.name!,
            price: item?.price!,
            quantity: Math.abs(quantity),
            url: buildLinkUrlForCartItem(type, item?.slug),
          },
        });
      },
      onError: () => {
        errorToast();
      },
      onMutate: ({ itemId }) => {
        updateGlobalOperationsCartState(1);
        updateChangeItemQuantityOperationCartState(itemId);
      },
      onSettled: (_, __, { itemId }) => {
        updateGlobalOperationsCartState(-1);
        updateChangeItemQuantityOperationCartState(itemId, { shouldRemove: true });
      },
    }
  );

  return { handleChangeItemQuantityMutation, ...rest };
}
