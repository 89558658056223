import { useRef } from 'react';
import { useRouter } from 'next/router';

export const useIsWebView = () => {
  const router = useRouter();
  const isWebViewRef = useRef(false);

  if (router && router.pathname.includes('/_webview')) {
    isWebViewRef.current = true;
  }

  return { isWebView: isWebViewRef.current };
};
