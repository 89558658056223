import { ErrorBase } from '@utils/errors';

export const CHECKOUT_ERRORS = {
  CHECKOUT_INITIALISE_ERROR: 'CHECKOUT_INITIALISE_ERROR',
  TRANSACTION_FAILED: 'TRANSACTION_FAILED',
  TRANSACTION_ROUNDING_ERROR: 'TRANSACTION_ROUNDING_ERROR',
  EMPTY_PAYMENTS_LIST: 'EMPTY_PAYMENTS_LIST',
} as const;

type CheckoutErrorName = keyof typeof CHECKOUT_ERRORS;

export class CheckoutError extends ErrorBase<CheckoutErrorName> {}

export const CART_ERRORS = {
  CART_ID_NOT_FOUND: 'CART_ID_NOT_FOUND',
} as const;

type CartErrorName = keyof typeof CART_ERRORS;

export class CartError extends ErrorBase<CartErrorName> {}
