import { useIsFetching, useQuery, useQueryClient } from 'react-query';
import { ErrorWithResponse } from '@core/fetch/client.types';
import { graphqlGatewayClient } from '@core/graphql/gateway/gateway';
import { generateAuthHeaders } from '@features/authentication/headers';
import { useCartId } from '@features/cart-and-checkout/hooks/useCartId';
import { updateGlobalOperationsCartState } from '@features/cart-and-checkout/store/cart.store';
import { initialiseCartStore } from '@features/cart-and-checkout/utils/cart.utils';
import { GetCartQuery, GetCartQueryVariables } from '@/data/graphql/types';

export const GET_CART_QUERY_KEY = {
  name: ['GET_CART'],
  query: (cartId: string, forceUpdate: boolean) => [
    ...GET_CART_QUERY_KEY.name,
    cartId,
    forceUpdate,
  ],
};

export function useGetCartQuery({ forceUpdate }: { forceUpdate?: boolean } = {}) {
  const cartId = useCartId();

  const queryClient = useQueryClient();

  return useQuery<GetCartQuery['getCart'], ErrorWithResponse>(
    GET_CART_QUERY_KEY.query(cartId!, !!forceUpdate),
    async () => {
      updateGlobalOperationsCartState(1);
      const { getCart: cart } = await graphqlGatewayClient.post<
        GetCartQueryVariables,
        GetCartQuery
      >(
        '/GetCart',
        {
          id: cartId!,
          forceUpdate,
        },
        { headers: generateAuthHeaders() }
      );

      await initialiseCartStore(cart!, queryClient);
      return cart;
    },
    {
      enabled: !!cartId,
      onSuccess: () => {
        // Remove inactive cart queries
        queryClient.removeQueries(GET_CART_QUERY_KEY.name, {
          inactive: true,
          active: false,
        });
      },
      onSettled: () => {
        updateGlobalOperationsCartState(-1);
      },
    }
  );
}

export const useIsCartLoading = () => !!useIsFetching(GET_CART_QUERY_KEY.name);
