import { UrlObject } from 'url';
import {
  AuthenticationEventsPayloads,
  CartEventsPayloads,
  CheckoutEventsPayloads,
} from '@therapie-ecommerce-ui/events';
import { log } from '@core/logging/logging';

type CartActions = {
  'CART/UPDATE_CART_ITEM_TOTAL': CartEventsPayloads['CART/UPDATE_CART_ITEM_TOTAL'];
};

type CheckoutActions = {
  'CHECKOUT/TRIGGER_PLATFORM_PAYMENT': CheckoutEventsPayloads['CHECKOUT/TRIGGER_PLATFORM_PAYMENT'];
  'CHECKOUT/GET_PLATFORM_PAYMENT_SUPPORT': CheckoutEventsPayloads['CHECKOUT/GET_PLATFORM_PAYMENT_SUPPORT'];
};

type AuthActions = {
  'AUTHENTICATION/INITIALISED': AuthenticationEventsPayloads['AUTHENTICATION/INITIALISED'];
  'AUTHENTICATION/SIGN_IN': AuthenticationEventsPayloads['AUTHENTICATION/SIGN-IN'];
  'AUTHENTICATION/SIGN_OFF': AuthenticationEventsPayloads['AUTHENTICATION/SIGN-OFF'];
};

type NavigationActions = {
  'NAVIGATION/LINK': {
    href: string | UrlObject;
  };
  // UI-TODO: Customise router
  'NAVIGATION/ROUTER': {};
};

type UtilActions = {
  'UTILS/COPY_TO_CLIPBOARD': string;
  'UTILS/SHARE_VIA_WHATSAPP': {
    message: string;
  };
  'UTILS/SHARE_VIA_EMAIL': {
    subject: string;
    body: string;
  };
};

type MobileAppPostMessagePayload = CartActions &
  AuthActions &
  NavigationActions &
  UtilActions &
  CheckoutActions;

type MobileActionKeys = keyof MobileAppPostMessagePayload;

/**
 * Used to communicate with webview to using the ReactNativeWebView method that
 * is attached to the window when the webview is loaded in the mobile repo.
 *
 * `postMessage` expects a string, so messages are stringified before being
 * sent and they can be parsed on the other side.
 *
 */

export function postMessageToMobileApp<TKey extends MobileActionKeys>(
  message: Record<TKey, MobileAppPostMessagePayload[TKey]>
) {
  if (window && window.ReactNativeWebView) {
    if (typeof window.ReactNativeWebView.postMessage !== 'function') {
      return log({
        level: 'warn',
        category: 'app',
        message: 'app_post_message_not_function',
        messageContext: {
          message,
        },
      });
    }

    window.ReactNativeWebView.postMessage(JSON.stringify(message));
  }
}
