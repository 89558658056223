import { isDevEnv } from '@utils/environment';
import { getWPUrlForRegion } from '@utils/functions/region';
import { WORDPRESS_URL } from './region.constants';

export const SITE_URLS = {
  CART: '/cart',
  CHECKOUT: '/checkout',
  CHECKOUT_ORDER: '/checkout/order/[orderId]',
  CHECKOUT_GATEWAY: '/checkout/gateway/[orderId]',
  CHECKOUT_COMPLETE: '/checkout/complete',
  BOOK_YOUR_CONSULTATION: '/book-your-consultation',
  GIFT_VOUCHERS: '/gift-vouchers',
  HOME: '/',
  REGISTRATION: '/register',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  COMPLETE_REGISTRATION_WITH_PASSWORD: '/complete-registration-with-password',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  PRODUCTS: '/products',
  PRODUCT: '/products/[slug]',
  PRODUCTS_BY_BRAND: '/products/brands/[brandName]',
  PRODUCTS_BY_TYPE: '/products/types/[productType]',
  PRODUCTS_BY_SKIN_TYPE: '/products/skin-types/[skinType]',
  PRODUCTS_BY_SKIN_CONCERN: '/products/skin-concerns/[skinConcern]',
  REFER_A_FRIEND: '/refer-a-friend',
  TREATMENTS: '/treatments',
  TREATMENT: '/treatments/[slug]',
  TREATMENT_COLLECTIONS: '/treatments/[group]/[collection]',
  TREATMENT_COLLECTIONS_SINGLE_TREATMENT: '/treatments/[group]/[collection]/[slug]',
  TREATMENT_WP: '/treatments/treatment-wp/[nuaSlug]',
  MY_ACCOUNT: '/my-account',
  MY_ACCOUNT_TREATMENTS: '/my-account/treatments',
  MY_ACCOUNT_TREATMENTS_WEBVIEW: '/my-account/treatments/_webview',
  MY_ACCOUNT_BOOKINGS: '/my-account/bookings',
  MY_ACCOUNT_DETAILS: '/my-account/details',
  MY_ACCOUNT_PERSONAL_DETAILS: '/my-account/details/personal-details',
  MY_ACCOUNT_ORDER_HISTORY: '/my-account/details/order-history',
  MY_ACCOUNT_REGISTRATION_SUCCESS: '/my-account/registration-success',
  MY_ACCOUNT_REGISTRATION_SUCCESS_WEBVIEW: '/my-account/registration-success/_webview',
  BOOKING: '/booking',
  BOOKING_CREATE_POST_PURCHASE: '/booking/create/[treatmentGroup]',
  BOOKING_CREATE_PRE_PURCHASE: '/booking/create/pre-purchase/[treatmentId]',
  BOOKING_CREATE_FREE_CONSULTATION: '/booking/create/free-consultation/[slug]',
  BOOKING_CANCEL: '/booking/cancel',
  BOOKING_CANCEL_APP: '/booking/cancel/app/[appointmentIds]',
  BOOKING_EDIT: '/booking/edit',
  BOOKING_EDIT_APP: '/booking/edit/app/[appointmentIds]',
  PRIVACY_CENTER: '/privacy-centre',
} as const;

export const SITE_URL_VALUES = Object.values(SITE_URLS);

export const SITE_REGION_URLS = {
  roi: isDevEnv()
    ? '/en-IE'
    : `https://${process.env.NEXT_PUBLIC_REGION_DOMAIN_ROI ?? 'ie.shop.therapieclinic.com'}`,
  uk: isDevEnv()
    ? '/en-GB'
    : `https://${process.env.NEXT_PUBLIC_REGION_DOMAIN_UK ?? 'uk.shop.therapieclinic.com'}`,
  us: isDevEnv()
    ? '/en-US'
    : `https://${process.env.NEXT_PUBLIC_REGION_DOMAIN_US ?? 'us.shop.therapieclinic.com'}`,
} as const;

const ROI_WORDPRESS_URL = getWPUrlForRegion('roi');
const UK_WORDPRESS_URL = getWPUrlForRegion('uk');
const US_WORDPRESS_URL = getWPUrlForRegion('us');

export const WORDPRESS_URLS = {
  HOME: WORDPRESS_URL,
  FIND_A_CLINIC: `${WORDPRESS_URL}/our-clinics`,
  HEAD_OFFICE: `${WORDPRESS_URL}/our-clinics`,
  ROI_CLINICS: `${ROI_WORDPRESS_URL}/our-clinics`,
  UK_CLINICS: `${UK_WORDPRESS_URL}/our-clinics`,
  US_CLINICS: `${US_WORDPRESS_URL}/our-clinics`,
  ABOUT_US: `${WORDPRESS_URL}/about-us`,
  CAREERS: `https://therapieclinic.teamtailor.com`,
  THERAPIE_BLOG: `${WORDPRESS_URL}/blog`,
  THERAPIE_FERTILITY: `https://therapiefertility.com/`,
  OPTILASE: `https://optilase.com`,
  POLICIES: `${WORDPRESS_URL}/our-policy-procedures`,
} as const;

export const SOCIAL_URLS = {
  facebook: 'https://www.facebook.com/TherapieClinics/',
  instagram: 'https://www.instagram.com/therapieclinic/',
  tiktok: 'https://www.tiktok.com/@therapieclinic',
  youtube: 'https://www.youtube.com/@TherapieClinicTV',
  linkedin: 'https://www.linkedin.com/company/therapie-laser-clinics-ltd/',
};
