import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { Modal, Button, cx, Icon } from '@therapie-ecommerce-ui/ui';

import { Flag } from '@components/flag/Flag';
import { ENV_REGION, SORTED_ACTIVE_REGIONS } from '@utils/constants/region.constants';
import { getRegionUrl } from '@utils/functions/region';
import { isDevEnv } from '@utils/environment';
import { SiteRegionURLsType } from '@utils/types/url.types';

import { RegionMenuModalProps } from './RegionMenu.types';
import { ServiceBarSubmenuProps } from '../../ServiceBar.types';

import styles from '../../service-bar.module.scss';
import stylesModal from './region-menu.module.scss';

/**
 * Provides a menu to switch between regions defined in `SITE_REGION_URLS`
 *
 * **Staging/Production**
 *
 * It will link between the different regions based on the **domain**
 *
 * **Development**
 *
 * It will link between the regions based on the **sub-path**
 *
 * This is **only** for testing i18n, it will still be pointing to the environment set in `.env.local`
 */
export const RegionMenu = ({ className }: ServiceBarSubmenuProps) => {
  const { t } = useTranslation('common');
  const { locale } = useRouter();

  const isActiveRegion = (region: SiteRegionURLsType) => {
    return isDevEnv() ? getRegionUrl(region).includes(locale!) : region === ENV_REGION;
  };

  return (
    <div className={cx(styles['submenu'], className)}>
      {SORTED_ACTIVE_REGIONS.map((region) => (
        <Button
          key={region}
          tag={isActiveRegion(region) ? undefined : 'a'}
          href={getRegionUrl(region)}
          variant="text"
          className={styles['submenu__button']}
          leftIcon={<Flag region={region} />}
          rightIcon={
            isActiveRegion(region) ? (
              <Icon
                size="sm"
                family="therapie"
                name="done"
                className={styles['submenu__text-icon']}
              />
            ) : undefined
          }
        >
          {t(`regions.${region}`)}
        </Button>
      ))}
    </div>
  );
};

const RegionMenuModal = ({ isOpen, onClose, onExited, className }: RegionMenuModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onExited={onExited}
      size="xs"
      closeButtonProps={{
        size: 'sm',
      }}
      containerClassName={stylesModal['modal']}
      dialogClassName={cx(stylesModal['modal__dialog'], className)}
      headerClassName={stylesModal['modal__header']}
      headerContent="Choose your region:"
      bodyClassName={stylesModal['modal__body']}
      bodyContent={<RegionMenu />}
    />
  );
};

RegionMenu.Modal = RegionMenuModal;
