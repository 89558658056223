import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';

import { ResponsiveSSR } from '@components/responsive-ssr/ResponsiveSSR';

import { FooterDesktop } from './footer-desktop/FooterDesktop';
import { FooterMobile } from './footer-mobile/FooterMobile';
import { createFooterGroups } from './Footer.utils';

export const Footer = (): JSX.Element => {
  const { t } = useTranslation(['footer', 'navbar']);

  const FOOTER_GROUPS = useMemo(() => createFooterGroups(t), [t]);

  return (
    <>
      <ResponsiveSSR atMobile>
        <FooterMobile groups={FOOTER_GROUPS} />
      </ResponsiveSSR>
      <ResponsiveSSR atDesktop>
        <FooterDesktop groups={FOOTER_GROUPS} />
      </ResponsiveSSR>
    </>
  );
};
