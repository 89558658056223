import React from 'react';
import { FooterLinkProps } from '../Footer.types';
import { LinkText } from '@components/link/Link';
import styles from './footer-link.module.scss';

export const FooterLink = ({ href, label, isExternal = false, ...restProps }: FooterLinkProps) => {
  const LinkTag = isExternal ? 'a' : LinkText;

  const linkProps = isExternal ? { target: '_blank', ...restProps } : restProps;

  return (
    <LinkTag href={href} className={styles['link']} {...linkProps}>
      {label}
    </LinkTag>
  );
};
