type DashEnvType = 'local' | 'staging' | 'production';

export const isDevEnv = () => {
  return process.env.NODE_ENV === 'development';
};

export const isTestEnv = () => {
  return process.env.NODE_ENV === 'test';
};

export const isProdEnv = () => {
  return process.env.NODE_ENV === 'production';
};

export const isPreviewEnv = () => {
  return process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview';
};

export const DASH_ENV = process.env.NEXT_PUBLIC_DASH_ENV as DashEnvType;
