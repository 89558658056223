import React from 'react';
import { useIsWebView } from '@features/web-view/hooks/useIsWebView/useIsWebView';
import { postMessageToMobileApp } from '@features/web-view/utils/post-message-to-mobile-app';
import { useSubscribe } from '@hooks/useSubscribe/useSubscribe';

export const WebViewProvider = ({ children }: React.PropsWithChildren) => {
  const { isWebView } = useIsWebView();

  if (!isWebView) {
    return <>{children}</>;
  }

  return <WebViewProviderInner>{children}</WebViewProviderInner>;
};

const WebViewProviderInner = ({ children }: React.PropsWithChildren) => {
  useWebViewSetup();

  return <>{children}</>;
};

// UI-TODO: Some inconsistencies using dashes and underscores introduced here, should be consistent
const useWebViewSetup = () => {
  useSubscribe('CART/UPDATE_CART_ITEM_TOTAL', (_, totalItems) => {
    postMessageToMobileApp({ 'CART/UPDATE_CART_ITEM_TOTAL': totalItems });
  });

  useSubscribe('AUTHENTICATION/INITIALISED', (_, user) => {
    postMessageToMobileApp({ 'AUTHENTICATION/INITIALISED': user });
  });

  useSubscribe('AUTHENTICATION/SIGN-IN', (_, user) => {
    postMessageToMobileApp({ 'AUTHENTICATION/SIGN_IN': user });
  });

  useSubscribe('AUTHENTICATION/SIGN-OFF', (_) => {
    postMessageToMobileApp({ 'AUTHENTICATION/SIGN_OFF': null });
  });
};
