import { TFunction } from 'react-i18next';
import { SITE_URLS, WORDPRESS_URLS } from '@utils/constants/site-urls.constants';
import { getTreatmentsPageLink } from '@features/treatments/pages/treatments-page/TreatmentsPage.utils';
import { ENV_REGION } from '@utils/constants/region.constants';
import { FOOTER_GROUPS } from './Footer.constants';
import { FooterLink } from './footer-link/FooterLink';
import { InitialFooterGroup } from './Footer.types';

export const createFooterGroups = (t: TFunction<['footer', 'navbar']>) => {
  return FOOTER_GROUPS(t).map(({ title, links }) => ({
    title,
    links: links.map((link, index) => {
      return (
        <>
          {!!link && (
            <FooterLink
              key={`footer-link-${index}-${link.href}`}
              href={link.href}
              label={link.label}
              isExternal={link.isExternal}
            />
          )}
        </>
      );
    }),
  }));
};

export function getFooterLinks(
  t: TFunction<['footer', 'navbar']>,
  footerLinkNames: string[]
): InitialFooterGroup['links'] {
  return footerLinkNames?.map(
    (footerLinkName) => MAP_FOOTER_LINKS(t)[footerLinkName as FooterLinkNameType]
  );
}

type FooterLinkNameType = keyof ReturnType<typeof MAP_FOOTER_LINKS>;

function MAP_FOOTER_LINKS(t: TFunction<['footer', 'navbar']>) {
  return {
    findAClinic: {
      href: WORDPRESS_URLS.FIND_A_CLINIC,
      isExternal: true,
      label: t('footer:navigation.locations.links.findAClinic'),
    },
    headOffice: {
      href: WORDPRESS_URLS.HEAD_OFFICE,
      isExternal: true,
      label: t('footer:navigation.locations.links.headOffice'),
    },
    ireland: {
      href: WORDPRESS_URLS.ROI_CLINICS,
      isExternal: true,
      label: t('footer:navigation.locations.links.ireland'),
    },
    uk: {
      href: WORDPRESS_URLS.UK_CLINICS,
      isExternal: true,
      label: t('footer:navigation.locations.links.uk'),
    },
    us: {
      href: WORDPRESS_URLS.US_CLINICS,
      isExternal: true,
      label: t('footer:navigation.locations.links.us'),
    },
    lhr: {
      href: getTreatmentsPageLink('laser-hair-removal'),
      label: t('navbar:treatmentGroups.laser.label'),
    },
    injections:
      ENV_REGION === 'us'
        ? {
            href: getTreatmentsPageLink('botox-and-fillers'),
            label: t('navbar:treatmentGroups.botoxAndFillers.label'),
          }
        : {
            href: getTreatmentsPageLink('cosmetic-injections'),
            label: t('navbar:treatmentGroups.injectables.label'),
          },
    body: {
      href: getTreatmentsPageLink('body-treatments'),
      label: t('navbar:treatmentGroups.body.label'),
    },
    skin: {
      href: getTreatmentsPageLink('skin-treatments'),
      label: t('navbar:treatmentGroups.skin.label'),
    },
    skincare: {
      href: SITE_URLS.PRODUCTS,
      label: t('footer:navigation.treatments.links.skincare'),
    },
    aboutUs: {
      href: WORDPRESS_URLS.ABOUT_US,
      isExternal: true,
      label: t('footer:navigation.company.links.aboutUs'),
    },
    careers: {
      href: WORDPRESS_URLS.CAREERS,
      isExternal: true,
      label: t('footer:navigation.company.links.careers'),
    },
    therapieBlog: {
      href: WORDPRESS_URLS.THERAPIE_BLOG,
      isExternal: true,
      label: t('footer:navigation.company.links.therapieBlog'),
    },
    therapieFertility: {
      href: WORDPRESS_URLS.THERAPIE_FERTILITY,
      isExternal: true,
      label: t('footer:navigation.company.links.therapieFertility'),
    },
    optilase: {
      href: WORDPRESS_URLS.OPTILASE,
      isExternal: true,
      label: t('footer:navigation.company.links.optilase'),
    },
    policies: {
      href: WORDPRESS_URLS.POLICIES,
      isExternal: true,
      label: t('footer:navigation.legalStuff.links.policies'),
    },
    privacy: {
      href: SITE_URLS.PRIVACY_CENTER,
      isExternal: true,
      label: t('footer:navigation.legalStuff.links.privacy'),
    },
    termsAndConditions: {
      href: SITE_URLS.TERMS_AND_CONDITIONS,
      label: t('footer:navigation.legalStuff.links.termsAndConditions'),
    },
  };
}
