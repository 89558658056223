import { useEffect, useState } from 'react';
import { isDevEnv, isPreviewEnv } from '@utils/environment';

type CookieConsent =
  | {
      necessary: boolean;
      preferences: boolean;
      statistics: boolean;
      marketing: boolean;
    }
  | undefined;

export const useCookieConsent = () => {
  const [cookieConsent, setCookieConsent] = useState<CookieConsent>(getCookieConsent());

  useEffect(() => {
    if (cookieConsent) return;

    function consentReadyHandler() {
      setCookieConsent(getCookieConsent());
    }

    window.addEventListener('CookiebotOnConsentReady', consentReadyHandler);

    return () => {
      window.removeEventListener('CookiebotOnConsentReady', consentReadyHandler);
    };
  }, [cookieConsent]);

  return { cookieConsent };
};

function getCookieConsent(): CookieConsent {
  if (isDevEnv() || isPreviewEnv())
    return {
      necessary: true,
      preferences: true,
      statistics: true,
      marketing: true,
    };

  if (typeof window === 'undefined' || window.Cookiebot === undefined) return undefined;

  return window.Cookiebot.consent;
}
