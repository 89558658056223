import { PropsWithChildren, useEffect } from 'react';
import { useSegmentAnalytics } from '@hooks/useSegmentAnalytics/useSegmentAnalytics';
import { useRouteChange } from '@hooks/useRouteChange/useRouteChange';

export const AnalyticsProvider = ({ children }: PropsWithChildren) => {
  const { sendPageEvent } = useSegmentAnalytics();

  useEffect(() => {
    // send initial page event on mount
    sendPageEvent();
  }, [sendPageEvent]);

  useRouteChange({
    // send subsequent page events when route changes
    onRouteChangeComplete: () => {
      sendPageEvent();
    },
  });

  return <>{children}</>;
};
