import { CartItemType } from '@/data/graphql/types';
import { CartOperations } from './cart.types';

export const LS_CART_KEY = 'USER_CART';

// UI-TODO: To be moved on a separated package
export const CART_ACTIONS = {
  ADD_ITEM: 'CART/ADD-ITEM-TO-CART',
  INCREASE_ITEM_QTY: 'CART/INCREASE-ITEM-QUANTITY',
  DECREASE_ITEM_QTY: 'CART/DECREASE-ITEM-QUANTITY',
  REMOVE_ITEM: 'CART/REMOVE-ITEM-FROM-CART',
  RECOVER_ITEM: 'CART/RECOVER-ITEM-TO-CART',
  CLEAR_ITEMS: 'CART/CLEAR-ITEMS-FROM-CART',
  CLEAR_REMOVED_ITEMS: 'CART/CLEAR-REMOVED_ITEMS-FROM-CART',
  UPDATE_GLOBAL_OPERATIONS: 'CART/UPDATE-GLOBAL-OPERATIONS',
  UPDATE_ADD_ITEM_OPERATION: 'CART/UPDATE-ADD-ITEM-OPERATION',
  UPDATE_REMOVE_ITEM_OPERATION: 'CART/UPDATE-REMOVE-ITEM-OPERATION',
  UPDATE_CHANGE_ITEM_QUANTITY_OPERATION: 'CART/UPDATE-CHANGE-ITEM-QUANTITY-OPERATION',
} as const;

export const DEFAULT_CART_OPERATIONS: CartOperations = {
  add_item_to_cart: {},
  change_item_quantity: [],
  remove_item_from_cart: [],
};

export const DEFAULT_CART_ITEM_TYPE = CartItemType.Product;
