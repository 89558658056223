import { useTranslation } from 'next-i18next';
import Image from 'next/image';

import { Button, cx } from '@therapie-ecommerce-ui/ui';
import { Flag } from '@components/flag/Flag';
import { RegionMenu } from '@components/layout/default-layout/service-bar/service-submenu/region-menu/RegionMenu';

import { THERAPIE_LOGO_SRC } from '@utils/constants/images.constants';
import { Link } from '@components/link/Link';

import { HeaderProps } from './Header.types';

import styles from './header.module.scss';
import { useState } from 'react';

export const Header = ({ className }: HeaderProps) => {
  const { t } = useTranslation(['header', 'navbar', 'common']);
  const [isRegionMenuOpen, setIsRegionMenuOpen] = useState(false);

  const handleRegionMenuOnClick = () => {
    setIsRegionMenuOpen(true);
  };
  const handleRegionMenuOnClose = () => {
    setIsRegionMenuOpen(false);
  };

  return (
    <header className={cx(styles['header'], className)}>
      <div className={styles['header__logo']}>
        <Link href="/" aria-label={t('header:logoLinkLabel')}>
          <Image src={THERAPIE_LOGO_SRC} alt="Therapie Logo" objectFit="contain" />
        </Link>
      </div>

      {/* <Button
        className={styles['sidebar__action-button']}
        aria-label={t('common:regions.chooseRegion')}
        variant="link"
        size="sm"
        rightIcon={<Flag size="md" />}
        onClick={handleRegionMenuOnClick}
        textProps={{
          variant: { sm: 'h6', md: 'h5' },
          color: 'night-200',
        }}
      >
        {t('navbar:language')}
      </Button> */}

      <RegionMenu.Modal isOpen={isRegionMenuOpen} onClose={handleRegionMenuOnClose} />
    </header>
  );
};
