import { generateAuthHeaders as generateLSAuthHeaders } from '@therapie-ecommerce-ui/auth';
import { AUTH_HEADERS_SOURCE } from './authentication.constants';

export function generateAuthHeaders() {
  if (AUTH_HEADERS_SOURCE === 'localStorage') return generateLSAuthHeaders();
  else if (AUTH_HEADERS_SOURCE === 'cookie') {
    return { 'x-auth-required': 'token' };
  }
  throw new Error('Invalid Auth Headers Source specified.');
}
