import { useEffect, useState } from 'react';

import { NO_IMAGE_AVAILABLE_SRC } from '@utils/constants/images.constants';

import { UseProgressiveImageProps } from './useProgressiveImage.types';

export const useProgressiveImage = ({
  highQualitySrc = '',
  lowQualitySrc = '',
}: UseProgressiveImageProps) => {
  const [src, setSrc] = useState(lowQualitySrc);

  useEffect(() => {
    setSrc(lowQualitySrc);

    const img = new Image();
    img.src = highQualitySrc;

    img.onload = () => {
      setSrc(highQualitySrc);
    };
  }, [lowQualitySrc, highQualitySrc]);

  const isBlurred = lowQualitySrc === src;

  const hasImg = !!src;

  const style = hasImg
    ? {
        filter: isBlurred ? 'blur(20px)' : 'none',
        overflow: 'hidden',
        transition: isBlurred ? 'none' : 'filter 0.3s ease-out',
      }
    : undefined;

  return {
    hasImg,
    src: hasImg ? src : NO_IMAGE_AVAILABLE_SRC,
    style,
  };
};
