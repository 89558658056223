import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';

import { Button, cx, Drawer, Icon } from '@therapie-ecommerce-ui/ui';
import { useAuthenticationStore } from '@therapie-ecommerce-ui/auth';

import { WORDPRESS_URLS } from '@utils/constants/site-urls.constants';
import { LogOut } from '@features/authentication/components/log-out/LogOut';
import { LoginRegister } from '@features/authentication/components/login-register/LoginRegister';
import { RegionMenu } from '@components/layout/default-layout/service-bar/service-submenu/region-menu/RegionMenu';
import { Flag } from '@components/flag/Flag';

import { SidebarProps } from './Sidebar.types';

import styles from './sidebar.module.scss';

export const Sidebar = ({ children, onClose, isOpen, hasBanner }: SidebarProps) => {
  const { t } = useTranslation(['navbar', 'common']);
  const isUserAuthenticated = useAuthenticationStore((s) => s.isUserAuthenticated);
  const [isRegionMenuOpen, setIsRegionMenuOpen] = useState(false);

  const handleRegionMenuOnClick = () => {
    onClose();
    setIsRegionMenuOpen(true);
  };
  const handleRegionMenuOnClose = () => {
    setIsRegionMenuOpen(false);
  };

  return (
    <Drawer
      keepMounted
      disablePortal
      hideCloseButton
      isOpen={isOpen}
      placement="left"
      onClose={onClose}
      size="full"
      className={cx(styles['sidebar'], { [styles['sidebar--with-banner']]: hasBanner })}
      containerClassName={styles['sidebar__container']}
      overlayClassName={styles['sidebar__overlay']}
      bodyClassName={styles['sidebar__body']}
      dialogClassName={styles['sidebar__content']}
      footerClassName={styles['sidebar__footer']}
      footerContent={
        <>
          <Button
            className={styles['sidebar__action-button']}
            aria-label={t('common:regions.chooseRegion')}
            variant="text"
            size="sm"
            leftIcon={<Flag size="lg" />}
            onClick={handleRegionMenuOnClick}
            textProps={{
              variant: { sm: 'h6', md: 'h5' },
              color: 'night-200',
            }}
          >
            {t('navbar:language')}
          </Button>
          {isUserAuthenticated ? (
            <LogOut
              className={styles['sidebar__action-button']}
              variant="text"
              size="sm"
              textProps={{
                variant: { sm: 'h6', md: 'h5' },
                color: 'night-200',
              }}
            />
          ) : (
            <LoginRegister />
          )}
        </>
      }
    >
      {children}
      <div className={styles['sidebar__fixed']}>
        <a className={styles['sidebar__link--back-to-website']} href={WORDPRESS_URLS.HOME}>
          <Icon family="therapie" name="arrow_back" size="sm" color="night-200" />
          {t('common:backToTherapieWebsite')}
        </a>
      </div>
      <RegionMenu.Modal isOpen={isRegionMenuOpen} onClose={handleRegionMenuOnClose} />
    </Drawer>
  );
};
