import { formatCountyLabel } from '@components/forms/address-fields/AddressFields.utils';
import { formatOptionsLabel } from '@utils/functions/format';
import { AddressInput, User } from '@/data/graphql/types';

export const formatAddress = (address: AddressInput) =>
  [
    address.line1,
    address.line2,
    address.city,
    formatCountyLabel(address.county, address.country),
    formatOptionsLabel(address.state),
    address.country,
    address.postcode,
  ]
    .filter((field) => field && field.trim() !== '')
    .join(', ');

/**
 * Returns a new array of addresses ordered by default shipping and billing address
 * moved to first position (Prioritizing shipping before billing) and adding a new prop
 * to each Address obj with the `addressId` (initial index)
 */
export const sortAddresses = (addresses: AddressInput[]) => {
  // Clone the array with the addressId prop to not lose the original order
  const addressesWithId = addresses.map((address, index) => ({ ...address, addressId: index }));
  return addressesWithId.sort((a, b) =>
    a.defaultShipping
      ? -1
      : b.defaultShipping
      ? 1
      : a.defaultBilling
      ? -1
      : b.defaultBilling
      ? 1
      : 0
  );
};

export const getUsersDefaultAddress = (user?: User) => {
  return {
    defaultShippingAddress:
      user?.addresses?.find((address) => address?.defaultShipping) ?? user?.addresses?.[0]
        ? user?.addresses?.[0]!
        : {},
    defaultBillingAddress:
      user?.addresses?.find((address) => address?.defaultBilling) ?? user?.addresses?.[0]
        ? user?.addresses?.[0]!
        : {},
  };
};
