import { minutesToMilliseconds } from 'date-fns';
import { useQuery } from 'react-query';
import { useAuthenticationStore } from '@therapie-ecommerce-ui/auth';
import { graphqlGatewayClient } from '@core/graphql/gateway/gateway';
import { generateAuthHeaders } from '@features/authentication/headers';
import {
  removeCancelledAppointments,
  splitUpcomingAppointments,
} from '@features/booking/utils/booking.utils';
import { WEB_VIEW_STALE_TIME_MS } from '@features/web-view/constants/queries';
import { useIsWebView } from '@features/web-view/hooks/useIsWebView/useIsWebView';
import {
  BookingHistory,
  GetUserBookingsHistoryQuery,
  GetUserBookingsHistoryQueryVariables,
} from '@/data/graphql/types';

export const GET_USER_BOOKINGS_HISTORY_KEY = 'GET_USER_BOOKINGS_HISTORY';

export const useGetUserBookingsHistoryQuery = () => {
  const isUserAuthenticated = useAuthenticationStore((s) => s.isUserAuthenticated);
  const { isWebView } = useIsWebView();

  return useQuery(
    GET_USER_BOOKINGS_HISTORY_KEY,
    async () => {
      const { getUserBookingsHistory: response } = await graphqlGatewayClient.post<
        GetUserBookingsHistoryQueryVariables,
        GetUserBookingsHistoryQuery
      >(
        '/GetUserBookingsHistory',
        { page: { page: 0, size: 100 } },
        { headers: generateAuthHeaders() }
      );

      return response?.bookingsHistory ?? [];
    },
    {
      enabled: isUserAuthenticated,
      refetchOnWindowFocus: true,
      staleTime: isWebView ? WEB_VIEW_STALE_TIME_MS : minutesToMilliseconds(1),
      select: (bookings) => removeCancelledAppointments(bookings),
    }
  );
};

export const useGetUserBookingsHistoryByAppointmentIdQuery = ({
  appointmentId,
}: {
  appointmentId: string;
}) => {
  const { data: bookings, ...rest } = useGetUserBookingsHistoryQuery();

  // Find booking that contains the appointment
  let data = bookings?.find((booking) =>
    booking.bookings?.find((appointment) => appointment?.appointmentId === appointmentId)
  );

  // Filter for the appointment in the booking
  data = data
    ? {
        ...data,
        bookings: data?.bookings?.filter(
          (appointment) => appointment?.appointmentId === appointmentId
        ),
      }
    : undefined;

  return {
    ...rest,
    data,
  };
};

export const useGetUserBookingsHistoryByAppointmentIdsQuery = ({
  appointmentIds,
}: {
  appointmentIds: string[];
}) => {
  const { data: bookings, ...rest } = useGetUserBookingsHistoryQuery();

  const data = bookings?.reduce((acc, booking) => {
    const bookingWithAppointments = booking.bookings?.filter((appt) =>
      appointmentIds.includes(appt.appointmentId!)
    );

    if (!bookingWithAppointments?.length) return acc;

    acc.push({ ...booking, bookings: bookingWithAppointments });

    return acc;
  }, [] as BookingHistory[]);

  return {
    ...rest,
    data,
  };
};

export const useGetSplitUserBookingsHistoryQuery = () => {
  const { data: bookings, ...rest } = useGetUserBookingsHistoryQuery();

  const splitUserBookingHistory = splitUpcomingAppointments(bookings);

  return {
    ...rest,
    data: splitUserBookingHistory,
  };
};
