import { cx, Text } from '@therapie-ecommerce-ui/ui';

import { useTreatmentGroups } from '@features/treatments/hooks/useTreatmentGroups/useTreatmentGroups';

import { CartItemLink } from '../../cart-item-link/CartItemLink';
import { CartTreatmentNameProps } from './CartTreatmentName.type';

import styles from '../cart-item-name.module.scss';

// UI-TODO: Get treatment group data and store in EnrichedCartItem
export const CartTreatmentName = ({
  textProps,
  name,
  itemHref,
  isLoading,
  isRemoved,
  treatmentGroup,
  handleSendAnalytics,
}: CartTreatmentNameProps) => {
  const { getTreatmentGroupName } = useTreatmentGroups();
  return (
    <div>
      <Text
        className={cx({
          [styles['item-name--removed']]: isRemoved,
        })}
        weight="light"
        {...textProps}
      >
        {treatmentGroup && getTreatmentGroupName({ treatmentGroup })}
      </Text>
      <CartItemLink
        href={itemHref}
        className={styles['item-name__link']}
        isDisabled={isLoading || isRemoved}
        onClick={handleSendAnalytics}
      >
        <Text
          color="inherit"
          className={cx(styles['item-name'], {
            [styles['item-name--removed']]: isRemoved,
          })}
          weight="semi-bold"
          {...textProps}
        >
          {name}
        </Text>
      </CartItemLink>
    </div>
  );
};
