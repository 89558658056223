import Script from 'next/script';

// Script to hide document from being embedded. This is a security measure to prevent clickjacking, and useful on older browsers where the header X-Frame-Options or Content-Security-Policy is not supported.

export const PreventClickJacking = () => (
  <Script id="click-jacking">
    {`try {
            top?.document.domain
          } catch (e) {
            const fn = function() {
              document.body.innerHTML = '';
            }
            setInterval(fn, 1);

            if(document.body) {
              document.body.onload = fn;
            }
          }`}
  </Script>
);
