import { CartItemType } from '@/data/graphql/types';
import { Price } from '@components/price/Price';
import { Trans, useTranslation } from 'next-i18next';

export const useProductName = ({ type, name }: { type: CartItemType; name?: string }) => {
  const { t } = useTranslation('products');

  const isVoucher = type === CartItemType.Voucher;

  if (!isVoucher) return name;

  // Voucher name from backend comes like "Voucher 50"
  const price = name?.split(' ')[1];

  return (
    <Trans
      t={t}
      i18nKey="giftVouchers.cartName"
      values={{ value: price }}
      components={[<Price key={0} />]}
    />
  );
};
