import { useAuthenticationStore } from '@therapie-ecommerce-ui/auth';

import { useOfflineCartId } from '@features/cart-and-checkout/hooks/useOfflineCartId';

/**
 * @returns cartId for the User. If User is not authenticated, returns offlineCartId.
 */
export const useCartId = () => {
  const isAuthInitialised = useAuthenticationStore((state) => state.isInitialised);
  const isUserAuthenticated = useAuthenticationStore((state) => state.isUserAuthenticated);
  const username = useAuthenticationStore((state) => state.user?.username);
  const offlineCartId = useOfflineCartId();

  const cartId = isUserAuthenticated ? username : offlineCartId;

  if (!isAuthInitialised) return null;

  return cartId;
};
