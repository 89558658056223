import { useTranslation } from 'next-i18next';

import { Button, ButtonProps } from '@therapie-ecommerce-ui/ui';
import { LinkButton } from '@components/link/Link';

import { ContinueShoppingButtonProps } from './ContinueShoppingButton.types';

export const ContinueShoppingButton = ({
  className,
  href,
  onClick,
}: ContinueShoppingButtonProps) => {
  const { t } = useTranslation('cart');

  const props: Omit<ButtonProps, 'children' | 'ref'> = {
    className,
    variant: 'outline',
    onClick,
    size: {
      sm: 'md',
      md: 'lg',
    },
    isFullWidth: true,
  };

  if (href) {
    return (
      <LinkButton href={href} {...props}>
        {t('continueShopping')}
      </LinkButton>
    );
  }

  return <Button {...props}>{t('continueShopping')}</Button>;
};
