import produce from 'immer';
import create from 'zustand';
import { NamedSet, persist, subscribeWithSelector } from 'zustand/middleware';
import { zsDevtools } from '@core/zustand/zustand.utils';
import { CART_ACTIONS, DEFAULT_CART_OPERATIONS, LS_CART_KEY } from './cart.constants';
import { CartState } from './cart.types';
import { generateOfflineUserId } from './cart.utils';

export const useCartStore = create(
  zsDevtools(
    // Persists selected values in LocalStorage
    persist(
      // allows us to selectively subscribe to a value in the state, main use case is in useCartSetup.
      subscribeWithSelector<CartState, NamedSet<CartState>>((set) => ({
        items: [],
        removedItems: [],
        totalCost: 0,
        deliveryCost: 0,
        subtotalCost: 0,
        offlineCartId: generateOfflineUserId(),
        status: {
          isInitialised: false,
          globalOperations: 0,
          operations: {
            ...DEFAULT_CART_OPERATIONS,
          },
        },
        initialiseCart: () =>
          set(
            produce<CartState>((state) => {
              state.status.isInitialised = true;
            })
          ),
        setCart: (cart) =>
          set(
            produce<CartState>((state) => {
              state.items = cart?.items ?? [];
              state.totalCost = cart?.totalCost ?? 0;
              state.deliveryCost = cart?.deliveryCost ?? 0;
              state.subtotalCost = cart?.subtotalCost ?? 0;
            })
          ),
        setRemovedItem: ({ itemId, removedQuantity }) =>
          set(
            produce<CartState>((state) => {
              const index = state.items.findIndex((item) => item.itemId === itemId);
              const removedItem = state.items[index];

              state.removedItems.push({
                ...removedItem,
                quantity: 0,
                removedQuantity: removedQuantity,
                removedIndex: index,
              });
            })
          ),
        setOfflineCartId: (newValue, overwrite) =>
          set(
            produce<CartState>((state) => {
              //Only updates value if `overwrite` or if the value comes from undefined/empty
              if (overwrite || !state.offlineCartId) state.offlineCartId = newValue;
            })
          ),
        resetStatus: () =>
          set(
            produce<CartState>((state) => {
              state.status = {
                isInitialised: false,
                globalOperations: 0,
                operations: {
                  ...DEFAULT_CART_OPERATIONS,
                },
              };
            })
          ),
        clearItems: () =>
          set(
            produce<CartState>((state) => {
              state.items = [];
              state.totalCost = 0;
              state.deliveryCost = 0;
              state.subtotalCost = 0;
            }),
            false,
            CART_ACTIONS.CLEAR_ITEMS
          ),
        clearRemovedItems: (itemId) =>
          set(
            produce<CartState>((state) => {
              if (itemId) {
                state.removedItems = state.removedItems.filter((item) => item.itemId !== itemId);
                return;
              }

              if (state.removedItems.length > 0) {
                state.removedItems = [];
              }
            }),
            false,
            CART_ACTIONS.CLEAR_REMOVED_ITEMS
          ),
        updateGlobalOperations: (quantity) => {
          set(
            produce<CartState>((state) => {
              state.status.globalOperations += quantity;
            }),
            false,
            CART_ACTIONS.UPDATE_GLOBAL_OPERATIONS
          );
        },
        updateAddItemOperation: (payload) => {
          set(
            produce<CartState>((state) => {
              if (!payload) {
                state.status.operations.add_item_to_cart = {};
                return;
              }

              state.status.operations.add_item_to_cart = {
                ...state.status.operations.add_item_to_cart,
                ...payload,
              };
            }),
            false,
            CART_ACTIONS.UPDATE_ADD_ITEM_OPERATION
          );
        },
        updateRemoveItemOperation: (itemId, options = {}) =>
          set(
            produce<CartState>((state) => {
              const { shouldRemove = false } = options;

              if (shouldRemove) {
                state.status.operations.remove_item_from_cart =
                  state.status.operations.remove_item_from_cart.filter((id) => id !== itemId);
                return;
              }
              state.status.operations.remove_item_from_cart.push(itemId);
            }),
            false,
            CART_ACTIONS.UPDATE_REMOVE_ITEM_OPERATION
          ),
        updateChangeItemQuantityOperation: (itemId, options = {}) =>
          set(
            produce<CartState>((state) => {
              const { shouldRemove = false } = options;

              if (shouldRemove) {
                state.status.operations.change_item_quantity =
                  state.status.operations.change_item_quantity.filter((id) => id !== itemId);
                return;
              }
              state.status.operations.change_item_quantity.push(itemId);
            }),
            false,
            CART_ACTIONS.UPDATE_CHANGE_ITEM_QUANTITY_OPERATION
          ),
      })),
      { name: LS_CART_KEY, partialize: (state) => ({ offlineCartId: state.offlineCartId }) }
    )
  )
);

export const {
  clearItems: clearItemsFromCartState,
  clearRemovedItems: clearRemovedItemsCartState,
  updateGlobalOperations: updateGlobalOperationsCartState,
  updateAddItemOperation: updateAddItemOperationCartState,
  updateChangeItemQuantityOperation: updateChangeItemQuantityOperationCartState,
  updateRemoveItemOperation: updateRemoveItemOperationCartState,
  initialiseCart: initialiseCartState,
  resetStatus: resetStatusCartState,
  setCart: setCartState,
  setRemovedItem: setRemovedItemCartState,
  setOfflineCartId: setOfflineCartIdState,
} = useCartStore.getState();
