import { useTranslation } from 'next-i18next';
import { Text } from '@therapie-ecommerce-ui/ui';
import styles from './footer-copyright.module.scss';

export const FooterCopyright = () => {
  const { t } = useTranslation('footer');
  return (
    <Text variant="small-print" color="trp-soft-grey" className={styles['copyright']}>
      {t('copyright')}
    </Text>
  );
};
