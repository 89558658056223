import { buildLinkUrl } from '@utils/functions/links';

import { TreatmentGroupType } from '@features/treatments/pages/treatments-page/TreatmentsPage.types';

import { TREATMENT_GROUP_QUERY_MAP } from './useTreatmentGroups.constants';

export const getAllTreatmentGroups = () => Object.keys(TREATMENT_GROUP_QUERY_MAP);

export const getTreatmentPageLink = (group: TreatmentGroupType, collection: string) => {
  return buildLinkUrl('TREATMENT_COLLECTIONS', { group, collection });
};
