import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import { createContext, cx, Text, useInterval } from '@therapie-ecommerce-ui/ui';
import { RecaptchaContext, RecaptchaErrorMessageProps } from './Recaptcha.types';
import { IS_RECAPTCHA_ENABLED } from './Recaptcha.utils';
import styles from './recaptcha.module.scss';

// Context will be undefined in development as recaptcha is disabled
const [RecaptchaContext, useRecaptchaContext] = createContext<RecaptchaContext | undefined>({
  shouldThrowError: false,
});

const RECAPTCHA_INVISIBLE_SITE_KEY =
  process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_INVISIBLE_SITEKEY ?? '';

const RECAPTCHA_DOM_SELECTOR = 'iframe[src*="recaptcha/api2/bframe"]';

/**
 * This is a wrapper component around ReCAPTCHA from react-google-recaptcha.
 * It provides a context to components to execute the recaptcha and verify
 * the user.
 *
 * https://github.com/dozoisch/react-google-recaptcha#usage
 */
export const RecaptchaProvider = ({ children, ...rest }: React.PropsWithChildren) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isRecaptchaCancelled, setIsRecaptchaCancelled] = useState(false);

  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const abortControllerRef = useRef<AbortController | null>(null);

  /**
   * Set first instance of abortControllerRef in useEffect as it is not
   * available on the server.
   *
   * Also clear any instance on unmount.
   */
  useEffect(() => {
    abortControllerRef.current = new AbortController();

    return () => {
      abortControllerRef.current = null;
    };
  }, []);

  useInterval(
    () => {
      const recaptchaIframe = document.querySelector(RECAPTCHA_DOM_SELECTOR);

      if (!recaptchaIframe) return;

      const recaptchaOverlay = recaptchaIframe?.parentNode?.parentNode as HTMLElement;
      if (isProcessing && recaptchaOverlay?.style.visibility === 'visible') {
        setIsOpen(true);
      }

      /**
       * If the recaptcha is has been initialised, but the user has closed the overlay,
       * we need to use the abortController to throw an error.
       */
      if (isProcessing && isOpen && recaptchaOverlay?.style.visibility === 'hidden') {
        abortControllerRef?.current?.abort();
      }
    },
    // Only run the interval when the recaptcha is processing
    isProcessing ? 100 : null
  );

  useEffect(() => {
    /**
     * isOpen tracks the state of the recaptcha overlay, and is set to true when
     * the recaptcha overlay is visible. If the user closes the overlay without
     * completing the challenge, isProcessing will be set to false and a DOMException
     * will be thrown.
     *
     * Here we can track that and assign a new AbortController to the ref.
     */
    const recaptchaHasBeenClosedWithoutCompleting = isOpen && !isProcessing;

    if (recaptchaHasBeenClosedWithoutCompleting) {
      setIsOpen(false);
      /**
       * Only once instance of an AbortController is allowed per request. As a result,
       * we need to create a new one each time the recaptcha is closed.
       */
      abortControllerRef.current = new AbortController();
    }
  }, [isOpen, isProcessing]);

  const handleExecuteRecaptcha = useCallback(async () => {
    let token: string | null = null;

    return new Promise<string | null>(async (resolve, reject) => {
      /**
       * Abort the promise if the user closes the recaptcha overlay without completing,
       * as unfortunately the recaptcha API does not provide a way to detect this.
       */
      abortControllerRef?.current?.signal.addEventListener('abort', () => {
        setIsProcessing(false);
        setIsRecaptchaCancelled(true);

        reject(new DOMException('AbortError', 'AbortError'));
      });

      if (recaptchaRef.current) {
        setIsRecaptchaCancelled(false);
        recaptchaRef.current.reset();
        setIsProcessing(true);

        token = await recaptchaRef.current.executeAsync();
        if (token) {
          setIsProcessing(false);
          resolve(token);
          return token;
        }
      }
    });
  }, []);

  if (!IS_RECAPTCHA_ENABLED) {
    return <>{children}</>;
  }

  return (
    <RecaptchaContext
      value={{
        isVisible,
        setIsVisible,
        handleExecuteRecaptcha,
        isRecaptchaCancelled,
        setIsRecaptchaCancelled,
      }}
    >
      {children}
      {/* UI-TODO: remove ts-ignore */}
      {/* @ts-ignore */}
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={RECAPTCHA_INVISIBLE_SITE_KEY}
        size={'invisible'}
        style={{ visibility: isVisible ? 'visible' : 'hidden' }}
        {...rest}
      />
    </RecaptchaContext>
  );
};
RecaptchaProvider.displayName = 'RecaptchaProvider';

export const useRecaptcha = () => {
  const {
    isVisible,
    setIsVisible,
    setIsRecaptchaCancelled,
    isRecaptchaCancelled,
    handleExecuteRecaptcha,
  } = useRecaptchaContext() ?? {};
  // Solves useEffect exhaustive-deps issues
  const setIsRecaptchaCancelledRef = useRef(setIsRecaptchaCancelled);
  const setIsVisibleRef = useRef(setIsVisible);

  // Resets the error stage when the hook is unmounted
  useEffect(() => () => setIsRecaptchaCancelledRef.current?.(false), []);

  // Show up the banner when the hook is rendered and hide it when unmounted
  useEffect(() => {
    const handleSetIsVisible = setIsVisibleRef.current;
    if (!isVisible) handleSetIsVisible?.(true);
    return () => {
      if (isVisible) handleSetIsVisible?.(false);
    };
  }, [isVisible]);

  return { isRecaptchaCancelled, handleExecuteRecaptcha };
};

export const RecaptchaErrorMessage = ({ className }: RecaptchaErrorMessageProps) => {
  const { t } = useTranslation('forms');
  const { isRecaptchaCancelled } = useRecaptcha();
  if (!isRecaptchaCancelled) return null;

  return (
    <Text
      color="scarlett-500"
      variant="small-print"
      className={cx(styles['recaptcha-message'], className)}
    >
      {t('recaptcha.challengeNotCompleted')}
    </Text>
  );
};
