import { TFunction } from 'react-i18next';

import {
  getBrandLink,
  getProductTypeLink,
  getProductSkinTypeLink,
  getProductSkinConcernLink,
} from '@features/product-catalog/pages/products-with-filter-page/ProductsWithFilterPage.utils';
import { buildLinkUrl } from '@utils/functions/links';
import { REFER_A_FRIEND_REWARD_PRICE } from '@features/refer-a-friend/constants/rewards.constants';
import { SITE_URLS } from '@utils/constants/site-urls.constants';
import { getTreatmentPageLink } from '@features/treatments/hooks/useTreatmentGroups/useTreatmentGroups.utils';
import { TreatmentGroupType } from '@features/treatments/pages/treatments-page/TreatmentsPage.types';
import { log } from '@core/logging/logging';

import { NavigationItem, NavigationColumnsType } from './Navigation.types';
import {
  ALL,
  BRANDS,
  PRODUCT_TYPE,
  SKIN_CONCERNS,
  SKIN_TYPE,
  ANTI_OXIDANT,
  GLYCOLIC,
  PEPTIDE,
  SALICYLIC,
  VITAMIN_C,
} from './Navigation.constants';
import { DASH_ENV } from '@utils/environment';

const getAllProductsLinks = (t: TFunction<'navbar'>): NavigationItem[] => [
  { text: t(`${ALL}.allProducts`), href: SITE_URLS.PRODUCTS },
  { text: t(`${ALL}.giftVouchers`), href: SITE_URLS.GIFT_VOUCHERS },
];

const getBrandLinks = (t: TFunction<'navbar'>): NavigationItem[] => {
  return [
    {
      text: t(`${BRANDS}.advancedNutritionProgramme`),
      href: getBrandLink('advanced-nutrition-programme'),
    },
    {
      text: t(`${BRANDS}.environ`),
      href: getBrandLink('environ'),
    },
    {
      text: t(`${BRANDS}.imageSkincare`),
      href: getBrandLink('image-skincare'),
    },
    {
      text: t(`${BRANDS}.murad`),
      href: getBrandLink('murad'),
    },
    {
      text: t(`${BRANDS}.skinTheory`),
      href: getBrandLink('skin-theøry'),
    },
  ];
};

const getProductTypeLinks = (t: TFunction<'navbar'>): NavigationItem[] => {
  return [
    {
      text: t(`${PRODUCT_TYPE}.bodyLotion`),
      href: getProductTypeLink('body-lotion'),
    },
    {
      text: t(`${PRODUCT_TYPE}.cleanser`),
      href: getProductTypeLink('cleanser'),
    },
    {
      text: t(`${PRODUCT_TYPE}.eyeCream`),
      href: getProductTypeLink('eye-cream'),
    },
    {
      text: t(`${PRODUCT_TYPE}.lipEnhancer`),
      href: getProductTypeLink('lip'),
    },
    {
      text: t(`${PRODUCT_TYPE}.masque`),
      href: getProductTypeLink('masque'),
    },
    {
      text: t(`${PRODUCT_TYPE}.moisturiser`),
      href: getProductTypeLink('moisturiser'),
    },
    {
      text: t(`${PRODUCT_TYPE}.serums`),
      href: getProductTypeLink('serum'),
    },
    {
      text: t(`${PRODUCT_TYPE}.skinSupplement`),
      href: getProductTypeLink('supplements'),
    },
    {
      text: t(`${PRODUCT_TYPE}.toner`),
      href: getProductTypeLink('toner'),
    },
  ];
};

const getSkinConcernLinks = (t: TFunction<'navbar'>): NavigationItem[] => {
  return [
    {
      text: t(`${SKIN_CONCERNS}.acne`),
      href: getProductSkinConcernLink('acne'),
    },
    {
      text: t(`${SKIN_CONCERNS}.acneScarring`),
      href: getProductSkinConcernLink('acne-scarring'),
    },
    {
      text: t(`${SKIN_CONCERNS}.ageingSkinAndWrinkles`),
      href: getProductSkinConcernLink('aging'),
    },
    {
      text: t(`${SKIN_CONCERNS}.pigmentation`),
      href: getProductSkinConcernLink('pigmentation'),
    },
    {
      text: t(`${SKIN_CONCERNS}.redness`),
      href: getProductSkinConcernLink('redness'),
    },
    {
      text: t(`${SKIN_CONCERNS}.rosacea`),
      href: getProductSkinConcernLink('rosacea'),
    },
    {
      text: t(`${SKIN_CONCERNS}.sensitivity`),
      href: getProductSkinConcernLink('sensitivity'),
    },
    {
      text: t(`${SKIN_CONCERNS}.sunDamage`),
      href: getProductSkinConcernLink('sun-damage'),
    },
  ];
};

const getSkinTypeLinks = (t: TFunction<'navbar'>): NavigationItem[] => {
  return [
    {
      text: t(`${SKIN_TYPE}.aging`),
      href: getProductSkinTypeLink('aging'),
    },
    {
      text: t(`${SKIN_TYPE}.combination`),
      href: getProductSkinTypeLink('combination'),
    },
    {
      text: t(`${SKIN_TYPE}.dry`),
      href: getProductSkinTypeLink('dry'),
    },
    {
      text: t(`${SKIN_TYPE}.normal`),
      href: getProductSkinTypeLink('normal'),
    },
    {
      text: t(`${SKIN_TYPE}.oily`),
      href: getProductSkinTypeLink('oily'),
    },
    {
      text: t(`${SKIN_TYPE}.sensitive`),
      href: getProductSkinTypeLink('sensitive'),
    },
  ];
};

/** UI-TODO-US: cross-check slugs with Quick Admin (US) */
const getAntiOxidantLinks = (t: TFunction<'navbar'>): NavigationItem[] => {
  return [
    {
      text: t(`${ANTI_OXIDANT}.gelCleanser`),
      href: buildLinkUrl('PRODUCT', { slug: 'antioxidant-gel-cleanser' }),
    },
    {
      text: t(`${ANTI_OXIDANT}.lighteningSerum`),
      href: buildLinkUrl('PRODUCT', { slug: 'antioxidant-lightening-serum' }),
    },
    {
      text: t(`${ANTI_OXIDANT}.sheerDayCream`),
      href: buildLinkUrl('PRODUCT', { slug: 'antioxidant-sheer-day-cream-spf-30' }),
    },
    {
      text: t(`${ANTI_OXIDANT}.soothingSerum`),
      href: buildLinkUrl('PRODUCT', { slug: 'antioxidant-soothing-serum' }),
    },
    {
      text: t(`${ANTI_OXIDANT}.triplePeptideCream`),
      href: buildLinkUrl('PRODUCT', { slug: 'antioxidant-triple-peptide-cream' }),
    },
  ];
};

/** UI-TODO-US: cross-check slugs with Quick Admin (US) */
const getGlycolicLinks = (t: TFunction<'navbar'>): NavigationItem[] => {
  return [
    {
      text: t(`${GLYCOLIC}.gelCleanser`),
      href: buildLinkUrl('PRODUCT', { slug: 'glycolic-gel-cleanser' }),
    },
  ];
};

/** UI-TODO-US: cross-check slugs with Quick Admin (US) */
const getPeptideLinks = (t: TFunction<'navbar'>): NavigationItem[] => {
  return [
    {
      text: t(`${PEPTIDE}.eyeCream`),
      href: buildLinkUrl('PRODUCT', { slug: 'peptide-eye-gel' }),
    },
    {
      text: t(`${PEPTIDE}.hyaluronicSerum`),
      href: buildLinkUrl('PRODUCT', { slug: 'peptide-hyaluronic-serum' }),
    },
  ];
};

/** UI-TODO-US: cross-check slugs with Quick Admin (US) */
const getSalicylicLinks = (t: TFunction<'navbar'>): NavigationItem[] => {
  return [
    {
      text: t(`${SALICYLIC}.gelCleanser`),
      href: buildLinkUrl('PRODUCT', { slug: 'salicylic-gel-cleanser' }),
    },
  ];
};

/** UI-TODO-US: cross-check slugs with Quick Admin (US) */
const getVitaminCLinks = (t: TFunction<'navbar'>): NavigationItem[] => {
  return [
    {
      text: t(`${VITAMIN_C}.ahaBhaCleanser`),
      href: buildLinkUrl('PRODUCT', { slug: 'vitamin-c-aha-bha-cleanser' }),
    },
    {
      text: t(`${VITAMIN_C}.cleansingCream`),
      href: buildLinkUrl('PRODUCT', { slug: 'vitamin-c-cream-cleanser' }),
    },
    {
      text: t(`${VITAMIN_C}.nightCream`),
      href: buildLinkUrl('PRODUCT', { slug: 'vitamin-c-night-cream' }),
    },
  ];
};

const MAP_SHOP_LINKS = (t: TFunction<'navbar'>) => ({
  [ALL]: {
    text: t(`${ALL}.title`),
    children: getAllProductsLinks(t),
  },
  [BRANDS]: {
    text: t(`${BRANDS}.title`),
    children: getBrandLinks(t),
  },
  [PRODUCT_TYPE]: {
    text: t(`${PRODUCT_TYPE}.title`),
    children: getProductTypeLinks(t),
  },
  [SKIN_TYPE]: {
    text: t(`${SKIN_TYPE}.title`),
    children: getSkinTypeLinks(t),
  },
  [SKIN_CONCERNS]: {
    text: t(`${SKIN_CONCERNS}.title`),
    children: getSkinConcernLinks(t),
  },
  [ANTI_OXIDANT]: {
    text: t(`${ANTI_OXIDANT}.title`),
    children: getAntiOxidantLinks(t),
  },
  [GLYCOLIC]: {
    text: t(`${GLYCOLIC}.title`),
    children: getGlycolicLinks(t),
  },
  [PEPTIDE]: {
    text: t(`${PEPTIDE}.title`),
    children: getPeptideLinks(t),
  },
  [SALICYLIC]: {
    text: t(`${SALICYLIC}.title`),
    children: getSalicylicLinks(t),
  },
  [VITAMIN_C]: {
    text: t(`${VITAMIN_C}.title`),
    children: getVitaminCLinks(t),
  },
});

export const getSkinShopLinks = (t: TFunction<'navbar'>): NavigationColumnsType => {
  let mappingMismatchCounter = 0;
  const groups = t('shopGroupsLinks', { returnObjects: true });
  const mapShopLinks = MAP_SHOP_LINKS(t);
  const shopLinks = groups.map((group) =>
    group.map((groupItem) => {
      const mappedGroupItem = mapShopLinks[groupItem as keyof typeof MAP_SHOP_LINKS];
      if (!mappedGroupItem) {
        log({
          category: 'internal',
          message: 'skin_shop_links_group_item_unmapped',
          messageContext: {
            groups,
            group,
            groupItem,
            mappedGroupItem,
          },
          level: 'warn',
        });
        mappingMismatchCounter++;
      }
      return mappedGroupItem;
    })
  );
  if (!shopLinks.length) {
    log({
      category: 'internal',
      message: 'skin_shop_links_is_empty',
      messageContext: {
        groups,
        shopLinks,
      },
      level: 'error',
    });
    if (DASH_ENV !== 'production') throw new Error('Skin Shop links mapping returned empty');
  }
  if (mappingMismatchCounter && DASH_ENV !== 'production') {
    throw new Error(`Skin Shop links mapping is missing ${mappingMismatchCounter} groups items`);
  }

  return shopLinks;
};

export const getTreatmentLinks = (t: TFunction<['navbar', 'common']>): NavigationColumnsType => {
  // UI-TODO: build links from getTreatmentCollections
  // keep this up-to-date with getTreatmentCollections
  const availableColumns = t('navbar:treatmentGroupsLinks', { returnObjects: true });
  const treatmentGroups = t('navbar:treatmentGroups', { returnObjects: true });

  return availableColumns.map((treatmentColumn) => {
    return treatmentColumn.map((_treatmentGroup) => {
      const treatmentGroup = _treatmentGroup as keyof typeof treatmentGroups | 'giftVouchers';
      if (treatmentGroup === 'giftVouchers') {
        return {
          text: t(`navbar:${ALL}.${treatmentGroup}`),
          href: SITE_URLS.GIFT_VOUCHERS,
        };
      }

      const childrenCollections = t(`navbar:treatmentGroups.${treatmentGroup}.collections`, {
        returnObjects: true,
      });
      if (!Array.isArray(childrenCollections) || childrenCollections.length === 0) {
        log({
          category: 'internal',
          message: 'treatment_group_has_no_collections',
          messageContext: {
            treatmentGroup,
          },
          level: 'warn',
        });
      }

      const groupSlug = t(`navbar:treatmentGroups.${treatmentGroup}.slug`) as TreatmentGroupType;
      return {
        text: t(`navbar:treatmentGroups.${treatmentGroup}.label`),
        children:
          childrenCollections?.map((collection) => ({
            text: collection.label,
            href: getTreatmentPageLink(groupSlug, collection.slug),
          })) ?? [],
      };
    });
  });
};

export const getNavigationMobileLinks = (t: TFunction<['navbar', 'common']>): NavigationItem[] => {
  return [
    {
      text: t('navbar:myBookings'),
      href: buildLinkUrl('MY_ACCOUNT_BOOKINGS', {}),
    },
    {
      text: t('navbar:shopTreatments'),
      children: getTreatmentLinks(t).flat(),
    },
    {
      text: t('navbar:shopProducts'),
      children: getSkinShopLinks(t).flat(),
    },
    {
      text: t('common:referAFriend.cta', { amount: REFER_A_FRIEND_REWARD_PRICE }),
      href: SITE_URLS.REFER_A_FRIEND,
    },
  ];
};
