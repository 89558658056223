import { PropsWithChildren } from 'react';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';

import { Text } from '@therapie-ecommerce-ui/ui';

import { SOCIAL_URLS } from '@utils/constants/site-urls.constants';
import FACEBOOK from '@images/social/facebook_icon.svg';
import INSTAGRAM from '@images/social/instagram_icon.svg';
import TIKTOK from '@images/social/tiktok_icon.svg';
import YOUTUBE from '@images/social/youtube_icon.svg';
import LINKEDIN from '@images/social/linkedin_icon.svg';

import styles from './footer-social-links.module.scss';

export const FooterSocialLinks = () => {
  const { t } = useTranslation('footer');

  return (
    <div className={styles['social']}>
      <Text className={styles['social__title']} variant="h5" weight="bold" color="night-200">
        {t('connectWithUs')}
      </Text>
      <div className={styles['social__links']}>
        <Link href={SOCIAL_URLS.facebook}>
          <Image src={FACEBOOK} alt="facebook" />
        </Link>
        <Link href={SOCIAL_URLS.instagram}>
          <Image src={INSTAGRAM} alt="instagram" />
        </Link>
        <Link href={SOCIAL_URLS.tiktok}>
          <Image src={TIKTOK} alt="tiktok" />
        </Link>
        <Link href={SOCIAL_URLS.youtube}>
          <Image src={YOUTUBE} alt="youtube" />
        </Link>
        <Link href={SOCIAL_URLS.linkedin}>
          <Image src={LINKEDIN} alt="linkedin" />
        </Link>
      </div>
    </div>
  );
};

const Link = ({ href, children }: PropsWithChildren<{ href: string }>) => {
  return (
    <a className={styles['social__link']} href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};
