import { AuthHeadersSource } from './authentication.types';

export const VERIFICATION_MESSAGE_TIMEOUT = 1500;

export const COOKIES = {
  DOMAIN: process.env.COOKIE_DOMAIN as string,
  HTTP_ONLY: process.env.COOKIE_HTTP_ONLY === '1',
  SAME_SITE: (process.env.COOKIE_SAME_SITE as any) ?? 'lax',
  SECURE: process.env.COOKIE_SECURE === '1',
};

export const AUTH_HEADERS_SOURCE: AuthHeadersSource =
  (process.env.NEXT_PUBLIC_AUTH_HEADERS_SOURCE as any) ?? 'localStorage';
