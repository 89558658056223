import { useTranslation } from 'next-i18next';

import { IconButton } from '@therapie-ecommerce-ui/ui';

import { useDevice } from '@hooks/useDevice/useDevice';

import { CartItemRemoveButtonProps } from './CartItemRemoveButton.types';

import styles from './cart-item-remove-button.module.scss';

export const CartItemRemoveButton = ({
  handleRemoveItem,
  handleRestoreRemovedItem,
  isRemoved,
}: CartItemRemoveButtonProps) => {
  const { t } = useTranslation('cart');
  const { isMobile } = useDevice();

  return (
    <IconButton
      className={styles['item-remove-button']}
      colorScheme="night"
      onClick={isRemoved ? handleRestoreRemovedItem : handleRemoveItem}
      aria-label={isRemoved ? t('restoreItem') : t('removeItem')}
      size={isMobile ? 'sm' : 'md'}
      iconProps={{
        name: isRemoved ? 'undo' : 'delete_outline',
        size: isMobile ? 'sm' : 'md',
      }}
    />
  );
};
