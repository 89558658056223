import { datadogLogs } from '@datadog/browser-logs';

/**
 *
 * Suggested settings for staging:
 * NEXT_PUBLIC_DATADOG_FORWARD_CONSOLE_LOGS=warning,error
 *
 * Suggested settings for production:
 * NEXT_PUBLIC_DATADOG_FORWARD_CONSOLE_LOGS=info,log,debug,warning,error
 * NEXT_PUBLIC_LOG_DISABLE_CONSOLE=1
 */

export function setupLogging() {
  if (!process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN) return;

  datadogLogs.init({
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN as string,
    site: process.env.NEXT_PUBLIC_DATADOG_SITE as string,
    forwardErrorsToLogs: true,
    // @ts-ignore
    forwardConsoleLogs: process.env.NEXT_PUBLIC_DATADOG_FORWARD_CONSOLE_LOGS!.split(','),
    sampleRate: 100,
    env: process.env.NEXT_PUBLIC_DATADOG_ENV as string,
    service: process.env.NEXT_PUBLIC_DATADOG_SERVICE as string,
    beforeSend: (log) => {
      // discard 404 network errors
      if (log.http && log.http.status_code === 404) {
        return false;
      }
    },
  });
}
