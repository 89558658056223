import { Locale } from 'date-fns';
import { enGB, enUS } from 'date-fns/locale';
import { log } from '@core/logging/logging';
import { isValidTimeZone } from '@utils/functions/dates';

export const MAP_TIMEZONE_TO_LOCALE = {
  'America/New_York': enUS,
} as { [timeZone: string]: Locale };

const ENV_TIMEZONE = process.env.NEXT_PUBLIC_DEFAULT_TIMEZONE;

if (!isValidTimeZone(ENV_TIMEZONE)) {
  log({
    level: 'error',
    category: 'internal',
    message: 'invalid_default_timezone',
    messageContext: { NEXT_PUBLIC_DEFAULT_TIMEZONE: ENV_TIMEZONE },
  });
}

// If env variable is an invalid Timezone, defaulted to `Dublin` timezone
export const SITE_TIMEZONE = isValidTimeZone(ENV_TIMEZONE) ? ENV_TIMEZONE! : 'Europe/Dublin';

export const CLIENT_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

// Default locale (language) is English if the timezone hasn't been mapped with a locale
export const SITE_LOCALE = MAP_TIMEZONE_TO_LOCALE[SITE_TIMEZONE] ?? enGB;

export const DATE_FORMATS = {
  // 2022-01-01
  BACKEND_DATE: 'yyyy-MM-dd',
  // 08:30 AM, 4:30 PM
  AM_PM: 'p',
  // 15:00
  HOURS_MINUTES: 'HH:mm',
  // 01/01/2022
  DAY_MONTH_YEAR: 'dd/MM/yyyy',
  // Saturday January 1st
  READABLE_DAY_MONTH: 'EEEE MMMM do',
  // Wed 1st, Fri 17th, ...
  COMPACT_DAY_WEEK: 'eee do',
  // Wednesday
  DAY_OF_WEEK_LONG_STRING: 'eeee',
} as const;
