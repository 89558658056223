import { ReactElement } from 'react';

import { cx } from '@therapie-ecommerce-ui/ui';

import { Header } from '@components/layout/basic-layout/header/Header';

import { Wrapper } from '../default-layout/wrapper/Wrapper';
import { Content } from '../default-layout/content/Content';
import { BasicLayoutProps, BasicLayoutCustomisationProps } from './BasicLayout.types';

import styles from './basic-layout.module.scss';

export const BasicLayout = ({
  children,
  headerClassName,
  contentClassName,
  hasGreyBg = false,
  hasHeader = true,
}: BasicLayoutProps) => (
  <Wrapper>
    {hasHeader && <Header className={cx(styles['layout__header'], headerClassName)} />}
    <Content
      hasSecondaryBg={hasGreyBg}
      hasFooter={false}
      className={cx(styles['layout__content'], contentClassName)}
    >
      {children}
    </Content>
  </Wrapper>
);

export const getBasicLayout = (customProps: BasicLayoutCustomisationProps = {}) => {
  const getLayout = (page: ReactElement) => <BasicLayout {...customProps}>{page}</BasicLayout>;
  return getLayout;
};
