import { useIsMutating, useMutation, UseMutationOptions } from 'react-query';
import { MUTATION_KEYS } from '@/api/mutations/mutations.constants';
import { graphqlGatewayClient } from '@core/graphql/gateway/gateway';
import { generateAuthHeaders } from '@features/authentication/headers';
import {
  DeletePaymentIntentMutation,
  DeletePaymentIntentMutationVariables,
  PaymentIntentType,
} from '@/data/graphql/types';
import {
  ParsedPaymentIntent,
  ParsedSetupIntent,
  parseIntentString,
} from '@features/stripe/utils/intents';

const MUTATION_KEY_MAP = {
  [PaymentIntentType.PaymentIntent]: MUTATION_KEYS.CANCEL_PAYMENT_INTENT,
  [PaymentIntentType.SetupIntent]: MUTATION_KEYS.CANCEL_SETUP_INTENT,
};

export function useCancelIntentMutation<TIntent extends PaymentIntentType>(
  intentType: TIntent,
  options: UseMutationOptions<
    TIntent extends PaymentIntentType.PaymentIntent ? ParsedPaymentIntent : ParsedSetupIntent,
    Error,
    Omit<DeletePaymentIntentMutationVariables, 'intentType'> | undefined
  > = {}
) {
  return useMutation<
    TIntent extends PaymentIntentType.PaymentIntent ? ParsedPaymentIntent : ParsedSetupIntent,
    Error,
    Omit<DeletePaymentIntentMutationVariables, 'intentType'>,
    unknown
  >(
    async ({ intentId }) => {
      const { deletePaymentIntent: response } = await graphqlGatewayClient.post<
        DeletePaymentIntentMutationVariables,
        DeletePaymentIntentMutation
      >(
        '/DeletePaymentIntent',
        { intentId, intentType },
        { headers: generateAuthHeaders() },
        { keepalive: true }
      );

      return parseIntentString(response?.intent) as any;
    },
    {
      mutationKey: MUTATION_KEY_MAP[intentType],
      ...options,
    }
  );
}

export const useIsCancelIntentLoading = (intentType: PaymentIntentType) =>
  !!useIsMutating({
    mutationKey: [MUTATION_KEY_MAP[intentType]],
  });
