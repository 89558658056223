import { useTranslation } from 'next-i18next';
import { Button } from '@therapie-ecommerce-ui/ui';
import { SkipContentProps } from './SkipContent.types';

import styles from './skip-content.module.scss';

export const SkipContent = ({ href = '#main' }: SkipContentProps) => {
  const { t } = useTranslation('common');

  return (
    <Button tag="a" href={href} className={styles['skip-content']}>
      {t('buttons.skipContent')}
    </Button>
  );
};
