import React from 'react';
import NextLinkBase from 'next/link';
import { Button, cx, Text } from '@therapie-ecommerce-ui/ui';
import { useIsWebView } from '@features/web-view/hooks/useIsWebView/useIsWebView';
import { postMessageToMobileApp } from '@features/web-view/utils/post-message-to-mobile-app';

import { separateLinkProps } from './Link.utils';
import { LinkButtonProps, LinkTextProps, LinkProps } from './Link.types';

import styles from './link.module.scss';

/**
 * A wrapper around Next.js Link component. In webviews, it will use postMessage to communicate with the
 * mobile app and the mobile app will handle navigation.
 * On web, it renders a Next.js Link component.
 */

/**
 * Used to wrap every other component other than `Text` and `Button`
 */
export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const { isWebView } = useIsWebView();

  const {
    linkProps: { href, passHref, ...restLinkProps },
    className,
    children,
    isLinkOnWebView,
    onClick,
    ...restProps
  } = separateLinkProps(props);

  if (isWebView && !isLinkOnWebView) {
    return (
      <button
        className={cx(styles['link--web-view'], className)}
        onClick={(e) => {
          postMessageToMobileApp({
            'NAVIGATION/LINK': { href },
          });
          onClick?.(e);
        }}
        type="button"
      >
        {children}
      </button>
    );
  }

  return (
    <NextLinkBase href={href} {...restLinkProps}>
      <a ref={ref} className={cx(styles['link'], className)} onClick={onClick} {...restProps}>
        {children}
      </a>
    </NextLinkBase>
  );
});

/**
 * Used to display a `Link` with a `Text` component in it
 */
export const LinkText = React.forwardRef<HTMLAnchorElement, LinkTextProps>((props, ref) => {
  const { isWebView } = useIsWebView();

  const {
    linkProps: { href, ...restLinkProps },
    className,
    children,
    isLinkOnWebView,
    textProps,
    onClick,
    ...restProps
  } = separateLinkProps(props);

  if (isWebView && !isLinkOnWebView) {
    return (
      <button
        className={cx(styles['link--web-view'], className)}
        onClick={(e) => {
          postMessageToMobileApp({
            'NAVIGATION/LINK': { href },
          });
          onClick?.(e);
        }}
        type="button"
      >
        <Text ref={ref} className={className} color="trp-purple" {...textProps}>
          {children}
        </Text>
      </button>
    );
  }

  return (
    <NextLinkBase href={href} {...restLinkProps}>
      <Text
        tag="a"
        ref={ref}
        className={className}
        color="trp-purple"
        onClick={onClick}
        {...textProps}
        {...restProps}
      >
        {children}
      </Text>
    </NextLinkBase>
  );
});

/**
 * Used to display a `Link` with a `Button` component in it
 */
export const LinkButton = React.forwardRef<HTMLButtonElement, LinkButtonProps>((props, ref) => {
  const { isWebView } = useIsWebView();
  const {
    linkProps: { href, ...restLinkProps },
    children,
    isLoading,
    isDisabled,
    onClick,
    isLinkOnWebView = false,
    ...restProps
  } = separateLinkProps(props);

  if (isWebView && !isLinkOnWebView) {
    return (
      <Button
        onClick={(e) => {
          postMessageToMobileApp({
            'NAVIGATION/LINK': { href },
          });
          onClick?.(e);
        }}
        isDisabled={isDisabled}
        isLoading={isLoading}
        {...restProps}
      >
        {children}
      </Button>
    );
  }

  return (
    <NextLinkBase href={href} {...restLinkProps}>
      <Button
        ref={ref}
        tag={isLoading || isDisabled ? 'button' : 'a'}
        isDisabled={isDisabled}
        isLoading={isLoading}
        onClick={onClick}
        {...restProps}
      >
        {children}
      </Button>
    </NextLinkBase>
  );
});

Link.displayName = 'Link';
LinkText.displayName = 'LinkText';
LinkButton.displayName = 'LinkButton';
