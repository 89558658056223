import { useState, useEffect } from 'react';

import { updateAddItemOperationCartState } from '@features/cart-and-checkout/store/cart.store';
import { useCartOperations } from '@features/cart-and-checkout/hooks/useCartOperations';
import { useRouteChange } from '@hooks/useRouteChange/useRouteChange';
import { log } from '@core/logging/logging';
import { UseCartAddedItemProps } from './useCartAddedItem.types';

export const useCartAddedItem = ({ isMobile }: UseCartAddedItemProps) => {
  const {
    itemId,
    isSuccess,
    subtotalCost = 0,
    totalItems = 0,
    ...restAddedItem
  } = useCartOperations('add_item_to_cart');

  const { price, name, quantity } = restAddedItem;

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (itemId && isSuccess) {
      setIsOpen(true);

      if (!price || !name || !quantity) {
        log({
          level: 'error',
          category: 'query',
          message: 'missing_product_info_on_add_to_cart',
          messageContext: {
            source: 'useCartAddedItem',
            price,
            name,
            quantity,
          },
        });
      }
    }
  }, [itemId, isSuccess, price, name, quantity]);

  const handleOnClose = () => {
    setIsOpen(false);
  };

  const handleOnExited = () => {
    // Passing nothing will reset
    updateAddItemOperationCartState();
  };

  useRouteChange({ onRouteChangeStart: handleOnClose });

  /**
   * When the value of `isMobile` changes, the rendered component is changed.
   * However, there is a bug whereby the new component does not animate in and
   * instead the screen becomes stuck with no component display.
   *
   * As a result, when ever isMobile changes `isOpen` needs to be set to `false`
   * which will trigger it to be re-opened.
   */
  useEffect(() => {
    setIsOpen(false);
  }, [isMobile]);

  const item = {
    id: itemId,
    ...restAddedItem,
  };

  return {
    isOpen,
    onClose: handleOnClose,
    onExited: handleOnExited,
    item,
    totalCartItems: totalItems,
    cartSubtotal: subtotalCost,
  };
};
