import { useTranslation } from 'next-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { CartEventsPayloads } from '@therapie-ecommerce-ui/events';
import { ErrorWithResponse } from '@core/fetch/client.types';
import { graphqlGatewayClient } from '@core/graphql/gateway/gateway';
import { log } from '@core/logging/logging';
import { generateAuthHeaders } from '@features/authentication/headers';
import { buildLinkUrlForCartItem } from '@features/booking/utils/booking.utils';
import { CART_ERRORS, CartError } from '@features/cart-and-checkout/errors';
import { useCartId } from '@features/cart-and-checkout/hooks/useCartId';
import {
  CART_ACTIONS,
  DEFAULT_CART_OPERATIONS,
} from '@features/cart-and-checkout/store/cart.constants';
import {
  updateAddItemOperationCartState,
  updateGlobalOperationsCartState,
} from '@features/cart-and-checkout/store/cart.store';
import { setCartAndCheckoutSteps } from '@features/cart-and-checkout/utils/cart-and-checkout.utils';
import { useErrorToast } from '@hooks/useErrorToast/useErrorToast';
import { useSegmentAnalytics } from '@hooks/useSegmentAnalytics/useSegmentAnalytics';
import { formatAddedItem, setItemDataInQueryCache } from '@utils/enrichment/item-enrichment';
import {
  AddCartItemMutation,
  AddCartItemMutationVariables,
  CartItemType,
} from '@/data/graphql/types';

export function useAddToCartMutation() {
  const { t } = useTranslation('common');
  const cartId = useCartId();
  const queryClient = useQueryClient();
  const errorToast = useErrorToast();
  const { sendTrackEvent } = useSegmentAnalytics();

  return useMutation<
    AddCartItemMutation,
    ErrorWithResponse,
    CartEventsPayloads['CART/ADD-ITEM'],
    unknown
  >(
    async ({ itemId, quantity, type, price, images, name }) => {
      const payload = {
        cartId: cartId!,
        itemId,
        quantity: quantity ?? 1,
        type: type as CartItemType,
      };

      if (!cartId) {
        throw new CartError({
          name: CART_ERRORS.CART_ID_NOT_FOUND,
          message: 'cart_id_not_found_on_add_to_cart',
        });
      }

      updateGlobalOperationsCartState(1);
      updateAddItemOperationCartState({
        price,
        quantity,
        images,
        name,
        itemId,
        type,
        isSuccess: false,
      });

      return await graphqlGatewayClient.post<AddCartItemMutationVariables, AddCartItemMutation>(
        '/AddCartItem',
        payload,
        { headers: generateAuthHeaders() }
      );
    },
    {
      mutationKey: CART_ACTIONS.ADD_ITEM,
      onSuccess: ({ addCartItem: cart }, item) => {
        setCartAndCheckoutSteps(cart!);
        setItemDataInQueryCache({ item: formatAddedItem(item), queryClient });

        sendTrackEvent({
          event: 'Product Added',
          properties: {
            cartId: cart?.id!,
            productId: item.itemId,
            crmProductId: item.crmId!,
            type: item.type,
            treatmentType: item.treatmentType,
            category: item.treatmentCollection,
            name: item.name,
            price: item.price,
            quantity: item.quantity,
            url: buildLinkUrlForCartItem(item.type, item.slug),
          },
        });
      },
      onSettled: (response, error) => {
        if (error) {
          updateAddItemOperationCartState({
            ...DEFAULT_CART_OPERATIONS.add_item_to_cart,
          });
        } else {
          const { addCartItem: cart } = response ?? {};
          updateAddItemOperationCartState({
            isSuccess: true,
            totalItems: cart?.items?.reduce((acc, item) => acc + item.quantity, 0),
            subtotalCost: cart?.subtotalCost ?? 0,
          });
        }
        updateGlobalOperationsCartState(-1);
      },
      onError: (e) => {
        if (e instanceof CartError) {
          return log({
            level: 'error',
            category: 'query',
            ...e,
            userMessage: t('errors.errorMustRefresh'),
          });
        }
        errorToast();
      },
    }
  );
}
