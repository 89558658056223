import { cx, Text } from '@therapie-ecommerce-ui/ui';

import { Price } from '@components/price/Price';

import { CartItemSubtotalProps } from './CartItemSubtotal.types';

import styles from './cart-item-subtotal.module.scss';

export const CartItemSubtotal = ({
  className,
  textProps,
  totalPrice,
  isRemoved,
  outOfStock,
}: CartItemSubtotalProps) => {
  if (isRemoved || outOfStock) return null;

  return (
    <Text
      className={cx(styles['item-subtotal'], className)}
      variant="h2"
      weight="light"
      {...textProps}
    >
      <Price>{totalPrice}</Price>
    </Text>
  );
};
