import React from 'react';

import { ResponsiveSSR } from '@components/responsive-ssr/ResponsiveSSR';

import { HeaderDesktop } from './header-desktop/HeaderDesktop';
import { HeaderMobile } from './header-mobile/HeaderMobile';
import { SkipContent } from '../skip-content/SkipContent';
import { ServiceBar } from '../service-bar/ServiceBar';
import { FreeConsultationBanner } from '../free-consultation-banner/FreeConsultationBanner';

import styles from './header.module.scss';

export const Header = ({
  hasNavigation = true,
  hasBanner = true,
}: {
  hasNavigation?: boolean;
  hasBanner?: boolean;
}) => {
  return (
    <>
      {hasNavigation && <SkipContent />}
      <header className={styles['header']}>
        {hasBanner && <FreeConsultationBanner />}
        {hasNavigation && (
          <>
            <ResponsiveSSR down="lg">
              <HeaderMobile hasBanner={hasBanner} />
            </ResponsiveSSR>
            <ResponsiveSSR up="xl">
              <ServiceBar />
              <HeaderDesktop />
            </ResponsiveSSR>
          </>
        )}
      </header>
    </>
  );
};
