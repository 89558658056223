import { useEffect } from 'react';
import Head from 'next/head';
import { useAuthenticationStore } from '@therapie-ecommerce-ui/auth';

const PRELOADER_BG = 'rgb(255, 255, 255)';

export const NO_PAGE_FLICKER_CLASSNAME = 'render';

export const hideBodyCss = `
  body::before {
    content: '';
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: ${PRELOADER_BG};
  }
  
  .${NO_PAGE_FLICKER_CLASSNAME} body::before {
    display: none;
  }
`;

const noscriptCSS = `
  body::before {
    content: none
  }
`;

export function NoPageFlicker() {
  const isInitialised = useAuthenticationStore((s) => s.isInitialised);

  useEffect(() => {
    if (isInitialised) {
      document.documentElement.classList.add(NO_PAGE_FLICKER_CLASSNAME);
    }
  }, [isInitialised]);

  return (
    <Head>
      <style dangerouslySetInnerHTML={{ __html: hideBodyCss }} />
      <noscript>
        <style dangerouslySetInnerHTML={{ __html: noscriptCSS }} />
      </noscript>
    </Head>
  );
}
