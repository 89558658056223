import React from 'react';
import { CartAddedItem } from '@features/cart-and-checkout/components/cart-added-item/CartAddedItem';
import { useCartSetup } from '@features/cart-and-checkout/setup/useCartSetup';

export const CartProvider = ({ children }: { children: React.ReactNode }) => {
  useCartSetup();

  return (
    <>
      <CartAddedItem />
      {children}
    </>
  );
};
