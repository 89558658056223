import produce from 'immer';
import create from 'zustand';
import shallow from 'zustand/shallow';
import { zsDevtools } from '@core/zustand/zustand.utils';
import { CHECKOUT_ACTIONS } from './checkout.constants';
import { CheckoutState, InitialDataType } from './checkout.types';

const CHECKOUT_INITIAL_DATA_VALUES: InitialDataType = {
  status: 'fetching_prerequisites',
  voucher: null,
  loyaltyPoints: null,
  errors: {
    paymentError: null,
    transactionError: null,
  },
  checkoutData: {
    billingAddress: {},
    shippingAddress: {},
    preferredClinic: null,
    voucher: null,
    order: {
      id: null,
      snapshot: null,
    },
  },
  steps: {
    SHIPPING_ADDRESS: true,
    BILLING_ADDRESS: true,
    PREFERRED_CLINIC: false,
    PAYMENT_METHOD: true,
  },
  initialStep: null,
};

export const useCheckoutStore = create<CheckoutState>(
  zsDevtools(
    (set) => ({
      ...CHECKOUT_INITIAL_DATA_VALUES,
      actions: {
        setCheckoutSteps: (steps) =>
          set(
            produce<CheckoutState>((state) => {
              state.steps = steps(state.steps);
            }),
            false,
            CHECKOUT_ACTIONS.SET_STEPS
          ),
        setInitialCheckoutStep: (step) =>
          set(
            produce<CheckoutState>((state) => {
              state.initialStep = step;
            }),
            false,
            CHECKOUT_ACTIONS.SET_INITIAL_STEP
          ),
        setCheckoutData: (checkoutData) =>
          set(
            produce<CheckoutState>((state) => {
              if (typeof checkoutData === 'function') {
                state.checkoutData = checkoutData(state.checkoutData);
                return;
              }
              state.checkoutData = {
                ...state.checkoutData,
                ...checkoutData,
              };
            }),
            false,
            CHECKOUT_ACTIONS.SET_CHECKOUT_DATA
          ),
        setVoucher: (voucher) =>
          set(
            produce<CheckoutState>((state) => {
              state.voucher = voucher;
            }),
            false,
            CHECKOUT_ACTIONS.SET_VOUCHER
          ),
        removeVoucher: () =>
          set(
            produce<CheckoutState>((state) => {
              state.voucher = null;
            }),
            false,
            CHECKOUT_ACTIONS.REMOVE_VOUCHER
          ),
        setLoyaltyPoints: (loyaltyPoints) =>
          set(
            produce<CheckoutState>((state) => {
              state.loyaltyPoints = loyaltyPoints;
            }),
            false,
            CHECKOUT_ACTIONS.SET_LOYALTY_POINTS
          ),
        setCheckoutStatus: (status) =>
          set(
            produce<CheckoutState>((state) => {
              if (status === 'fetching_prerequisites') {
                state.errors = {
                  paymentError: null,
                  transactionError: null,
                };
              }
              state.status = status;
            }),
            false,
            CHECKOUT_ACTIONS.SET_CHECKOUT_STATUS
          ),
        setCheckoutError: (key, payload) =>
          set(
            produce<CheckoutState>((state) => {
              state.errors = {
                ...state.errors,
                [key]: payload,
              };
            }),
            false,
            CHECKOUT_ACTIONS.SET_CHECKOUT_ERROR
          ),
        resetCheckout: () =>
          set(() => CHECKOUT_INITIAL_DATA_VALUES, false, CHECKOUT_ACTIONS.RESET_CHECKOUT),
      },
    }),
    {
      name: 'CHECKOUT',
    }
  )
);

export const checkoutActionsState = useCheckoutStore.getState().actions;

export const useCheckoutStatus = () =>
  useCheckoutStore(
    ({ status: checkoutStatus, actions: { setCheckoutStatus } }) => ({
      checkoutStatus,
      setCheckoutStatus,
    }),
    shallow
  );
export const useCheckoutVoucher = () => useCheckoutStore((state) => state.voucher);
export const useCheckoutLoyaltyPoints = () => useCheckoutStore((state) => state.loyaltyPoints);

export const useCheckoutError = () =>
  useCheckoutStore(
    ({ errors, actions: { setCheckoutError } }) => ({
      errors,
      setTransactionError: (payload: CheckoutState['errors']['transactionError']) =>
        setCheckoutError('transactionError', payload),
      setPaymentError: (payload: CheckoutState['errors']['paymentError']) =>
        setCheckoutError('paymentError', payload),
    }),
    shallow
  );
export const useCheckoutData = () => useCheckoutStore((state) => state.checkoutData);
export const useCheckoutSteps = () => useCheckoutStore((state) => state.steps);
export const useCheckoutInitialStep = () => useCheckoutStore((state) => state.initialStep);
