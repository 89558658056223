import { generateMediaQueries, BreakpointsType } from '@therapie-ecommerce-ui/ui';

export const MEDIA_BREAKPOINTS: BreakpointsType = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export const RESPONSIVE_MEDIA_QUERIES = generateMediaQueries(MEDIA_BREAKPOINTS);
