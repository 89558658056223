import { ENV_REGION } from '@utils/constants/region.constants';
import { convertToStripeFormat } from '../utils/amounts';
import { getCountryFromRegion } from '../utils/intents';

export const DEFAULT_CONFIG = {
  stripe: null,
  elements: null,
  options: undefined,
};

export const STORE_ACTIONS = {
  SET_PAYMENT_INTENT: 'SET_PAYMENT_INTENT',
  SET_SETUP_INTENT: 'SET_SETUP_INTENT',
  CLEAR_STORE: 'CLEAR_STORE',
  SET_CONFIG: 'SET_CONFIG',
  SET_STATUS: 'SET_STATUS',
  SET_PAYMENT_MODE: 'SET_PAYMENT_MODE',
  RESET_PAYMENT_INTENT: 'RESET_PAYMENT_INTENT',
  RESET_SETUP_INTENT: 'RESET_SETUP_INTENT',
  RESET_INTENTS: 'RESET_INTENTS',
};

export const STRIPE_LS_KEY = 'USER_PAYMENTS';

export const STRIPE_COUNTRY = getCountryFromRegion(ENV_REGION);

export const STRIPE_AFFIRM_MINIMUM_THRESHOLD = convertToStripeFormat(50);
