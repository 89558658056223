import Script from 'next/script';

import * as Snippet from '@segment/snippet';

const SEGMENT_WRITE_KEY = process.env.NEXT_PUBLIC_SEGMENT_ANALYTICS_WRITE_KEY;

const renderSnippet = () => {
  const opts: Snippet.Options = {
    apiKey: SEGMENT_WRITE_KEY,
    page: false,
  };

  if (process.env.NODE_ENV === 'development') {
    return Snippet.max(opts);
  }

  return Snippet.min(opts);
};

/**
 * Script to download Segments analytics.js snippet
 *
 * https://www.npmjs.com/package/@segment/snippet
 */
export const SegmentAnalytics = () => {
  if (!process.env.NEXT_PUBLIC_SEGMENT_ANALYTICS_WRITE_KEY) return null;

  return (
    <Script
      id="segment-analytics"
      dangerouslySetInnerHTML={{ __html: renderSnippet() }}
      crossOrigin="anonymous"
    />
  );
};
