import { ReactElement } from 'react';
import { DefaultLayoutCustomisationProps, DefaultLayoutProps } from '../Layout.types';
import { Content } from './content/Content';
import { Header } from './header/Header';
import { Wrapper } from './wrapper/Wrapper';

export const DefaultLayout = ({
  children,
  className,
  hasGreyBg,
  hasHeader = true,
  hasFooter = true,
  hasNavigation = true,
  hasBanner = true,
}: DefaultLayoutProps) => (
  <Wrapper className={className}>
    {hasHeader && <Header hasNavigation={hasNavigation} hasBanner={hasBanner} />}
    <Content hasSecondaryBg={hasGreyBg} hasFooter={hasFooter}>
      {children}
    </Content>
  </Wrapper>
);

export const getDefaultLayout = (customProps: DefaultLayoutCustomisationProps = {}) => {
  const customDefaultLayout = (page: ReactElement) => (
    <DefaultLayout {...customProps}>{page}</DefaultLayout>
  );
  return customDefaultLayout;
};
