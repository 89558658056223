import { useTranslation } from 'next-i18next';

import { cx, Icon } from '@therapie-ecommerce-ui/ui';

import { LinkButton } from '@components/link/Link';
import { LogOut } from '@features/authentication/components/log-out/LogOut';
import { SITE_URLS } from '@utils/constants/site-urls.constants';

import { ServiceBarSubmenuProps } from '../../ServiceBar.types';

import styles from '../../service-bar.module.scss';

export const MyAccountMenu = ({ className }: ServiceBarSubmenuProps) => {
  const { t } = useTranslation('common');
  return (
    <div className={cx(styles['submenu'], className)}>
      <LinkButton
        href={SITE_URLS.MY_ACCOUNT_TREATMENTS}
        className={styles['submenu__button']}
        variant="text"
        leftIcon={<Icon family="therapie" name="assignment" />}
      >
        {t('buttons.myTreatments')}
      </LinkButton>
      <LinkButton
        href={SITE_URLS.MY_ACCOUNT_BOOKINGS}
        className={styles['submenu__button']}
        variant="text"
        leftIcon={<Icon family="therapie" name="calendar_event" />}
      >
        {t('buttons.myBookings')}
      </LinkButton>
      <LinkButton
        href={SITE_URLS.MY_ACCOUNT_DETAILS}
        className={styles['submenu__button']}
        variant="text"
        leftIcon={<Icon family="therapie" name="client_badge" />}
      >
        {t('buttons.myDetails')}
      </LinkButton>
      <LogOut className={styles['submenu__button']} />
    </div>
  );
};
