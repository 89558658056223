import { Text } from '@therapie-ecommerce-ui/ui';

import { CartItemPrice } from '../cart-item-price/CartItemPrice';
import { UseCartItemReturnType } from '../hooks/useCartItem.types';

import styles from './cart-item-quantity.module.scss';

export const CartItemQuantity = (cartItemProps: UseCartItemReturnType) => {
  const { isRemoved, outOfStock, quantity } = cartItemProps;

  if (isRemoved || outOfStock) return null;

  return (
    <div className={styles['quantity']}>
      <CartItemPrice textProps={{ variant: 'h4', weight: 'light' }} {...cartItemProps} />
      <Text variant="h4" weight="light">
        {`x ${quantity}`}
      </Text>
    </div>
  );
};
