import { isUSRegion } from './region';
import { Address } from '@/data/graphql/types';
import { SiteRegionURLsType } from '@utils/types/url.types';

export function checkKeysForInvalidValues(address: Address | null) {
  if (!address) {
    return false;
  }
  const keysToCheck = [
    'city',
    'country',
    'firstName',
    'lastName',
    'line1',
    'phoneNumber',
    'postcode',
    isUSRegion() ? 'state' : 'county',
  ] as const;

  return keysToCheck.every((key) => !!address[key]);
}

export function getCountiesForRegion(region: SiteRegionURLsType) {
  switch (region) {
    case 'roi':
      return ROI_COUNTIES_LIST;
    case 'uk':
      return UK_COUNTIES_LIST;
    case 'us':
      return US_STATES_LIST;
  }
}

const ROI_COUNTIES_LIST = [
  'CARLOW',
  'CAVAN',
  'CLARE',
  'CORK',
  'DONEGAL',
  'CO. DUBLIN',
  'DUBLIN 1',
  'DUBLIN 10',
  'DUBLIN 11',
  'DUBLIN 12',
  'DUBLIN 13',
  'DUBLIN 14',
  'DUBLIN 15',
  'DUBLIN 16',
  'DUBLIN 17',
  'DUBLIN 18',
  'DUBLIN 2',
  'DUBLIN 20',
  'DUBLIN 22',
  'DUBLIN 24',
  'DUBLIN 3',
  'DUBLIN 4',
  'DUBLIN 5',
  'DUBLIN 6',
  'DUBLIN 6W',
  'DUBLIN 7',
  'DUBLIN 8',
  'DUBLIN 9',
  'GALWAY',
  'KERRY',
  'KILDARE',
  'KILKENNY',
  'LAOIS',
  'LEITRIM',
  'LIMERICK',
  'LONGFORD',
  'LOUTH',
  'MAYO',
  'MEATH',
  'MONAGHAN',
  'OFFALY',
  'ROSCOMMON',
  'SLIGO',
  'TIPPERARY',
  'WATERFORD',
  'WESTMEATH',
  'WEXFORD',
  'WICKLOW',
];

const UK_COUNTIES_LIST = [
  'ABERDEEN CITY',
  'ABERDEENSHIRE',
  'ANGLESEY',
  'ANGUS',
  'ANTRIM',
  'ARGYLL AND BUTE',
  'ARGYLLSHIRE',
  'ARMAGH',
  'AVON',
  'AYRSHIRE',
  'BANFFSHIRE',
  'BEDFORDSHIRE',
  'BERKSHIRE',
  'BERWICKSHIRE',
  'BRECONSHIRE',
  'BUCKINGHAMSHIRE',
  'CAERNARVONSHIRE',
  'CAMBRIDGESHIRE',
  'CARDIGANSHIRE',
  'CARMARTHENSHIRE',
  'CHESHIRE',
  'CITY OF EDINBURGH',
  'CLACKMANNANSHIRE',
  'CLEVELAND',
  'CORNWALL',
  'CUMBRIA',
  'DENBIGHSHIRE',
  'DERBYSHIRE',
  'DERRY AND LONDONDERRY',
  'DEVON',
  'DORSET',
  'DOWN',
  'DUMFRIES AND GALLOWAY',
  'DUMFRIESSHIRE',
  'DUNBARTONSHIRE',
  'DUNDEE CITY',
  'DURHAM',
  'DYFED',
  'EAST AYRSHIRE',
  'EAST DUNBARTONSHIRE',
  'EAST LOTHIAN',
  'EAST RENFREWSHIRE',
  'EAST SUSSEX',
  'EILEAN SIAR',
  'ESSEX',
  'FALKIRK',
  'FERMANAGH',
  'FIFE',
  'FLINTSHIRE',
  'GLAMORGAN',
  'GLASGOW CITY',
  'GLOUCESTERSHIRE',
  'GRAMPIAN',
  'GREATER LONDON',
  'GREATER MANCHESTER',
  'GUERNSEY',
  'GWENT',
  'GWYNEDD',
  'HAMPSHIRE',
  'HEREFORD AND WORCESTER',
  'HEREFORDSHIRE',
  'HERTFORDSHIRE',
  'HIGHLAND',
  'HUMBERSIDE',
  'HUNTINGDONSHIRE',
  'INVERCLYDE',
  'INVERNESS-SHIRE',
  'ISLE OF MAN',
  'ISLE OF WIGHT',
  'JERSEY',
  'KENT',
  'KINCARDINESHIRE',
  'KINROSS-SHIRE',
  'KIRKCUDBRIGHTSHIRE',
  'LANARKSHIRE',
  'LANCASHIRE',
  'LEICESTERSHIRE',
  'LINCOLNSHIRE',
  'LONDON',
  'LOTHIAN',
  'MERIONETHSHIRE',
  'MERSEYSIDE',
  'MID GLAMORGAN',
  'MIDDLESEX',
  'MIDLOTHIAN',
  'MONMOUTHSHIRE',
  'MONTGOMERYSHIRE',
  'MORAY',
  'MORAYSHIRE',
  'NAIRN',
  'NORFOLK',
  'NORTH AYRSHIRE',
  'NORTH LANARKSHIRE',
  'NORTH YORKSHIRE',
  'NORTHAMPTONSHIRE',
  'NORTHUMBERLAND',
  'NOTTINGHAMSHIRE',
  'ORKNEY ISLANDS',
  'OXFORDSHIRE',
  'PEEBLES-SHIRE',
  'PEMBROKESHIRE',
  'PERTH AND KINROSS',
  'PERTH',
  'POWYS',
  'RADNORSHIRE',
  'RENFREWSHIRE',
  'ROSS AND CROMARTY',
  'ROXBURGHSHIRE',
  'RUTLAND',
  'SCOTTISH BORDERS',
  'SELKIRKSHIRE',
  'SHETLAND ISLANDS',
  'SHETLAND',
  'SHROPSHIRE',
  'SOMERSET',
  'SOUTH AYRSHIRE',
  'SOUTH GLAMORGAN',
  'SOUTH LANARKSHIRE',
  'SOUTH YORKSHIRE',
  'STAFFORDSHIRE',
  'STIRLING',
  'STIRLINGSHIRE',
  'STRATHCLYDE',
  'SUFFOLK',
  'SURREY',
  'SUSSEX',
  'SUTHERLAND',
  'TAYSIDE',
  'TYNE AND WEAR',
  'TYRONE',
  'WARWICKSHIRE',
  'WEST BERKSHIRE',
  'WEST DUNBARTONSHIRE',
  'WEST GLAMORGAN',
  'WEST LOTHIAN',
  'WEST MIDLANDS',
  'WEST SUSSEX',
  'WEST YORKSHIRE',
  'WESTMORLAND',
  'WIGTOWNSHIRE',
  'WILTSHIRE',
  'WORCESTERSHIRE',
  'YORKSHIRE',
];

const US_STATES_LIST = [
  'ALABAMA',
  'ALASKA',
  'ARIZONA',
  'ARKANSAS',
  'CALIFORNIA',
  'COLORADO',
  'CONNECTICUT',
  'DELAWARE',
  'FLORIDA',
  'GEORGIA',
  'HAWAII',
  'IDAHO',
  'ILLINOIS',
  'INDIANA',
  'IOWA',
  'KANSAS',
  'KENTUCKY',
  'LOUISIANA',
  'MAINE',
  'MARYLAND',
  'MASSACHUSETTS',
  'MICHIGAN',
  'MINNESOTA',
  'MISSISSIPPI',
  'MISSOURI',
  'MONTANA',
  'NEBRASKA',
  'NEVADA',
  'NEW HAMPSHIRE',
  'NEW JERSEY',
  'NEW MEXICO',
  'NEW YORK',
  'NORTH CAROLINA',
  'NORTH DAKOTA',
  'OHIO',
  'OKLAHOMA',
  'OREGON',
  'PENNSYLVANIA',
  'RHODE ISLAND',
  'SOUTH CAROLINA',
  'SOUTH DAKOTA',
  'TENNESSEE',
  'TEXAS',
  'UTAH',
  'VERMONT',
  'VIRGINIA',
  'WASHINGTON',
  'WEST VIRGINIA',
  'WISCONSIN',
  'WYOMING',
];
