import React, { useMemo, useState, useCallback, Fragment } from 'react';

import { useTranslation } from 'next-i18next';

import {
  cx,
  useAccordion,
  AccordionItem,
  AccordionBody,
  AccordionHeader,
  IconButton,
} from '@therapie-ecommerce-ui/ui';

import { useRouteChange } from '@hooks/useRouteChange/useRouteChange';
import { LinkText } from '@components/link/Link';

import { getNavigationMobileLinks } from '../Navigation.utils';
import { NavigationMobileLinksProps } from './NavigationMobile.types';
import { Sidebar } from './sidebar/Sidebar';

import styles from './navigation-mobile.module.scss';

export const NavigationMobile = ({ hasBanner }: { hasBanner?: boolean }) => {
  const { t } = useTranslation(['navbar', 'common']);
  const [isOpen, setIsOpen] = useState(false);

  const links = useMemo(() => getNavigationMobileLinks(t), [t]);

  const handleCloseNavbar = useCallback(() => setIsOpen(false), []);

  useRouteChange({ onRouteChangeComplete: handleCloseNavbar });

  return (
    <>
      <IconButton
        colorScheme="night"
        size="md"
        aria-label={t('navbar:menu.openLabel')}
        iconProps={{
          name: isOpen ? 'close' : 'menu',
        }}
        onClick={() => setIsOpen((prev) => !prev)}
      />
      <nav aria-hidden={!isOpen}>
        <Sidebar hasBanner={hasBanner} isOpen={isOpen} onClose={handleCloseNavbar}>
          <NavigationMobileLinks links={links} className={styles['links']} />
        </Sidebar>
      </nav>
    </>
  );
};

const NESTING_CLASSNAME = {
  0: 'grandparent',
  1: 'parent',
  2: 'child',
} as const;

const NavigationMobileLinks = ({ className, links, nesting = 0 }: NavigationMobileLinksProps) => {
  const { getBodyProps, getHeaderProps, isActiveItem } = useAccordion({
    canRecloseItems: true,
  });

  const Wrapper = className ? 'div' : Fragment;
  const wrapperProps = className ? { className } : {};

  const nestingClassName = NESTING_CLASSNAME[nesting as keyof typeof NESTING_CLASSNAME];

  return (
    <Wrapper {...wrapperProps}>
      {links.map(({ text, href, onClick, children }, index) => {
        const isActive = isActiveItem(text);

        if (href) {
          return (
            <LinkText
              key={index}
              href={href}
              onClick={onClick}
              className={styles[`link-${nestingClassName}`]}
            >
              {text}
            </LinkText>
          );
        }
        return (
          <AccordionItem
            key={index}
            className={cx(styles[`dropdown-${nestingClassName}`], {
              [styles[`dropdown-${nestingClassName}--active`]]: isActive,
            })}
          >
            <AccordionHeader
              className={cx(styles[`dropdown__header-${nestingClassName}`], {
                [styles[`dropdown__header-${nestingClassName}--active`]]: isActive,
              })}
              {...getHeaderProps(text)}
            >
              {text}
            </AccordionHeader>
            <AccordionBody
              isOpen={isActive}
              wrapperClassName={cx(styles[`dropdown__body-${nestingClassName}`], {
                [styles[`dropdown__body-${nestingClassName}--active`]]: isActive,
              })}
              className={cx({ [styles[`dropdown__body-${nestingClassName}--active`]]: isActive })}
              {...getBodyProps(text)}
            >
              <NavigationMobileLinks links={children!} nesting={nesting + 1} />
            </AccordionBody>
          </AccordionItem>
        );
      })}
    </Wrapper>
  );
};
