import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { cx } from '@therapie-ecommerce-ui/ui';

import { ENV_REGION } from '@utils/constants/region.constants';
import { isDevEnv } from '@utils/environment';

import { FlagProps } from './Flag.types';
import { FLAG_SIZES } from './Flag.constants';
import { getRegionForLocale } from './Flag.utils';

import styles from './flag.module.scss';

export const Flag = ({ region, size = 'sm', variant = 'rounded', className }: FlagProps) => {
  const { t } = useTranslation('common');
  const router = useRouter();

  const _region = region ? region : isDevEnv() ? getRegionForLocale(router.locale) : ENV_REGION;

  return (
    <Image
      src={`/static/images/flag/${_region}.svg`}
      height={FLAG_SIZES[size]}
      width={FLAG_SIZES[size]}
      className={cx(className, styles[`flag--${variant}`])}
      alt={_region ? t(`regions.${_region}`) : undefined}
    />
  );
};
