import { TFunction } from 'react-i18next';
import { Cart, EnrichedCartGql } from '@/data/graphql/types';
import { CheckoutStepsNameType } from '../constants/checkout-steps.constants';
import { checkoutActionsState } from '../store/checkout/checkout.store';
import { CheckoutSteps } from '../store/checkout/checkout.types';
import { getItemsHaveProducts } from './cart.utils';

type CheckoutStateFlagsProps = {
  hasPaymentMethod?: boolean;
  hasShippingAddress?: boolean;
  hasPreferredClinic?: boolean;
};

export const getCheckoutSteps = (t: TFunction<'forms'>, steps: CheckoutStepsNameType[]) => {
  const allSteps = [
    {
      id: 'SHIPPING_ADDRESS',
      label: t('titles.shippingAddress'),
    },
    {
      id: 'BILLING_ADDRESS',
      label: t('titles.billingAddress'),
    },
    {
      id: 'PREFERRED_CLINIC',
      label: t('titles.preferredClinic'),
    },
    {
      id: 'PAYMENT_METHOD',
      label: t('titles.paymentMethod'),
    },
  ] as const;

  if (steps.length === 0) {
    return allSteps.filter(Boolean);
  }

  return allSteps.filter((step) => Boolean(step) && steps.includes(step.id)).filter(Boolean);
};

// TODO: Remove this function
export const getEnabledStepsForCheckout = ({
  hasPaymentMethod = true,
  hasShippingAddress = true,
  hasPreferredClinic = false,
}: CheckoutStateFlagsProps) => {
  const enabledSteps = [
    hasShippingAddress ? 'SHIPPING_ADDRESS' : undefined,
    'BILLING_ADDRESS',
    hasPreferredClinic ? 'PREFERRED_CLINIC' : undefined,
    hasPaymentMethod ? 'PAYMENT_METHOD' : undefined,
  ].filter(Boolean) as CheckoutStepsNameType[];
  return { enabledSteps, enabledStepsString: enabledSteps.join(',') };
};

export function setStepsForCheckout(
  t: TFunction<'forms'>,
  checkoutStateFlags: CheckoutStateFlagsProps = {}
) {
  const { enabledSteps } = getEnabledStepsForCheckout(checkoutStateFlags);
  const steps = getCheckoutSteps(t, enabledSteps);

  return steps;
}

export const setCheckoutStepsFromCart = (cart: Cart | EnrichedCartGql) => {
  const hasProducts = getItemsHaveProducts(cart?.items);

  checkoutActionsState.setCheckoutSteps((steps) => ({
    ...steps,
    SHIPPING_ADDRESS: hasProducts,
  }));
};

export const getEnabledCheckoutSteps = (t: TFunction<'forms'>, steps: CheckoutSteps) => {
  const filteredSteps = Object.keys(steps).filter((step) =>
    Boolean(steps[step as keyof typeof steps])
  ) as (keyof CheckoutSteps)[];

  return getCheckoutSteps(t, filteredSteps);
};
