import { useCallback } from 'react';
import { useTranslation } from 'next-i18next';

import { useToast } from '@therapie-ecommerce-ui/ui';

/**
 * Returns a callback to display an error toast
 * with a generic message if one is not provided
 */
export const useErrorToast = () => {
  const { t } = useTranslation('common');

  const toast = useToast();

  const errorToast = useCallback(
    (message?: string) =>
      toast({ variant: 'danger', message: message ?? t('errors.unexpectedError') }),
    [toast, t]
  );

  return errorToast;
};
