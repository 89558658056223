import { PaymentIntentType } from '@/data/graphql/types';
import { log } from '@core/logging/logging';
import { useCancelIntentMutation } from '@features/stripe/api/hooks/useCancelIntentMutation';
import { useStripePaymentIntent, useStripeSetupIntent } from '@features/stripe/store/stripe.store';
import { useCallback } from 'react';

export const useCancelPendingIntents = () => {
  const {
    mutate: handleCancelPaymentIntentSync,
    mutateAsync: handleCancelPaymentIntentAsync,
    isLoading: isLoadingCancelPaymentIntent,
  } = useCancelIntentMutation(PaymentIntentType.PaymentIntent);
  const {
    mutate: handleCancelSetupIntentSync,
    mutateAsync: handleCancelSetupIntentAsync,
    isLoading: isLoadingCancelSetupIntent,
  } = useCancelIntentMutation(PaymentIntentType.SetupIntent);

  const { paymentIntent } = useStripePaymentIntent();
  const { setupIntent } = useStripeSetupIntent();

  const handleCancelPendingIntentsSync = useCallback(() => {
    if (setupIntent?.id && setupIntent?.status !== 'succeeded' && !isLoadingCancelSetupIntent) {
      handleCancelSetupIntentSync({ intentId: setupIntent?.id ?? '' });
    }

    if (
      paymentIntent?.id &&
      paymentIntent?.status !== 'succeeded' &&
      !isLoadingCancelPaymentIntent
    ) {
      handleCancelPaymentIntentSync({ intentId: paymentIntent?.id ?? '' });
    }
  }, [
    handleCancelPaymentIntentSync,
    handleCancelSetupIntentSync,
    paymentIntent?.id,
    setupIntent?.id,
    setupIntent?.status,
    paymentIntent?.status,
    isLoadingCancelPaymentIntent,
    isLoadingCancelSetupIntent,
  ]);

  const handleCancelPendingIntentsAsync = useCallback(async () => {
    const promises = [];

    if (setupIntent?.id && setupIntent?.status !== 'succeeded' && !isLoadingCancelSetupIntent) {
      promises.push(
        handleCancelSetupIntentAsync({ intentId: setupIntent?.id ?? '' }).catch(() => {
          throw new Error(`Failed to cancel setup intent ${setupIntent.id} when user logged out`);
        })
      );
    }

    if (
      paymentIntent?.id &&
      paymentIntent?.status !== 'succeeded' &&
      !isLoadingCancelPaymentIntent
    ) {
      promises.push(
        handleCancelPaymentIntentAsync({ intentId: paymentIntent?.id ?? '' }).catch(() => {
          throw new Error(
            `Failed to cancel payment intent ${paymentIntent.id} when user logged out`
          );
        })
      );
    }

    await Promise.all(promises).catch((error) => {
      if (error instanceof Error) {
        log({
          level: 'error',
          category: 'payments',
          message: error.message,
          messageContext: {
            setupIntentId: setupIntent?.id,
            paymentIntentId: paymentIntent?.id,
          },
        });
      }
    });
  }, [
    paymentIntent?.id,
    paymentIntent?.status,
    isLoadingCancelPaymentIntent,
    handleCancelPaymentIntentAsync,
    setupIntent?.id,
    setupIntent?.status,
    isLoadingCancelSetupIntent,
    handleCancelSetupIntentAsync,
  ]);

  return {
    handleCancelPendingIntentsSync,
    handleCancelPendingIntentsAsync,
  };
};
