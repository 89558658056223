import { ErrorBase } from '@utils/errors';

const PAYMENT_INTENT_ERRORS = {
  PAYMENT_INTENT_NOT_FOUND: 'PAYMENT_INTENT_NOT_FOUND',
  PAYMENT_INTENT_AMOUNT_MISMATCH: 'PAYMENT_INTENT_AMOUNT_MISMATCH',
  PAYMENT_INTENT_INITIALISE_ERROR: 'PAYMENT_INTENT_INITIALISE_ERROR',
  PAYMENT_INTENT_RECREATE_ERROR: 'PAYMENT_INTENT_RECREATE_ERROR',
  PAYMENT_INTENT_CREATE_ERROR: 'PAYMENT_INTENT_CREATE_ERROR',
  PAYMENT_INTENT_UPDATE_ERROR: 'PAYMENT_INTENT_UPDATE_ERROR',
  PAYMENT_INTENT_CONFIRM_FAILED: 'PAYMENT_INTENT_CONFIRM_FAILED',
  PAYMENT_INTENT_CONFIRM_ERROR: 'PAYMENT_INTENT_CONFIRM_ERROR',
  PAYMENT_INTENT_CANCEL_ERROR: 'PAYMENT_INTENT_CANCEL_ERROR',
} as const;

const SETUP_INTENT_ERRORS = {
  SETUP_INTENT_NOT_FOUND: 'SETUP_INTENT_NOT_FOUND',
  SETUP_INTENT_INITIALISE_ERROR: 'SETUP_INTENT_INITIALISE_ERROR',
  SETUP_INTENT_CREATE_ERROR: 'SETUP_INTENT_CREATE_ERROR',
  SETUP_INTENT_UPDATE_ERROR: 'SETUP_INTENT_UPDATE_ERROR',
  SETUP_INTENT_CONFIRM_FAILED: 'SETUP_INTENT_CONFIRM_FAILED',
  SETUP_INTENT_CONFIRM_ERROR: 'SETUP_INTENT_CONFIRM_ERROR',
  SETUP_INTENT_CANCEL_ERROR: 'SETUP_INTENT_CANCEL_ERROR',
} as const;

export const PAYMENT_ERRORS = {
  GET_CUSTOMER_CARDS_ERROR: 'GET_CUSTOMER_CARDS_ERROR',
  STRIPE_ELEMENTS_NOT_DEFINED: 'STRIPE_ELEMENTS_NOT_DEFINED',
  PARSE_INTENT_STRING_ERROR: 'PARSE_INTENT_STRING_ERROR',
  PAYMENT_INTENT_USER_MISMATCH: 'PAYMENT_INTENT_USER_MISMATCH',
  PAYMENT_INTENT_GET_STATUS_INCORRECT: 'PAYMENT_INTENT_GET_STATUS_INCORRECT',
  ...PAYMENT_INTENT_ERRORS,
  ...SETUP_INTENT_ERRORS,
} as const;

type PaymentErrorNames = keyof typeof PAYMENT_ERRORS;

export class PaymentError extends ErrorBase<PaymentErrorNames> {}
