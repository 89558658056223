import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Icon } from '@therapie-ecommerce-ui/ui';
import { LinkButton } from '@components/link/Link';
import { log } from '@core/logging/logging';
import { useCartItems } from '@features/cart-and-checkout/hooks/useCartItems';
import { useCartOperations } from '@features/cart-and-checkout/hooks/useCartOperations';
import { useCartStore } from '@features/cart-and-checkout/store/cart.store';
import { CartState } from '@features/cart-and-checkout/store/cart.types';
import { checkoutActionsState } from '@features/cart-and-checkout/store/checkout/checkout.store';
import { useUserAddresses } from '@hooks/useUserAddresses/useUserAddresses';
import { SITE_URLS } from '@utils/constants/site-urls.constants';
import { useGetUserQuery } from '@/api/hooks/useGetUserQuery';
import { validateAndUseAddressIfExists } from '../checkout-workflow/CheckoutWorkflow.utils';
import { CheckoutButtonProps } from './CheckoutButton.types';

const isInitialisedSelector = (state: CartState) => state.status.isInitialised;

export const CheckoutButton = React.forwardRef<HTMLButtonElement, CheckoutButtonProps>(
  (
    { className, isDisabled: isDisabledProp = false, isLoading: isLoadingProp = false, ...rest },
    ref
  ) => {
    const router = useRouter();
    const { totalItems, hasOutOfStockItem, items } = useCartItems();
    const { t } = useTranslation('cart');
    const cartGlobalOperations = useCartOperations();
    const isCartInitialised = useCartStore(isInitialisedSelector);
    const { data: user } = useGetUserQuery();
    const { defaultBillingAddress, defaultShippingAddress } = useUserAddresses();

    const isLoading = isLoadingProp || !isCartInitialised;

    const isDisabled =
      isDisabledProp ||
      (cartGlobalOperations > 0 && isCartInitialised) ||
      totalItems === 0 ||
      hasOutOfStockItem;

    const handleOnClick = () => {
      if (user?.id) {
        checkoutActionsState.setCheckoutData((prev) => ({
          ...prev,
          billingAddress:
            validateAndUseAddressIfExists(user?.addresses, prev.billingAddress) ??
            defaultBillingAddress,
          shippingAddress:
            validateAndUseAddressIfExists(user?.addresses, prev.shippingAddress) ??
            defaultShippingAddress,
        }));
        checkoutActionsState.setCheckoutSteps((prev) => ({
          ...prev,
          PREFERRED_CLINIC: user?.preferredClinic ? false : true,
        }));
      }
      checkoutActionsState.setInitialCheckoutStep(null);
      log({
        level: 'info',
        category: 'checkout',
        message: 'checkout_button_clicked',
        messageContext: {
          items,
          from: router.asPath,
        },
      });
    };

    return (
      <LinkButton
        onClick={handleOnClick}
        href={SITE_URLS.CHECKOUT}
        ref={ref}
        className={className}
        leftIcon={<Icon name="lock" size="sm" />}
        size={{
          sm: 'md',
          md: 'lg',
        }}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isFullWidth
        {...rest}
      >
        {t('checkoutSecurelyNow')}
      </LinkButton>
    );
  }
);

CheckoutButton.displayName = 'CheckoutButton';
