export const MUTATION_KEYS = {
  CREATE_USER: 'CREATE_USER',
  CREATE_ORDER: 'CREATE_ORDER',
  UPDATE_PERSONAL_INFO: 'UPDATE_PERSONAL_INFO',
  UPDATE_ADDRESS_INFO: 'UPDATE_ADDRESS_INFO',
  DELETE_ADDRESS: 'DELETE_ADDRESS',
  CREATE_PAYMENT_INTENT: 'CREATE_PAYMENT_INTENT',
  CREATE_INTENT: 'CREATE_INTENT',
  CAPTURE_PAYMENT: 'CAPTURE_PAYMENT',
  GET_CUSTOMER: 'GET_CUSTOMER',
  GET_CUSTOMER_CARDS: 'GET_CUSTOMER_CARDS',
  SETUP_PAYMENT_INTENT: 'SETUP_PAYMENT_INTENT',
  UPDATE_PAYMENT_INTENT: 'UPDATE_PAYMENT_INTENT',
  TRIGGER_TRANSACTION: 'TRIGGER_TRANSACTION',
  CANCEL_PAYMENT_INTENT: 'CANCEL_PAYMENT_INTENT',
  CREATE_SETUP_INTENT: 'CREATE_SETUP_INTENT',
  CANCEL_SETUP_INTENT: 'CANCEL_SETUP_INTENT',
  UPDATE_SETUP_INTENT: 'UPDATE_SETUP_INTENT',
  UPDATE_INTENT: 'UPDATE_INTENT',
  CREATE_PURCHASE_TRANSACTION_SYNC: 'CREATE_PURCHASE_TRANSACTION_SYNC',
  GET_PAYMENT_INTENT: 'GET_PAYMENT_INTENT',
  GET_SETUP_INTENT: 'GET_SETUP_INTENT',
  GET_CART_ENRICHED: 'GET_CART_ENRICHED',
} as const;
