import { useCallback, useMemo } from 'react';
import { DEFAULT_ADDRESS_BACKBONE } from '@utils/constants/address.constants';
import { useGetUserQuery } from '@/api/hooks/useGetUserQuery';
import { userCanHaveMoreAddresses } from '@/api/hooks/useUpdateUserAddressInfoMutation/useUpdateUserAddressInfoMutation.utils';
import { getUsersDefaultAddress } from './useUserAddresses.utils';

export const useUserAddresses = () => {
  const { data: user, isLoading } = useGetUserQuery();

  const { userAddresses, hasSavedAddresses, canAddExtraAddress } = useMemo(() => {
    return {
      userAddresses: user?.addresses?.map((address) => ({ ...address })) ?? [],
      hasSavedAddresses: !!user?.addresses?.length,
      canAddExtraAddress: userCanHaveMoreAddresses(user?.addresses),
    };
  }, [user?.addresses]);

  const { defaultShippingAddress, defaultBillingAddress } = useMemo(
    () => getUsersDefaultAddress(user!),
    [user]
  );

  const getAddressValues = useCallback(
    (addressId?: number) => {
      if (typeof addressId === 'undefined' || !userAddresses[addressId]) {
        const isFirstAddress = !userAddresses.length;
        // If first address, we default two addresses
        return {
          ...DEFAULT_ADDRESS_BACKBONE,
          defaultShipping: isFirstAddress,
          defaultBilling: isFirstAddress,
        };
      }
      return userAddresses[addressId];
    },
    [userAddresses]
  );

  return {
    isLoading,
    userAddresses,
    hasSavedAddresses,
    canAddExtraAddress,
    defaultShippingAddress,
    defaultBillingAddress,
    getAddressValues,
  };
};
