import { Fragment } from 'react';

import { useCartItems } from '@features/cart-and-checkout/hooks/useCartItems';
import {
  CartItem,
  CartItemDesktop,
} from '@features/cart-and-checkout/components/cart-items/cart-item/CartItem';

import { CartEmptyState } from './cart-empty-state/CartEmptyState';
import { CartItemsProps } from './CartItems.types';

export const CartItems = ({ className, compact = true, itemProps }: CartItemsProps) => {
  const { items } = useCartItems();

  const Container = className ? 'div' : Fragment;
  const containerProps = className ? { className } : {};

  return (
    <Container {...containerProps}>
      {items.length > 0 ? (
        items.map((item) =>
          compact ? (
            <CartItem key={item.itemId} item={item} {...itemProps} />
          ) : (
            <CartItemDesktop key={item.itemId} item={item} {...itemProps} />
          )
        )
      ) : (
        <CartEmptyState />
      )}
    </Container>
  );
};
