import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  authClient,
  publishUserSignoff,
  SignOutRequest,
  useAuthenticationStore,
} from '@therapie-ecommerce-ui/auth';
import { useIsClient } from '@therapie-ecommerce-ui/ui';
import { log } from '@core/logging/logging';
import { GET_USER_BOOKINGS_HISTORY_KEY } from '@features/booking/api/hooks/useGetUserBookingsHistoryQuery';
import { GET_USER_TREATMENTS_KEY } from '@features/booking/api/hooks/useGetUserTreatmentsQuery';
import { GET_USER_SAVED_PAYMENT_METHODS_KEY } from '@features/cart-and-checkout/api/hooks/useGetUserSavedPaymentMethodsQuery';
import { GET_PAYMENT_SERVICE_USER } from '@features/stripe/api/hooks/useGetPaymentServiceUserQuery';
import { useCancelPendingIntents } from '@features/stripe/hooks/useCancelPendingIntents/useCancelPendingIntents';
import { stripeActionsState } from '@features/stripe/store/stripe.store';
import { GET_USER_LOYALTY_POINTS_KEY } from '@/api/hooks/useGetUserLoyaltyPoints';

const LOG_OUT_LOADING_DELAY_IN_MS = 100;

type UseLogOutProps = { onLogOutSuccess?: () => void; shouldLogOutImmediately?: boolean };

export const useLogOut = ({
  onLogOutSuccess,
  shouldLogOutImmediately = false,
}: UseLogOutProps = {}) => {
  const [isLoading, setIsLoading] = useState(false);
  const isUserAuthenticated = useAuthenticationStore((s) => s.isUserAuthenticated);
  const username = useAuthenticationStore((s) => s.user?.username!);
  const authClearUser = useAuthenticationStore((s) => s.clearUser);
  const isClientSide = useIsClient();
  const canLogOut = isClientSide && isUserAuthenticated;

  const { handleCancelPendingIntentsAsync } = useCancelPendingIntents();

  const handleLogOut = useCallback(async () => {
    if (!canLogOut) {
      onLogOutSuccess?.();
      return;
    }

    // Prevent flicker of spinner when logging out if the request is fast
    const timeout = setTimeout(() => {
      setIsLoading(true);
    }, LOG_OUT_LOADING_DELAY_IN_MS);

    try {
      await authClient.post<SignOutRequest, null>('/sign-out', { username });
      await handleCancelPendingIntentsAsync();
      stripeActionsState.resetAllIntents();
      authClearUser();
      publishUserSignoff();
      log({
        level: 'info',
        category: 'login',
        message: 'user_logged_out',
        messageContext: { username },
      });
      onLogOutSuccess?.();
    } catch (e) {
    } finally {
      clearTimeout(timeout);
      setIsLoading(false);
      authClearUser();
      publishUserSignoff();
      stripeActionsState.resetAllIntents();
    }
  }, [authClearUser, canLogOut, onLogOutSuccess, username, handleCancelPendingIntentsAsync]);

  useEffect(() => {
    if (shouldLogOutImmediately && canLogOut) {
      handleLogOut();
    }
  }, [handleLogOut, shouldLogOutImmediately, canLogOut]);

  return {
    canLogOut,
    handleLogOut,
    isLoading,
  };
};

export const useClearCacheOnLogOut = () => {
  const queryClient = useQueryClient();

  const handleClearCacheOnLogOut = () => {
    // Clear related cache
    queryClient.removeQueries(GET_PAYMENT_SERVICE_USER);
    queryClient.removeQueries(GET_USER_LOYALTY_POINTS_KEY);
    queryClient.removeQueries(GET_USER_BOOKINGS_HISTORY_KEY);
    queryClient.removeQueries(GET_USER_TREATMENTS_KEY);
    queryClient.removeQueries(GET_USER_SAVED_PAYMENT_METHODS_KEY);
  };

  return { handleClearCacheOnLogOut };
};
